import React, { useState, useEffect } from "react";
import classes from "./Events.module.css";
import { Link, useHistory } from "react-router-dom";
import LoadingSpinner from "../Components/LoadingSpinner";

const Events = (props) => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const [isLoading, setLoading] = useState(true);
  useEffect(() => {
    EventList();
  }, [currentPage]);
  console.log(data, "jjjj000000---");
  const history = useHistory();
  const AddEventsHandler = () => {
    history.push("/BiDashboard/addOffers");
  };
  const EventList = () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      page: currentPage,
      content: 10,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_API_URL + "/v1/admin1_1/WeCareOffList",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setData(result.data);
        setLoading(false);
      })
      .catch((error) => console.log("error", error));
  };

  const convertDate = (date) => {
    return date.slice(0, 10).split("-").reverse().join("-");
  };
  return (
    <div className={classes.dashboard_container}>
      <div className={classes.table_container}>
        <div className={classes.container}>
          <div className={classes.button_wrapper}>
            <button
              onClick={AddEventsHandler}
              className={classes.Add_event_button}
            >
              Add Offer
            </button>
          </div>
          <h4 className={classes.events_title}>Offers</h4>
          <div className={classes.buttons1}>
            <div>
              {currentPage !== 1 ? (
                <button
                  onClick={() => {
                    setCurrentPage(currentPage - 1);
                  }}
                  className={classes.buttonone}
                >
                  Prev
                </button>
              ) : (
                <></>
              )}
              <span>{data.length < 10 ? <></> : currentPage}</span>
              {data.length < 10 ? (
                <></>
              ) : (
                <button
                  onClick={() => {
                    setCurrentPage(currentPage + 1);
                  }}
                  className={classes.buttonone}
                >
                  Next
                </button>
              )}
            </div>
          </div>
          {isLoading ? (
            <div className={classes.spinner}>
              <LoadingSpinner />{" "}
            </div>
          ) : (
            <table className={classes.events_table}>
              <tr className={classes.table_row}>
                <th className={classes.table_heading}>Web Image</th>
                <th className={classes.table_heading}>Mobile Image</th>
                <th className={classes.table_heading}>Title</th>
                <th className={classes.table_heading}>Company</th>
                <th className={classes.table_heading}>Start Date</th>
                <th className={classes.table_heading}>End Date</th>
                <th className={classes.table_heading}>Url</th>
                <th className={classes.table_heading}>isLive</th>
                <th className={classes.table_heading}>Action</th>
              </tr>

              <tbody>
                {data.map((item) => (
                  <tr className={classes.table_row}>
                    <td className={classes.table_td}>
                      <a href={item.WebImage} target="_blank">
                        <img
                          src={item.WebImage}
                          className={classes.table_td_image}
                          alt=""
                        />
                      </a>
                    </td>
                    <td className={classes.table_td}>
                      <a href={item.MobImage} target="_blank">
                        <img
                          src={item.MobImage}
                          className={classes.table_td_image}
                          alt=""
                        />
                      </a>
                    </td>
                    <td className={classes.table_td}>{item.Title}</td>
                    <td className={classes.table_td}>{item.Company}</td>
                    <td className={classes.table_td}>
                      {convertDate(item.StartDate)}
                    </td>
                    <td className={classes.table_td}>
                      {convertDate(item.EndData)}
                    </td>
                    <td className={classes.table_td}>{item.Url}</td>
                    <td className={classes.table_td}>
                      {item.IsLive === "1" ? "Yes" : "No"}
                    </td>

                    <td className={classes.table_td}>
                      <Link
                        to={"/BiDashboard/editOffers/" + item.WeCareOfferUUID}
                        className={classes.table_td_Link}
                      >
                        <span className={classes.editbtn}>Edit</span>
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  );
};

export default Events;
