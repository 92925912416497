import React, { useState, useEffect } from "react";
// import { useHistory } from "react-router-dom";
import classes from "./FeaturedCompanies.module.css";
import EventInputs from "../../Components/Events/EventInputs";
import Modal from "../../Components/Modal";
import { Link } from "react-router-dom";
import BackButton from "../../Components/BackButton";

const FeaturedCompanies = () => {
  // const history = useHistory();
  // const [name, setName] = useState()
  const [position, setPosition] = useState();
  const [image, setImage] = useState("");
  const [company, setCompany] = useState([]);
  const [companyName, setCompanyName] = useState([]);
  const [companySuggestions, setCompanySuggestions] = useState([]);
  const [companySuggestionsShow, setCompanySuggestionsShow] = useState(true);
  const [editableCImage, setEditableCImage] = useState(true);
  const [logoImage, setLogoImage] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [check, setCheck] = useState(0);
  const [imageAlt, setImageAlt] = useState("");
  // const [active, setActive] = useState(0);
  const [id, setId] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [erorrshow, setErrorShow] = useState(false);
  const [internalErrorShow, setInternalErrorShow] = useState(false);
  // const [paidCompany, setPaidCompany] = useState(0);
  const [isdelete, setIsdelete] = useState(0);

  const selectionApi = () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      SearchTearm: companyName,
    });
    // console.log(company);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(global.apiLink + "/adminapi/Search-Company", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "SUCCESS" && result.status_code === 200)
          setCompany(result.data);
        else setCompany([]);
      })
      .catch((error) => console.log("error", error));
  };

  const UpdateCompanies = (id) => {
    // setPassLoader(true)
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );

    var formdata = new FormData();
    formdata.append("CompanyId", id);
    if (logoImage) formdata.append("Image", logoImage);
    formdata.append("LogoPosition", position);
    formdata.append("Active", check);
    formdata.append("Name", companyName);
    formdata.append("StartDate ", startDate);
    formdata.append("EndDate ", endDate);
    formdata.append("isPaid ", 0);
    formdata.append("isDeleted ", isdelete);
    formdata.append("ImageAltTag ", imageAlt);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };
    fetch(
      process.env.REACT_APP_API_URL + "/adminapi/Update-FeaturedCompany",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "SUCCESS" && result.status_code === 200) {
          setModalShow(true);
        } else if (result.status_code === 400 || result.status_code === 500) {
          setInternalErrorShow(true);
        } else if (result.status_code === 300) {
          setErrorShow(true);
        }
      })
      .catch((error) => console.log("error", error));
    // .finally(() => {
    //   setPassLoader(false);
    // });
  };
  // console.log(check, position)
  useEffect(() => {
    selectionApi();
  }, [companyName]);

  useEffect(() => {
    if (companyName !== "") {
      let val = false;

      setCompanySuggestions(
        company.filter((val) => {
          if (val.name === companyName) {
            val = true;
          }
          return val.name
            ? val.name.toLowerCase().startsWith(companyName.toLowerCase())
            : null;
        })
      );
      if (val) {
        setCompanySuggestionsShow(false);
      }
      setCompanySuggestionsShow(true);
    } else {
      setCompanySuggestions([]);
      setCompanySuggestionsShow(false);
    }
  }, [company]);

  return (
    <div className={classes.top_div}>
      {modalShow && (
        <Modal
          heading={"Data inserted successfully"}
          onClick={() => setModalShow(false)}
        />
      )}
      {erorrshow && (
        <Modal
          heading={"Required Fields Missing"}
          onClick={() => setErrorShow(false)}
        />
      )}
      {internalErrorShow && (
        <Modal
          heading={"Internal server error"}
          onClick={() => setInternalErrorShow(false)}
        />
      )}

      <div className={classes.container}>
        <div className={classes.form}>
          <div className={classes.form_header_container}>
            <div className={classes.form_header}>
              Featured Companies</div>
            <Link
              to="/BiDashboard/FeaturedCompanyList"
              className={classes.BackButton}
            >
              <BackButton />
            </Link>

          </div>
          <div className={classes.form_input}>
            <div className={classes.input}>
              <EventInputs
                input={"SingleLineInput"}
                label={"Company Name"}
                placeholder={"Company Name"}
                onChange={(e) => setCompanyName(e.target.value)}
                value={companyName}
              />
              <div>
                {companySuggestionsShow ? (
                  <div>
                    {companySuggestions.map((item) => {
                      return (
                        <p
                          onClick={() => {
                            setCompanyName(item.name);
                            setPosition(item.logoPosition);
                            setImage(item.fileImage);
                            setId(item.id);
                          }}
                        >
                          <div>{item.name}</div>
                        </p>
                      );
                    })}
                  </div>
                ) : (
                  <></>
                )}
              </div>
              <EventInputs
                input={"SingleLineInput"}
                label={"Logo Position"}
                placeholder={"1 to 7"}
                onChange={(e) => setPosition(e.target.value)}
                value={position}
              />
              {editableCImage ? (
                <div>
                  <div className={classes.fieldName}>
                    <label htmlFor="input7">Logo</label>
                  </div>
                  <div className={classes.field1}>
                    <div className={classes.inputField}>
                      <div className={classes.inputFieldText}>
                        {" "}
                        <img src={image} width={60} height={60} />
                      </div>
                      <div>
                        {" "}
                        <button
                          onClick={() => setEditableCImage(false)}
                          className={classes.input_button}
                        >
                          edit{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <EventInputs
                  input={"UpdateImageInput"}
                  label={"Logo"}
                  placeholder={"Logo"}
                  onChange={(e) => setLogoImage(e.target.files[0])}
                  type={"file"}
                  accept=".png, .jpg, .jpeg"
                />
              )}
            </div>

            <div className={classes.input}>
              <EventInputs
                input={"SingleLineInput"}
                label={"Image Alt"}
                placeholder={"Image Alt"}
                onChange={(e) => setImageAlt(e.target.value)}
                value={imageAlt}
              />
              <EventInputs
                input={"DateInput"}
                label={"Start Date"}
                placeholder={"Start Date"}
                onChange={(e) => setStartDate(e.target.value)}
                value={startDate}
              />
              <EventInputs
                input={"DateInput"}
                label={"End Date"}
                placeholder={"End Date"}
                onChange={(e) => setEndDate(e.target.value)}
                value={endDate}
              />
              {/* <EventInputs
                input={"SingleLineInput"}
                label={"Company Id"}
                placeholder={"Company Id"}
                onChange={(e) => setId(e.target.value)}
                value={id}
              /> */}
              <div>
                <div className={classes.fieldName}>Company Id</div>
                <div className={classes.fields1}>
                  <div className={classes.inputFieldId}>{id}</div>
                </div>
              </div>
            </div>
          </div>
          <div className={classes.checkbox}>
            <EventInputs
              input={"CheckBox"}
              onChange={() => setCheck(!check === true ? 1 : 0)}
              label={"Want to publish"}
              value={check}
            />
          </div>
          <div className={classes.checkbox}>
            <EventInputs
              input={"CheckBox"}
              onChange={() => setIsdelete(!isdelete === true ? 1 : 0)}
              label={"Want to Delete"}
              value={isdelete}
            />
          </div>
        </div>
        <button
          className={classes.Upload_button}
          onClick={() => UpdateCompanies(id)}
        >
          submit
        </button>
      </div>
    </div>
  );
};

export default FeaturedCompanies;
