import React, { useState, useEffect } from "react";
import LoadingSpinner from "../Components/LoadingSpinner";
import classes from "./coursedatadetail.module.css";
import { CSVLink } from "react-csv";
import ExcelDownload from "../Components/Articles/ExcelDownload";
import Card from "../Components/Dashboard/Card";
import { Link } from "react-router-dom";
import TimesBreadCrumbs from "../Components/TimesBreadCrumbs";
import { AiOutlineArrowUp } from "react-icons/ai";
import { AiOutlineArrowDown } from "react-icons/ai";
const CourseDataPoints = (props) => {
  const [isLoading, setLoading] = useState(false);
  const [coursedatadetail, setCoursedatadetail] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [count, setCount] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const currentURL = window.location.href.split("/").pop();
  console.log("currentURL", currentURL);
  const [isfirstname, setIsfirstname] = useState("ASC");
  useEffect(() => {
    CoursedataApi();
  }, [currentPage]);

  const CoursedataApi = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      courseId: currentURL,
      startDate: startDate,
      endDate: endDate,
      Page: currentPage,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_API_URL + "/adminapi/getCourseDetail",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        console.log(result, "result");
        setCoursedatadetail(result.data);
        setCount(result.count);
      })
      .catch((error) => console.log("error", error))
      .finally(() => setLoading(false));
  };
  console.log(startDate, "Start Date");
  const id = localStorage.getItem("Id");
  const pageInfo = {
    currentPageName: "Course Data Points Details",
    previousPageName: "Course List",
    previousPage: "/BiDashboard/CourseById/" + id,
  };
  const sortingfirstname = (col) => {
    if (isfirstname === "ASC") {
      const sortedfirst = [...coursedatadetail].sort((a, b) =>
        a[col] > b[col] ? 1 : -1
      );
      setCoursedatadetail(sortedfirst);
      setIsfirstname("DSC");
    }
    if (isfirstname === "DSC") {
      const sortedfirst = [...coursedatadetail].sort((a, b) =>
        a[col] < b[col] ? 1 : -1
      );
      setCoursedatadetail(sortedfirst);
      setIsfirstname("ASC");
    }
  };

  return (
    <div className={classes.dashboard_container}>
      <div className={classes.TimsbiBreadcrumb}>
        {" "}
        <TimesBreadCrumbs pageInfo={pageInfo} />{" "}
      </div>
      <div className={classes.events_title}>Course Detailed Page</div>

      <div className={classes.DateFilter_container}>
        <div className={classes.button_container}>
          <CSVLink
            data={coursedatadetail}
            filename={"Course_Detail_Table.csv"}
            target="_blank"
          >
            <button className={classes.button_articleList}>CSV</button>
          </CSVLink>
          <ExcelDownload csvData={coursedatadetail} fileName="Course_Detail" />
        </div>
        <div className={classes.dateDiv}>
          <div>
            <label className={classes.dateLable}>Start Date</label>
            <input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
            <label className={classes.dateLable}>End Date</label>
            <input
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </div>
          <div className={classes.buttons}>
            <button
              type="button"
              className="button"
              onClick={() => CoursedataApi()}
            >
              {" "}
              Search
            </button>
          </div>
        </div>
      </div>
      <div className={classes.card}>
        <div className={classes.count}>Count :</div>
        <div className={classes.count_main}>{count}</div>
      </div>
      {count > 10 ? (
        <div className={classes.buttons1}>
          {currentPage !== 1 ? (
            <button
              onClick={() => {
                setCurrentPage(currentPage - 1);
              }}
              className={classes.buttonone}
            >
              Prev
            </button>
          ) : (
            <></>
          )}
          <span>{currentPage}</span>
          {coursedatadetail.length > 10 ? (
            <>
              <button
                onClick={() => {
                  setCurrentPage(currentPage + 1);
                }}
                className={classes.buttonone}
              >
                Next
              </button>
            </>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <></>
      )}
      {isLoading ? (
        <div className={classes.spinner}>
          <LoadingSpinner />
        </div>
      ) : (
        <div className={classes.table_container}>
          <div className={classes.container}>
            {coursedatadetail.length > 0 ? (
              <table className={classes.orders_table}>
                <tr className={classes.table_row}>
                  <th className={classes.table_heading}>Sno</th>
                  <th className={classes.table_heading}>Course Image</th>
                  <th className={classes.table_heading}>
                    Course Title
                    {isfirstname === "ASC" ? (
                      <span
                        className={classes.arrow}
                        onClick={() => sortingfirstname("course_title")}
                      >
                        <AiOutlineArrowDown />
                      </span>
                    ) : (
                      <span
                        className={classes.arrow}
                        onClick={() => sortingfirstname("course_title")}
                      >
                        <AiOutlineArrowUp />
                      </span>
                    )}
                  </th>
                  <th className={classes.table_heading}>Course Type</th>
                  <th className={classes.table_heading}>Course Category</th>
                  <th className={classes.table_heading}>Provider Name</th>
                  <th className={classes.table_heading}>Course Group</th>
                  <th className={classes.table_heading}>Course Duration</th>
                  <th className={classes.table_heading}>Date</th>
                  <th className={classes.table_heading}>Course Source</th>
                </tr>

                <tbody>
                  {coursedatadetail.map((item, value) => {
                    return (
                      <tr className={classes.table_row}>
                        <td className={classes.table_td}>{item.sno}</td>
                        <td className={classes.table_td}>
                          {item.course_image ? (
                            <img
                              src={item.course_image}
                              className={classes.table_td_image}
                              alt="no media"
                            />
                          ) : (
                            <img
                              src="/dummy-image-square.jpg"
                              className={classes.table_td_image}
                              alt="no media"
                            />
                          )}
                        </td>
                        <td className={classes.table_td}>
                          {item.course_title}
                        </td>
                        <td className={classes.table_td}>{item.course_type}</td>
                        <td className={classes.table_td}>
                          {item.course_category}
                        </td>
                        <td className={classes.table_td}>
                          {item.providerName}
                        </td>
                        <td className={classes.table_td}>
                          {item.course_group}
                        </td>
                        <td className={classes.table_td}>
                          {item.courseDuration}
                        </td>
                        <td className={classes.table_td}>{item.date}</td>
                        <td className={classes.table_td}>
                          <a href={item.course_source} target="_blank">
                            Course Url
                          </a>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            ) : (
              <div className={classes.No_data}>No data found!</div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default CourseDataPoints;
