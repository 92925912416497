import React, { useState } from "react";
import classes from "./AddPodcast.module.css";
import { Link, useHistory } from "react-router-dom";

import "suneditor/dist/css/suneditor.min.css";
import BackButton from "../../Components/BackButton";
import Modal from "../../Components/Modal";
import EventInputs from "../../Components/Events/EventInputs";
import Loader from "react-loader-spinner";

const AddPodcast = () => {
  const history = useHistory();

  const [modalShow, setModalShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [serialNumber, setSerialNumber] = useState("");
  const [podcastUrl, setPodcastUrl] = useState("");
  const [isLive, setIsLive] = useState(false);
  const [webImage, setWebImage] = useState("");
  const [provider, setProvider] = useState("");

  const createPodcastFunc = (e) => {
    e.preventDefault();
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );

    var formdata = new FormData();

    formdata.append("SrNo", serialNumber);
    formdata.append("PodcastTitle", title);
    formdata.append("PodcastURL", podcastUrl);
    formdata.append("Provider", provider);
    formdata.append("IsLive", isLive ? 1 : 0);
    formdata.append("Thumbnail", webImage);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };
    fetch(
      process.env.REACT_APP_API_URL + "/v1/admin1_1/PodcastCreate",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        if (result.status === "Success" && result.status_code == 200) {
          setModalShow(true);
        }
        return result;
      })
      .then((result) => {
        if (result.status_code == 200 && result.status === "Success") {
          setTitle("");
          setSerialNumber("");
          setPodcastUrl("");
          setIsLive(false);
          setWebImage("");
          setProvider("");
        }
      })
      .catch((error) => console.log("error", error))
      .finally(() => setLoading(false));
  };

  return (
    <div className={classes.top_div}>
      {modalShow && (
        <Modal
          heading={"Data inserted successfully"}
          onClick={() => history.push("/BiDashboard/podcasts")}
        />
      )}

      <div className={classes.container}>
        <div className={classes.form}>
          <div className={classes.form_header_container}>
            <div className={classes.form_header}>Add Podcast</div>
            <Link to="/BiDashboard/podcasts" className={classes.BackButton}>
              <BackButton />
            </Link>
          </div>
          <form onSubmit={createPodcastFunc} className={classes.form_input}>
            <div className={classes.input}>
              <EventInputs
                input={"SingleLineInput"}
                label={"Serial Number"}
                required={true}
                placeholder={"Serial Number"}
                onChange={(e) => setSerialNumber(e.target.value)}
                value={serialNumber}
              />

              <EventInputs
                input={"ImageInput"}
                label={"Image "}
                placeholder={"Image "}
                required={true}
                onChange={(e) => setWebImage(e.target.files[0])}
                type={"file"}
                accept=".png, .jpg, .jpeg"
              />
              <div className={classes.input}>
                <EventInputs
                  input={"SingleLineInput"}
                  label={"Provider"}
                  placeholder={"Provider"}
                  required={true}
                  onChange={(e) => setProvider(e.target.value)}
                  value={provider}
                />
              </div>
            </div>

            <div className={classes.input}>
              <EventInputs
                input={"SingleLineInput"}
                label={"Title"}
                placeholder={"Title"}
                onChange={(e) => setTitle(e.target.value)}
                value={title}
                required={true}
              />
              <div className={classes.input}>
                <EventInputs
                  input={"SingleLineInput"}
                  label={"Podcast Url"}
                  placeholder={"Podcast Url"}
                  onChange={(e) => setPodcastUrl(e.target.value)}
                  value={podcastUrl}
                  required={true}
                />
              </div>
            </div>

            <div className={classes.checkbox}>
              <EventInputs
                input={"CheckBox"}
                onChange={() => setIsLive(!isLive)}
                label={"Want to publish this podcast"}
                value={isLive}
              />
              <div className={classes.btnWrapper}>
                <button
                  type="submit"
                  className={classes.Upload_button}
                  disabled={loading}
                >
                  {loading ? (
                    <Loader
                      type="ThreeDots"
                      color="#fff"
                      height={10}
                      width={70}
                      visible={true}
                    //3 secs
                    />
                  ) : (
                    "Submit"
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddPodcast;
