import React, { useState, useEffect } from "react";
import classes from "./studentInformation.module.css";
import LoadingSpinner from "../LoadingSpinner";
import Modal from "../Modal";
import TimesBreadCrumbs from "../TimesBreadCrumbs";
import FormViewModal from "./FormViewModal";
import { BsEye } from "react-icons/bs";
import { CSVLink } from "react-csv";
import ExcelDownload from "../Articles/ExcelDownload";

// import Modal from "../Components/Modal";
const StudentInformation = () => {
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [actofcompassion, setActOfCompassion] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [modelData, setModelData] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    StudentInformation();
  }, [currentPage]);

  const StudentInformation = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 4YzpGk8Vf8IcxzxQ68teZ2KM5iX8SktW5RN4iz92"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = "";

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(
      process.env.REACT_APP_API_URL + "/adminapi/GlobalMindsStudentsGetAPI",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code === 200) {
          setActOfCompassion(result.data);
          setData(result.data);
        }
      })
      .catch((error) => console.log("error", error))
      .finally(() => setLoading(false));
  };

  const pageInfo = {
    currentPageName: "Global Student List",
  };

  const onModalCloseHandler = () => {
    setIsModalOpen(false);
    // history.push("/BiDashboard/articles");
  };

  return (
    <>
      <FormViewModal
        isModalOpen={isModalOpen}
        data={modelData}
        setIsModalOpen={setIsModalOpen}
        onClose={onModalCloseHandler}
      />{" "}
      <div className={classes.dashboard_container}>
        <div className={classes.TimsbiBreadcrumb}>
          <TimesBreadCrumbs pageInfo={pageInfo} />{" "}
        </div>
        <div className={classes.DateFilter_container}>
          <div className={classes.button_container}>
            <CSVLink
              data={data}
              filename={"students-global.csv"}
              target="_blank"
            >
              <button className={classes.button_articleList}>CSV</button>
            </CSVLink>
            <ExcelDownload csvData={data} fileName="students-global-table" />
          </div>
          <div className={classes.buttons1}>
            <div>
              <button
                onClick={() => {
                  setCurrentPage(currentPage - 1);
                }}
                className={classes.buttonone}
              >
                Prev
              </button>

              <button
                onClick={() => {
                  setCurrentPage(currentPage + 1);
                }}
                className={classes.buttonone}
              >
                Next
              </button>
            </div>
          </div>
        </div>

        <div className={classes.table_container}>

          <div className={classes.container}>

            {isLoading ? (
              <div className={classes.spinner}>
                <LoadingSpinner />{" "}
              </div>
            ) : data.length ? (
              <>
                <table className={classes.orders_table}>
                  <tr className={classes.table_row}>
                    <th className={classes.table_heading}>Sr. No.</th>
                    <th className={classes.table_heading}>Student Name</th>

                    <th className={classes.table_heading}>Phone Number</th>
                    <th className={classes.table_heading}>Email</th>
                    <th className={classes.table_heading}>Country</th>
                    <th className={classes.table_heading}>State</th>
                    <th className={classes.table_heading}>City</th>
                    <th className={classes.table_heading}>DegreeName</th>
                    <th className={classes.table_heading}>GraduationYear</th>
                    <th className={classes.table_heading}>view</th>
                  </tr>

                  <tbody>
                    {actofcompassion.map((item, i) => {
                      return (
                        <tr className={classes.table_row}>
                          <td className={classes.table_td}>{i + 1}</td>

                          <td className={classes.table_td}>{item.Name}</td>
                          <td className={classes.table_td}>{item.MobileNo}</td>
                          <td className={classes.table_td}>{item.EmailId}</td>
                          <td className={classes.table_td}>{item.Country}</td>
                          <td className={classes.table_td}>{item.State}</td>
                          <td className={classes.table_td}>{item.City}</td>
                          <td className={classes.table_td}>
                            {item.DegreeName}
                          </td>
                          <td className={classes.table_td}>
                            {item.GraduationYear}
                          </td>

                          <td
                            className={classes.table_td}
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setModelData(item);
                              setIsModalOpen(true);
                            }}
                          >
                            <span className={classes.table_td_Link}>
                              <BsEye />
                            </span>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </>
            ) : (
              <h2 className={classes.serachErrorText}>"No Data found !"</h2>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default StudentInformation;
