import { Fragment, useState } from "react";
import EventInputs from "../Events/EventInputs";
import classes from "./answermodal.module.css";
const Backdrop = () => {
  return <div className={classes.backdrop} />;
};

const AnswerModal = (props) => {
  let filterid = props.forumlist.filter((item) => {
    return item.ForumQuestionID === props.id;
  });
  console.log(filterid);

  const AnswerapproveApi = (ForumAnswerID) => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      ForumAnswerID: ForumAnswerID,
      AdminApproved: "Approved",
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_API_URL + "/v1/api/apiTimes/ApprovedAdminAnswerStatus",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code === 200) {
          props.onClick();
          props.ForumListApi();
        }
      })
      .catch((error) => console.log("error", error));
  };
  const AnswerNotapprove = (ForumAnswerID) => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      ForumAnswerID: ForumAnswerID,
      AdminApproved: "NotApproved",
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_API_URL + "/v1/api/apiTimes/ApprovedAdminAnswerStatus",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code === 200) {
          props.onClick();
          props.ForumListApi();
        }
      })
      .catch((error) => console.log("error", error));
  };

  const defaultApi = (ForumAnswerID) => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      ForumAnswerID: ForumAnswerID,
      DefaultAnswer: 1,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_API_URL + "/v1/api/apiTimes/AnswerMarkedDefault",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code === 200) {
          props.onClick();
          props.ForumListApi();
        }
      })
      .catch((error) => console.log("error", error));
  };
  const NotdefaultApi = (ForumAnswerID) => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      ForumAnswerID: ForumAnswerID,
      DefaultAnswer: 0,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_API_URL + "/v1/api/apiTimes/AnswerMarkedDefault",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code === 200) {
          props.onClick();
          props.ForumListApi();
        }
      })
      .catch((error) => console.log("error", error));
  };
  return (
    <Fragment>
      <Backdrop />
      <div className={classes.modal}>
        <table>
          <tr className={classes.table_row}>
            <th className={classes.table_heading}>Answers</th>
            <th className={classes.table_heading}>Approve</th>
            <th className={classes.table_heading}>NotApprove</th>
            <th className={classes.table_heading}>Status</th>
            <th className={classes.table_heading}>Set Default</th>
            <th className={classes.table_heading}>Not Default</th>
          </tr>

          <tbody>
            {filterid.map((item) => {
              return item.answers.map((item) => {
                return (
                  <>
                    <tr className={classes.table_row}>
                      {" "}
                      <td className={classes.table_td}>{item.Answer}</td>
                      <td className={classes.table_td}>
                        {" "}
                        <button
                          onClick={() => {
                            AnswerapproveApi(item.ForumAnswerID);
                          }}
                          className={classes.btnapp}
                        >
                          Approve
                        </button>
                      </td>
                      <td className={classes.table_td}>
                        {" "}
                        <button
                          onClick={() => {
                            AnswerNotapprove(item.ForumAnswerID);
                          }}
                          className={classes.btnun}
                        >
                          NotApprove
                        </button>
                      </td>
                      <td className={classes.table_td}>{item.AdminApproved}</td>
                      <td className={classes.table_td}>
                        {" "}
                        {/* {check === true
                          ? 1 && defaultApi(item.ForumAnswerID)
                          : ""} */}
                        <span
                          onClick={() => {
                            defaultApi(item.ForumAnswerID);
                          }}
                        >
                          Default
                        </span>
                      </td>
                      <td className={classes.table_td}>
                        {" "}
                        {/* {check === true
                          ? 1 && defaultApi(item.ForumAnswerID)
                          : ""} */}
                        <span
                          onClick={() => {
                            NotdefaultApi(item.ForumAnswerID);
                          }}
                        >
                          NotDefault
                        </span>
                      </td>
                    </tr>
                  </>
                );
              });
            })}
          </tbody>
        </table>

        <button className={classes.button} onClick={props.onClick}>
          ok
        </button>
      </div>
    </Fragment>
  );
};

export default AnswerModal;
