import React, { useState } from "react";
import classes from "../UpdateJson/UpdateJson.module.css";
// import LoadingSpinner from "../Components/LoadingSpinner";
import Loader from "react-loader-spinner";
import Modal from "../Components/Modal";

const UpdatePageJson = () => {
  const [searchCategory, setSearchCategory] = useState("");
  const [loading, setLoading] = useState("");
  const [modalShow, setModalShow] = useState(false);

  const categoryList = [
    { category: "homepageMainJSON" },
    { category: "BlogsPage" },
    { category: "jobfunctionFilter" },
    { category: "cityFilter" },
    { category: "companyFilter" },
    { category: "positionFilter" },
    { category: "indusrtyJsonFilter" },
    { category: "levelsFilter" },
    { category: "JobsPage" },
    { category: "forherYoutubeVideo" },
    { category: "ForherPage" },
    { category: "ForherBanner" },
    { category: "GeneralManagerGetFrontEndList" },
    { category: "getForherSreenData" },
    { category: "MainGMIPageJSON" },
    { category: "LeadershipFactoryFrontEndGetByList" },
    { category: "HrConclave" },
    { category: "SHEro" },
  ];

  const UpdateJson = () => {
    setLoading(true);
    const myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 4YzpGk8Vf8IcxzxQ68teZ2KM5iX8SktW5RN4iz92"
    );

    const formdata = new FormData();
    formdata.append("jsontype", searchCategory);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_API_URL + "/adminapi/JsonUpdation",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "Success" && result.status_code === 200) {
          setModalShow(true);
        } else if (result.status_code === 400 || result.status_code === 500) {
          // setInternalErrorShow(true);
        } else if (result.status_code === 300) {
          // setErrorShow(true);
        }
      })
      .catch((error) => console.log("error", error))
      .finally(() => setLoading(false));
  };
  const handleCloseModal = () => {
    setModalShow(false);
  };
  return (
    <div className={classes.main_div}>
      {modalShow && (
        <Modal
          heading={"Data inserted successfully"}
          onClick={handleCloseModal}
        />
      )}
      <div className={classes.container}>
        <div className={classes.header}>Update Json</div>
        <div className={classes.search_container}>
          <select
            onChange={(e) => setSearchCategory(e.target.value)}
            name="Json Category"
            id="cars"
            className={classes.option_input}
          >
            <option disabled selected>
              Please select{" "}
            </option>

            {categoryList.map((item) => (
              <option value={item.category}>{item.category}</option>
            ))}
          </select>
          <button className={classes.button} onClick={UpdateJson}>
            {loading ? (
              <Loader
                type="TailSpin"
                color="white"
                height={20}
                width={50}
                className="text-center"
              />
            ) : (
              "UpDate"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default UpdatePageJson;
