import React, { useState, useEffect, useRef } from "react";
import classes from "./QuizPlayers.module.css";
import { useHistory, Link } from "react-router-dom";
import { CSVLink } from "react-csv";
import jsPDF from "jspdf";
import LoadingSpinner from "../../Components/LoadingSpinner";
import ExcelDownload from "../../Components/Articles/ExcelDownload";
import Paginate from "../../Components/Pagination";

const QuizPlayers = () => {
    const [data, setData] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const doc = new jsPDF();
    const [currentPage, setCurrentPage] = useState(1);
    const [count, setCount] = useState(0);
    const [modalShow, setModalShow] = useState(false);
    const [confirmModalShow, setConfirmModalShow] = useState(false);
    const [leadershipUUID, setLeadershipUUID] = useState("");
    const perPage = 20;
    const [date, setDate] = useState({ startDate: "", endDate: "" });
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");

    const tableRef = useRef();

    useEffect(() => {
        quizplayerslist();
    }, [currentPage]);

    const history = useHistory();

    const quizplayerslist = () => {
        setLoading(true);
        var myHeaders = new Headers();
        myHeaders.append(
            "Authorization",
            "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
        );
        myHeaders.append("Content-Type", "application/json");
        var raw = JSON.stringify({
            page: currentPage,
            content: perPage,
            StartDate: date.startDate ? date.startDate : "",
            EndDate: date.endDate ? date.endDate : "",
        });

        var requestOptions = {
            method: "POST",
            body: raw,
            headers: myHeaders,
            redirect: "follow",
        };

        fetch(
            global.apiLink + "/adminapi/GetQuizLeaderboardAPI",
            requestOptions
        )
            .then((response) => response.json())
            .then((result) => {
                if (result.status_code == 200 && result.status === "Success") {
                    setData(result.data);
                    setCount(result.count);
                }
                setLoading(false);
            })
            .catch((error) => console.log("error", error));
    };

    useEffect(() => {
        var date = new Date();
        date.setDate(date.getDate() - 30);

        let startDateFormat =
            date.getDate().toString().length === 1
                ? "0" + date.getDate()
                : date.getDate();

        const monthFormat =
            date.getMonth() + 1 > 9
                ? date.getMonth() + 1
                : "0" + (date.getMonth() + 1);

        var finalDate =
            date.getFullYear() + "-" + monthFormat + "-" + startDateFormat;

        setStartDate(finalDate);

        var todayDate = new Date();
        todayDate.setDate(todayDate.getDate());

        let endDateFormat =
            todayDate.getDate().toString().length === 1
                ? "0" + todayDate.getDate()
                : todayDate.getDate();

        const todayMonthFormat =
            todayDate.getMonth() + 1 > 9
                ? todayDate.getMonth() + 1
                : "0" + (todayDate.getMonth() + 1);

        var finalToday =
            todayDate.getFullYear() + "-" + todayMonthFormat + "-" + endDateFormat;
        setEndDate(finalToday);
    }, []);

    useEffect(() => {
        if (currentPage && endDate && startDate) {
            quizplayerslist();
        }
    }, [currentPage, startDate, endDate]);

    return (
        <div className={classes.main_div}>
            <div className={classes.container}>
                <div className={classes.header}>Quiz Players List:</div>
                <div className={classes.DateFilter_container}>
                    <div className={classes.button_container}>
                        <CSVLink
                            data={data}
                            filename={"quiz-players.csv"}
                            target="_blank"
                        >
                            <button className={classes.button_articleList}>CSV</button>
                        </CSVLink>
                        <ExcelDownload csvData={data} fileName="quiz-players-table" />
                    </div>
                    {/* <div className={classes.dateDiv}>
                        <div>
                            <label className={classes.dateLable}>Start Date</label>
                            <input
                                type="date"
                                value={date.startDate}
                                onChange={(e) =>
                                    setDate({ ...date, startDate: e.target.value })
                                }
                            />
                            <label className={classes.dateLable}>End Date</label>
                            <input
                                type="date"
                                value={date.endDate}
                                onChange={(e) => setDate({ ...date, endDate: e.target.value })}
                            />
                        </div>
                        <div className={classes.buttons}>
                            <button
                                type="button"
                                className="button"
                                onClick={() => quizplayerslist()}
                            >
                                {" "}
                                Search
                            </button>
                        </div>
                    </div> */}
                </div>
                <div>
                    {isLoading ? (
                        <div className={classes.spinner}>
                            <LoadingSpinner />{" "}
                        </div>
                    ) : data.length ? (
                        <>
                            <table
                                id="article-table"
                                ref={tableRef}
                                className={classes.orders_table}
                            >
                                <tr className={classes.table_row}>
                                    <th className={classes.table_heading}>S.No.</th>
                                    <th className={classes.table_heading}>Candidate ID</th>
                                    <th className={classes.table_heading}>Candidate Name</th>
                                    <th className={classes.table_heading}>Candidate Email</th>
                                    <th className={classes.table_heading}>Total Points</th>
                                    <th className={classes.table_heading}>Quiz Played</th>
                                </tr>
                                <tbody>
                                    {data.map((item, idx) => (
                                        <tr className={classes.table_row}>
                                            <td className={classes.table_td}>{idx + 1}</td>
                                            <td className={classes.table_td}>{item.CandidateId}</td>
                                            <td className={classes.table_td}>{item.CandidateName}</td>
                                            <td className={classes.table_td}>{item.email}</td>
                                            <td className={classes.table_td}>
                                                {item.TotalPoints}
                                            </td>
                                            <td className={classes.table_td}>
                                                {item.QuizPlayed}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <div>
                                {count > 20 && (
                                    <Paginate
                                        maxPage={count}
                                        currentPage={currentPage}
                                        setCurrentPage={setCurrentPage}
                                        perPage={perPage}
                                    />
                                )}
                            </div>
                        </>
                    ) : (
                        <h2 className={classes.serachErrorText}>"No Players Found !"</h2>
                    )}
                </div>
            </div>
        </div>
    );
};

export default QuizPlayers;
