import React from "react";
import classes from "./companyInformation.module.css";
import { FaCopy } from "react-icons/fa";

const CompanyModal = (props) => {
  if (!props.isModalOpen) {
    return null;
  }

  const copyToClipboard = (url) => {
    if (url) {
      navigator.clipboard.writeText(url).then(() => {
        alert("URL copied to clipboard!");
      }).catch((err) => {
        console.error("Failed to copy: ", err);
      });
    } else {
      alert("No URL to copy!");
    }
  };

  return (
    <>
      <div className={classes.overlay} onClick={props.onClose}></div>
      <div className={classes.modal}>
        <div className={classes.header}>
          <h2>Company Details</h2>
          <button className={classes.closeButton} onClick={props.onClose}>
            &times;
          </button>
        </div>
        <div className={classes.contentWrapper}>

          <h4>Company Information</h4>
          <div className={classes.leftSection}>
            {/* Company Information Section */}
            <div className={classes.imageContainer}>
              {/* <h3>Profile Image</h3> */}
              <img
                src={props.data.LogoURL}
                alt={`${props.data.CompanyName}'s Profile`}
                className={classes.image}
              />
            </div>

            <div className={classes.section}>
              <table className={classes.detailsTable}>
                <tbody>
                  <tr>
                    <th >Company Name</th>
                    <td >{props.data.CompanyName}</td>
                  </tr>
                  <tr>
                    <th >Industry</th>
                    <td >{props.data.Industry}</td>
                  </tr>
                  <tr>
                    <th >Company Size</th>
                    <td >{props.data.CompanySize}</td>
                  </tr>
                  <tr>
                    <th >Website Address</th>
                    <td >{props.data.CompanyWebsite}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            {/* Contact Information Section */}

          </div>
          <div className={classes.section}>
            <hr />
            <h4>Contact Details</h4>
            <table className={classes.detailsTable}>
              <tbody>
                <tr>
                  <th >Name (Primary Contact Person)</th>
                  <td >{props.data.PrimaryContactName}</td>
                </tr>
                <tr>
                  <th >Title (Primary Contact Person)</th>
                  <td >{props.data.PrimaryContactTitle}</td>
                </tr>
                <tr>
                  <th >Email Address</th>
                  <td >{props.data.PrimaryContactEmail}</td>
                </tr>
                <tr>
                  <th >Phone Number</th>
                  <td >{props.data.PrimaryContactNumber}</td>
                </tr>
                {/* <tr>
                    <th >Company Logo</th>
                    <td >
                      <img
                        src={props.data.LogoURL}
                        alt="Company Logo"
                        className={classes.company_logo}
                        style={{ width: "150px", height: "150px" }}
                      />
                    </td>
                  </tr> */}
              </tbody>
            </table>
          </div>
          <div className={classes.rightSection}>
            {/* Optional Fields Section */}
            <div className={classes.section}>
              <hr />
              <h4>Optional Fields</h4>
              <table className={classes.detailsTable}>
                <tbody>
                  <tr>
                    <th >Company Description</th>
                    <td >{props.data.CompanyDescription || "Not Provided"}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            {/* Social Media Links Section */}
            <div className={classes.section}>
              <hr />
              <h4>Social Media Links</h4>
              <table className={classes.detailsTable}>
                <tbody>
                  <tr>
                    <th >Facebook Link</th>
                    <td >
                      {props.data.FacebookLink ? (
                        <>
                          <a
                            href={props.data.FacebookLink}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {props.data.FacebookLink}
                          </a>
                          <FaCopy
                            onClick={() => copyToClipboard(props.data.FacebookLink)}
                            style={{ cursor: "pointer", marginLeft: "10px" }}
                          />
                        </>
                      ) : (
                        "Not Provided"
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th >Instagram Link</th>
                    <td >
                      {props.data.InstagramLink ? (
                        <>
                          <a
                            href={props.data.InstagramLink}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {props.data.InstagramLink}
                          </a>
                          <FaCopy
                            onClick={() => copyToClipboard(props.data.InstagramLink)}
                            style={{ cursor: "pointer", marginLeft: "10px" }}
                          />
                        </>
                      ) : (
                        "Not Provided"
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th >Twitter Link</th>
                    <td >
                      {props.data.TwitterLink ? (
                        <>
                          <a
                            href={props.data.TwitterLink}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {props.data.TwitterLink}
                          </a>
                          <FaCopy
                            onClick={() => copyToClipboard(props.data.TwitterLink)}
                            style={{ cursor: "pointer", marginLeft: "10px" }}
                          />
                        </>
                      ) : (
                        "Not Provided"
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>





            {/* Job Openings Section */}
            <div className={classes.section}>
              <hr />
              <h4>Job Openings</h4>
              {/* Iterate through the jobs array to display each job opening */}
              {props.data.Jobs && props.data.Jobs.length > 0 ? (
                props.data.Jobs.map((job, index) => (
                  <div key={index} className={classes.detailsTable}>
                    <table className={classes.info_table}>
                      <tbody>
                        <tr>
                          <th>Position</th>
                          <td>{job.PositionTitle || "Not Provided"}</td>
                        </tr>
                        <tr>
                          <th>How to Apply</th>
                          <td>{job.ApplicationHtmlDetails || "Not Provided"}</td>
                        </tr>
                        <tr>
                          <th>Location</th>
                          <td>{job.JobAddress || "Not Provided"}</td>
                        </tr>
                        <tr>
                          <th>Salary</th>
                          <td>₹{Number(job.MaxCtc).toLocaleString() || 'Not Provided'}</td>
                        </tr>
                        <tr>
                          <th>Job Description</th>
                          <td>{job.JobHtmlDescription || "Not Provided"}</td>
                        </tr>
                      </tbody>
                    </table>
                    <hr />
                  </div>
                ))
              ) : (
                <p>Not Provided</p>
              )}
            </div>

            <div className={classes.section}>
              <h4>Image Gallery</h4>
              {/* Iterate through the images array to display each image */}
              {props.data.
                Images
                && props.data.
                  Images
                  .length > 0 ? (
                <div className={classes.imageGallery}>
                  {props.data.
                    Images
                    .map((image, index) => (
                      <div key={index} className={classes.imageContainerMul}>
                        <img src={image.
                          FileUrl
                        } alt={`Company Image ${index + 1}`} className={classes.imageMul} />
                      </div>
                    ))}
                </div>
              ) : (
                <p>No images available at the moment.</p>
              )}
            </div>

            {/* Video Gallery Section */}
            <div className={classes.section}>
              <hr />
              <h4>Video Gallery</h4>
              {/* Iterate through the videos array to display each video */}
              {props.data.Videos && props.data.Videos.length > 0 ? (
                <div className={classes.videoGallery}>
                  {props.data.Videos.map((video, index) => (
                    <div key={index} className={classes.videoContainer}>
                      <video controls className={classes.videomul}>
                        <source src={video.
                          FileUrl
                        } type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    </div>
                  ))}
                </div>
              ) : (
                <p>No videos available at the moment.</p>
              )}
            </div>


          </div>

          {/* Close Button */}
          <div className={classes.footer}>
            <button className={classes.closeFooterButton} onClick={props.onClose}>Close</button>
          </div>
        </div>
      </div >
    </>
  );
};

export default CompanyModal;
