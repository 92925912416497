import React, { useEffect, useState } from "react";
import classes from "./AddArticleManager.module.css";
import { Link, useHistory } from "react-router-dom";

import "suneditor/dist/css/suneditor.min.css";
import BackButton from "../../Components/BackButton";
import Modal from "../../Components/Modal";
import EventInputs from "../../Components/Events/EventInputs";
import Loader from "react-loader-spinner";
import TextEditor from "../../Components/TextEditor";
import { AiFillCopy, AiOutlineCloseCircle } from "react-icons/ai";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const UpdateManager = () => {
  const history = useHistory();

  const [modalShow, setModalShow] = useState(false);
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [designation, setDesignation] = useState("");
  const [quotes, setQuotes] = useState("");
  const [webImage, setWebImage] = useState("");
  const [uploadImage, setUploadImage] = useState("");
  const [description, setDescription] = useState("");
  const [webImageAltTag, setWebImageAltTag] = useState("");
  const [metaTitle, setMetaTitle] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const [shortDescrition, setShortDescription] = useState("");
  const [UUID, setUUID] = useState("");
  const [isLive, setIsLive] = useState(false);
  const [data, setData] = useState([]);
  const [showImages, setShowImages] = useState([]);
  const [erorrshow, setErrorShow] = useState(false);
  const [editableImage, setEditableImage] = useState(true);

  const getManagerDetail = () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      UUID: id,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_API_URL + "/adminapi/GetArticleManagerByID",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setData(result.data);
        setName(result.data.Name);
        setCompanyName(result.data.CompanyName);
        setDesignation(result.data.Designation);
        setShortDescription(result.data.ShortDescription);
        setQuotes(result.data.Quotes);
        setWebImage(result.data.Image);
        setMetaTitle(result.data.MetaTitle);
        setMetaDescription(result.data.MetaDescription);
        setWebImageAltTag(result.data.imageAltTag);
        setUUID(result.data.UUID);
        setIsLive(result.data.IsLive);
      })
      .catch((error) => console.log("error", error));
  };

  const UpdateArticleManager = (e) => {
    e.preventDefault();
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );

    var formdata = new FormData();

    formdata.append("UUID", UUID);
    formdata.append("Name", name);
    formdata.append("CompanyName", companyName);
    formdata.append("imageAltTag", webImageAltTag);
    formdata.append("Designation", designation);
    formdata.append("Quotes", quotes);
    formdata.append("Image", uploadImage);
    formdata.append("MetaTitle", metaTitle);
    formdata.append("MetaDescription", metaDescription);
    formdata.append("ShortDescription", shortDescrition);
    formdata.append("IsLive", isLive ? 1 : 0);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };
    fetch(
      process.env.REACT_APP_API_URL + "/adminapi/UpdateArticlesManager",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        if (result.status === "Success" && result.status_code == 200) {
          setModalShow(true);
        }
        return result;
      })
      .then((result) => {
        if (result.status_code == 200 && result.status === "Success") {
          setName("");
          setCompanyName("");
          setDesignation("");
          setQuotes("");
          setMetaTitle("");
          setMetaDescription("");
          setWebImage("");
          setWebImageAltTag("");
          setShortDescription("");
          setIsLive(false);
        } else if (result.status_code == 300) {
          setErrorShow(true);
        }
      })
      .catch((error) => console.log("error", error))
      .finally(() => setLoading(false));
  };

  console.log("islive", isLive);

  useEffect(() => {
    getManagerDetail();
  }, []);

  return (
    <div className={classes.top_div}>
      {modalShow && (
        <Modal
          heading={"Data updated successfully"}
          onClick={() => history.push("/BiDashboard/articleManagers")}
        />
      )}

      {erorrshow && (
        <Modal
          heading={"Unable to update the data"}
          onClick={() => setErrorShow(false)}
        />
      )}

      <div className={classes.container}>
        <div className={classes.form}>
          <div className={classes.form_header_container}>
            <div className={classes.form_header}>Edit Article Manager</div>
            <Link
              to="/BiDashboard/articleManagers"
              className={classes.BackButton}
            >
              <BackButton />
            </Link>
          </div>
          <form onSubmit={UpdateArticleManager} className={classes.form_input}>
            <div className={classes.input}>
              <EventInputs
                input={"SingleLineInput"}
                label={"Name"}
                required={true}
                placeholder={"Name"}
                onChange={(e) => setName(e.target.value)}
                value={name}
              />
              <EventInputs
                input={"SingleLineInput"}
                label={"Company Name"}
                placeholder={"Company Name"}
                value={companyName}
              />
              <EventInputs
                input={"SingleLineInput"}
                label={"Meta Description"}
                placeholder={"Meta Description"}
                required={true}
                onChange={(e) => setMetaDescription(e.target.value)}
                value={metaDescription}
              />

              <EventInputs
                input={"SingleLineInput"}
                label={"Meta Title"}
                placeholder={"Meta Title"}
                required={true}
                onChange={(e) => setMetaTitle(e.target.value)}
                value={metaTitle}
              />
            </div>

            <div className={classes.input}>
              <EventInputs
                input={"SingleLineInput"}
                label={"Designation"}
                placeholder={"Designation"}
                value={designation}
              />
              <EventInputs
                input={"SingleLineInput"}
                label={"Image Alt Tag"}
                placeholder={"Image Alt Tag"}
                onChange={(e) => setWebImageAltTag(e.target.value)}
                value={webImageAltTag}
                required={true}
              />
              <EventInputs
                input={"ImageInput"}
                label={"Image"}
                placeholder={"Image"}
                onChange={(e) => setUploadImage(e.target.files[0])}
              />
              {uploadImage ? (
                // eslint-disable-next-line jsx-a11y/alt-text
                <img
                  src={URL.createObjectURL(uploadImage)}
                  className={classes.imgicon}
                />
              ) : (
                <img src={webImage} alt="icon" className={classes.imgicon} />
              )}
            </div>
            <div className={classes.formGroup}>
              <label htmlFor="Content">Description :</label>

              <div>
                <TextEditor
                  onChangeContent={setShortDescription}
                  value={shortDescrition}
                />
              </div>
            </div>

            <div className={classes.formGroup}>
              <label htmlFor="Content">Quotes :</label>

              <div>
                <TextEditor onChangeContent={setQuotes} value={quotes} />
              </div>
            </div>

            <div className={classes.checkbox}>
              <EventInputs
                input={"CheckBox"}
                onChange={() => setIsLive(!isLive)}
                label={"Want to publish"}
                checked={isLive}
                value={isLive}
              />
              <div className={classes.btnWrapper}>
                <button
                  type="submit"
                  className={classes.Upload_button}
                  disabled={loading}
                >
                  {loading ? (
                    <Loader
                      type="ThreeDots"
                      color="#fff"
                      height={10}
                      width={70}
                      visible={true}
                    />
                  ) : (
                    "Submit"
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default UpdateManager;
