import React, { useState, useEffect, useRef } from "react";
import classes from "./LoginData.module.css";
// import { BsEye } from "react-icons/bs";
// import { FiEdit } from "react-icons/fi";
// import { MdDeleteForever } from "react-icons/md";
// import { useHistory } from "react-router-dom";
// import { useReactToPrint } from "react-to-print";
import { CSVLink } from "react-csv";
// import jsPDF from "jspdf";
// import autoTable from "jspdf-autotable";
// import { AiOutlineArrowUp, AiOutlineConsoleSql } from "react-icons/ai";
// import { AiOutlineArrowDown } from "react-icons/ai";
import LoadingSpinner from "../../Components/LoadingSpinner";
import ExcelDownload from "../../Components/Articles/ExcelDownload";
import Paginate from "../../Components/Pagination";
// import ConfirmModal from "../../Components/ConfirmModal";
// import Modal from "../../Components/Modal";

const LoginData = () => {
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(true);
  // const [date, setDate] = useState({ startDate: "", endDate: "" });
  // const doc = new jsPDF();
  const date = ""
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(0);
  // const [modalShow, setModalShow] = useState(false);
  // const [confirmModalShow, setConfirmModalShow] = useState(false);
  // const [leadershipUUID, setLeadershipUUID] = useState("");
  const perPage = 20;

  const tableRef = useRef();



  // const history = useHistory();

  // const handleNewArticle = () => {
  //   history.push("/BiDashboard/newLeadershipFactory");
  // };

  const loginDataApi = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      page: currentPage,
      content: perPage,
      StartDate: date.startDate ? date.startDate : "",
      EndDate: date.endDate ? date.endDate : "",
    });

    console.log(raw);
    var requestOptions = {
      method: "POST",
      body: raw,
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_API_URL + "/v1/admin1_1/LogInData",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        if (result.status_code == 200 && result.status === "Success") {
          setData(result.Data);
          setCount(result.Count);
        }
        setLoading(false);
      })
      .catch((error) => console.log("error", error));
  };

  useEffect(() => {
    loginDataApi();
  }, [currentPage]);

  const convertDate = (date) => {
    return date.slice(0, 10).split("-").reverse().join("-");
  };

  return (
    <div className={classes.main_div}>
      <div className={classes.container}>
        {/* <div className={classes.header}>Podcasts</div> */}

        <div className={classes.header}>User's Login Data</div>
        <div className={classes.DateFilter_container}>
          <div className={classes.button_container}>
            <CSVLink data={data} filename={"userLoginData.csv"} target="_blank">
              <button className={classes.button_articleList}>CSV</button>
            </CSVLink>
            <ExcelDownload csvData={data} fileName="User-login-data" />
          </div>
          <div>
            {count > 20 && (
              <Paginate
                maxPage={count}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                perPage={perPage}
              />
            )}
          </div>
          <div className={classes.count}>Total Count : {count}</div>
          {/* <div className={classes.dateDiv}>
            <div>
              <label className={classes.dateLable}>Start Date</label>
              <input
                type="date"
                value={date.startDate}
                onChange={(e) =>
                  setDate({ ...date, startDate: e.target.value })
                }
              />
              <label className={classes.dateLable}>End Date</label>
              <input
                type="date"
                value={date.endDate}
                onChange={(e) => setDate({ ...date, endDate: e.target.value })}
              />
            </div>
            <div className={classes.buttons}>
              <button
                type="button"
                className="button"
                onClick={() => leadershipFactoryList()}
              >
                {" "}
                Search
              </button>
            </div>
          </div> */}
        </div>
        <div>
          {isLoading ? (
            <div className={classes.spinner}>
              <LoadingSpinner />{" "}
            </div>
          ) : data.length ? (
            <>
              <table
                id="article-table"
                ref={tableRef}
                className={classes.orders_table}
              >
                <tr className={classes.table_row}>
                  <th className={classes.table_heading}>Candidate Id</th>
                  <th className={classes.table_heading}>Plateform</th>
                  <th className={classes.table_heading}>Candidate Name</th>
                  <th className={classes.table_heading}>Email</th>

                  <th className={classes.table_heading}>Login Date</th>
                </tr>
                <tbody>
                  {data.map((item, idx) => (
                    <tr className={classes.table_row}>
                      <td className={classes.table_td}>{item.CandidateId}</td>
                      <td className={classes.table_td}>{item.Plateform}</td>
                      <td
                        className={classes.table_td}
                      >{`${item.firstName} ${item.middleName} ${item.lastName}`}</td>
                      <td className={classes.table_td}>{item.email}</td>
                      <td className={classes.table_td}>
                        {convertDate(item.createdDate)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          ) : (
            <h2 className={classes.serachErrorText}>"No Articles found !"</h2>
          )}
        </div>
      </div>
    </div>
  );
};

export default LoginData;
