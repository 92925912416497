import React, { useState } from "react";

import classes from "./InsertVideo.module.css";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
//import TimesBreadCrumbs from "../Components/TimesBreadCrumbs";
import Loader from "react-loader-spinner";

import EventInputs from "../Events/EventInputs";
import TimesBreadCrumbs from "../TimesBreadCrumbs";
import Modal from "../Modal";

const AddGlobalJobs = () => {

  const [loading, setLoading] = useState(false);

  const [erorrshow, setErrorShow] = useState(false);
  const [internalErrorShow, setInternalErrorShow] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [Id, setId] = useState("");




  let history = useHistory();

  const AddJobs = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Token 4YzpGk8Vf8IcxzxQ68teZ2KM5iX8SktW5RN4iz92");

    var formdata = new FormData();
    formdata.append("JobId", Id);
    formdata.append("PageName", "globalgraduatejobs");



    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_API_URL}/v1/api/apiTimes/GlobalGraduateJobsInsert`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "Success" && result.status_code === 200) {
          setModalShow(true);
        } else if (result.status_code === 400 || result.status_code === 500) {
          setInternalErrorShow(true);
        } else if (result.status_code === 300) {
          setErrorShow(true);
        }
      })
      .catch((error) => console.log("error", error))
      .finally(() => setLoading(false));
  };


  const pageInfo = {
    currentPageName: "Add jobs",
    previousPageName: "Category List",
    previousPage: "/BiDashboard/QuizCategoreisList",
  };
  const currentURL = window.location.href;

  console.log(currentURL);
  return (
    <div className={classes.top_div}>
      {modalShow && (
        <Modal
          heading={"Job added successfully"}
          onClick={() => history.push("/BiDashboard/QuizCategoreisList")}
        />
      )}
      {erorrshow && (
        <Modal
          heading={"Required Fields Are Missing"}
          onClick={() => setErrorShow(false)}
        />
      )}
      {internalErrorShow && (
        <Modal
          heading={"Internal server error"}
          onClick={() => setInternalErrorShow(false)}
        />
      )}

      <div className={classes.container}>
        <div className={classes.form}>
          <div className={classes.TimsbiBreadcrumb}>
            {" "}
            <TimesBreadCrumbs pageInfo={pageInfo} />{" "}
          </div>
          <div className={classes.form_header_container}>
            <div className={classes.form_header}>Add Jobs</div>
          </div>
          <div className={classes.form_input}>
            <div className={classes.input}>
              <EventInputs
                input={"SingleLineInput"}
                label={"ID"}
                placeholder={"Job ID"}
                onChange={(e) => setId(e.target.value)}
                value={Id}
              />






            </div>

          </div>
        </div>
        <button className={classes.Upload_button} onClick={() => AddJobs()}>
          {loading ? (
            <Loader
              type="TailSpin"
              color="white"
              height={20}
              width={50}
              className="text-center"
            />
          ) : (
            "Add"
          )}
        </button>
      </div>
    </div>
  );
};

export default AddGlobalJobs;
