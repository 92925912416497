import React, { useState, useEffect } from "react";
import LoadingSpinner from "../LoadingSpinner";
import classes from "../WeCare/wecarelist.module.css";
import { CSVLink } from "react-csv";
import ExcelDownload from "../Articles/ExcelDownload";
import TimesBreadCrumbs from "../TimesBreadCrumbs";
import { BsEye } from "react-icons/bs";
import CourseModel from "./CourseModel";
import EventInputs from "../Events/EventInputs";
import Modal from "../Modal";

const CourseList = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [errors, setErrors] = useState(false);
  const [count, setCount] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modelData, setModelData] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [alertMessage, setAlertMessage] = useState(false);

  useEffect(() => {
    Api();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  const Api = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      StartDate: startDate || date,
      EndDate:
        endDate ||
        new Date()
          .toLocaleDateString("in", {
            day: "2-digit",
            year: "numeric",
            month: "2-digit",
          })
          .split("/")
          .reverse()
          .join("-"),
      page: currentPage,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_API_URL + "/v1/admin1_1/CoursePublishRequestData",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code === 200) {
          setData(result.alldata);
          setCount(result.count);
        }
      })
      .catch((error) => console.log("error", error))
      .finally(() => setLoading(false));
  };

  const courseApproveFunc = (uuid, approveStatus) => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      id: uuid,
      approve: approveStatus == "1" ? "0" : "1",
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_API_URL + "/v1/admin1_1/ApproveCoursePublishRequest",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "Success" && result.status_code == 200) {
          setAlertMessage(
            approveStatus == "1"
              ? "Data disapproved successfully"
              : "Data approved successfully"
          );
          setModalShow(true);
          Api();
        }
      })
      .catch((error) => console.log("error", error))
      .finally(() => setLoading(false));
  };

  const formValidation = () => {
    let isValid = true;
    let error = {};

    if (!startDate || !endDate) {
      isValid = false;
      error.startDate = "Please Select Both Dates";
    }

    setErrors(error);
    return isValid;
  };

  let today = new Date();
  today.setDate(today.getDate() - 60);
  let date = new Date(today)
    .toLocaleDateString("in", {
      day: "2-digit",
      year: "numeric",
      month: "2-digit",
    })
    .split("/")
    .reverse()
    .join("-");

  const pageInfo = {
    currentPageName: "Course list",
  };

  const onModalCloseHandler = () => {
    setIsModalOpen(false);
    // history.push("/BiDashboard/articles");
  };

  return (
    <>
      {modalShow && (
        <Modal
          heading={alertMessage}
          onClick={() => {
            setModalShow(false);
          }}
        />
      )}
      <CourseModel
        isModalOpen={isModalOpen}
        data={modelData}
        setIsModalOpen={setIsModalOpen}
        onClose={onModalCloseHandler}
      />{" "}
      <div className={classes.dashboard_container}>
        <div className={classes.TimsbiBreadcrumb}>
          <TimesBreadCrumbs pageInfo={pageInfo} />{" "}
        </div>
        <div className={classes.DateFilter_container}>
          <div className={classes.button_container}>
            <CSVLink data={data} filename={"data.csv"} target="_blank">
              <button className={classes.button_articleList}>CSV</button>
            </CSVLink>
            <ExcelDownload csvData={data} fileName="Courses_list" />
          </div>
          <div className={classes.dateDiv}>
            <div>
              <label className={classes.dateLable}>Start Date</label>
              <input
                type="date"
                value={startDate || date}
                onChange={(e) => setStartDate(e.target.value)}
              />
              <label className={classes.dateLable}>End Date</label>
              <input
                type="date"
                value={
                  endDate ||
                  new Date()
                    .toLocaleDateString("in", {
                      day: "2-digit",
                      year: "numeric",
                      month: "2-digit",
                    })
                    .split("/")
                    .reverse()
                    .join("-")
                }
                onChange={(e) => setEndDate(e.target.value)}
              />
            </div>
            <div className={classes.form_error}>{errors.startDate}</div>
            <div className={classes.buttons}>
              <button
                type="button"
                className="button"
                onClick={() =>
                  formValidation() ? Api() : console.log("Something Wrong")
                }
              >
                {" "}
                Search
              </button>
            </div>
          </div>
        </div>
        {loading ? (
          <div className={classes.spinner}>
            <LoadingSpinner />
          </div>
        ) : (
          <div className={classes.table_container}>
            <div className={classes.card}>
              <div className={classes.count}>Count :</div>
              <div className={classes.count_main}>{count}</div>
            </div>
            <div className={classes.buttons1}>
              <div>
                {currentPage !== 1 ? (
                  <button
                    onClick={() => {
                      setCurrentPage(currentPage - 1);
                    }}
                    className={classes.buttonone}
                  >
                    Prev
                  </button>
                ) : (
                  <></>
                )}
                <span>{data.length < 20 ? <></> : currentPage}</span>
                {data.length < 20 ? (
                  <></>
                ) : (
                  <button
                    onClick={() => {
                      setCurrentPage(currentPage + 1);
                    }}
                    className={classes.buttonone}
                  >
                    Next
                  </button>
                )}
              </div>
            </div>
            <div className={classes.container}>
              {data.length > 0 ? (
                <table className={classes.orders_table}>
                  <tr className={classes.table_row}>
                    <th className={classes.table_heading}>Id</th>
                    <th className={classes.table_heading}>Course Name</th>
                    <th className={classes.table_heading}>Course Category</th>
                    <th className={classes.table_heading}>Course Type</th>
                    <th className={classes.table_heading}>Platform Name</th>
                    <th className={classes.table_heading}>Mobile</th>
                    <th className={classes.table_heading}>Email</th>
                    <th className={classes.table_heading}>Created Date</th>
                    <th className={classes.table_heading}>Approve</th>
                    <th className={classes.table_heading}>View</th>
                  </tr>

                  <tbody>
                    {data.map((item, value) => {
                      return (
                        <tr className={classes.table_row}>
                          <td className={classes.table_td}>{item.id}</td>

                          <td className={classes.table_td}>
                            {item.courseName}
                          </td>
                          <td className={classes.table_td}>
                            {item.courseCategory}
                          </td>
                          <td className={classes.table_td}>
                            {item.course_type}
                          </td>
                          <td className={classes.table_td}>
                            {item.platformName}
                          </td>
                          <td className={classes.table_td}>{item.mobile}</td>
                          <td className={classes.table_td}>{item.email}</td>
                          <td className={classes.table_td}>
                            {item.createdAt.split("T")[0]
                              ? item.createdAt.split("T")[0]
                              : item.createdAt}
                          </td>
                          <td
                            className={classes.table_td}
                            style={{ width: "150px" }}
                          >
                            <EventInputs
                              input={"CheckBox"}
                              disabled={item.IsApproved == 1 ? true : false}
                              onChange={() =>
                                courseApproveFunc(item.id, item.IsApproved)
                              }
                              label={
                                item.IsApproved == 1
                                  ? "Approved"
                                  : "Not Approved"
                              }
                              value={item.IsApproved == 1 ? true : false}
                            />
                          </td>
                          <td
                            className={classes.table_td}
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setModelData(item);
                              setIsModalOpen(true);
                            }}
                          >
                            <span className={classes.table_td_Link}>
                              <BsEye />
                            </span>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              ) : (
                <div className={classes.No_data}>No data found!</div>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default CourseList;
