/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react";
import LoadingSpinner from "../../Components/LoadingSpinner";
import classes from "./lottieFileList.module.css";

import { Link } from "react-router-dom";
import TimesBreadCrumbs from "../../Components/TimesBreadCrumbs";
import { BsArrowDownRightSquare } from "react-icons/bs";

const LottieFileList = () => {
  const [isLoading, setLoading] = useState(false);
  const [bannerlist, setBannerlist] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [errors, setErrors] = useState(false);

  const ListImageApi = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      StartDate: startDate || date,
      EndDate:
        endDate ||
        new Date()
          .toLocaleDateString("in", {
            day: "2-digit",
            year: "numeric",
            month: "2-digit",
          })
          .split("/")
          .reverse()
          .join("-"),
      Page: currentPage,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_API_URL + "/adminapi/LottieBannerAdminList",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code === 200) {
          setBannerlist(result.data);
        }
      })
      .catch((error) => console.log("error", error))
      .finally(() => setLoading(false));
  };
  const DeleteApi = (bannerId) => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      lottieBannerUUID: bannerId,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_API_URL + "/adminapi/DeleteLottieBanner",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code === 200) {
          ListImageApi();
        }
      })
      .catch((error) => console.log("error", error));
  };
  useEffect(() => {
    ListImageApi();
  }, [currentPage]);

  const pageInfo = {
    currentPageName: "Lottie File List Page",
  };

  const formValidation = () => {
    let isValid = true;
    let error = {};

    if (!startDate || !endDate) {
      isValid = false;
      error.startDate = "Please Select Both Dates";
    }

    setErrors(error);
    return isValid;
  };

  let today = new Date();
  today.setDate(today.getDate() - 30);
  let date = new Date(today)
    .toLocaleDateString("in", {
      day: "2-digit",
      year: "numeric",
      month: "2-digit",
    })
    .split("/")
    .reverse()
    .join("-");

  return (
    <>
      <div className={classes.dashboard_container}>
        <div className={classes.TimsbiBreadcrumb}>
          {" "}
          <div>
            <TimesBreadCrumbs pageInfo={pageInfo} />{" "}
          </div>
          <div className={classes.dateDiv}>
            <div>
              <label className={classes.dateLable}>Start Date</label>
              <input
                type="date"
                value={startDate || date}
                onChange={(e) => setStartDate(e.target.value)}
              />
              <label className={classes.dateLable}>End Date</label>
              <input
                type="date"
                value={
                  endDate ||
                  new Date()
                    .toLocaleDateString("in", {
                      day: "2-digit",
                      year: "numeric",
                      month: "2-digit",
                    })
                    .split("/")
                    .reverse()
                    .join("-")
                }
                onChange={(e) => setEndDate(e.target.value)}
              />
            </div>
            <div className={classes.form_error}>{errors.startDate}</div>
            <div className={classes.buttons}>
              <button
                type="button"
                className={classes.serch_btn}
                onClick={() =>
                  formValidation()
                    ? ListImageApi()
                    : console.log("Something Wrong")
                }
              >
                {" "}
                Search
              </button>
            </div>
          </div>
        </div>
        {isLoading ? (
          <div className={classes.spinner}>
            <LoadingSpinner />
          </div>
        ) : (
          <div className={classes.table_container}>
            <div className={classes.buttons1}>
              {currentPage !== 1 ? (
                <button
                  onClick={() => {
                    setCurrentPage(currentPage - 1);
                  }}
                  className={classes.buttonone}
                >
                  Prev
                </button>
              ) : (
                <></>
              )}
              <span>{bannerlist.length < 20 ? <></> : currentPage}</span>
              {bannerlist.length < 20 ? (
                <></>
              ) : (
                <button
                  onClick={() => {
                    setCurrentPage(currentPage + 1);
                  }}
                  className={classes.buttonone}
                >
                  Next
                </button>
              )}
              <div>
                <button className={classes.Addbtn}>
                  <Link
                    to="/BiDashboard/InsertLottieJson"
                    className={classes.Edit_link}
                  >
                    Insert Lottie File
                  </Link>
                </button>
              </div>
            </div>
            <div className={classes.container}>
              {bannerlist.length > 0 ? (
                <table className={classes.orders_table}>
                  <tr className={classes.table_row}>
                    <th className={classes.table_heading}>SerialNumber</th>
                    <th className={classes.table_heading}>Desktop Image </th>
                    <th className={classes.table_heading}>Mobile Image </th>
                    {/* <th className={classes.table_heading}>Banner Id</th> */}
                    <th className={classes.table_heading}>Is Deleted</th>
                    <th className={classes.table_heading}>Is Published</th>
                    <th className={classes.table_heading}>Page Name</th>
                    <th className={classes.table_heading}>Redirection Url</th>
                    <th className={classes.table_heading}>Created Date</th>
                    <th className={classes.table_heading}>Updated Date</th>
                    <th className={classes.table_heading}>Edit</th>
                    <th className={classes.table_heading}>Delete</th>
                    {/* <th className={classes.table_heading}>Update</th> */}
                  </tr>

                  <tbody>
                    {bannerlist.map((item, value) => {
                      return (
                        <tr className={classes.table_row}>
                          <td className={classes.table_td}>
                            {item.serialNumber}
                          </td>
                          <td
                            className={`${classes.table_td} ${classes.viewJson}`}
                          >
                            <a
                              href={item.redirectionDesktopJson}
                              target="_blank"
                            >
                              <BsArrowDownRightSquare />
                            </a>
                          </td>
                          <td className={classes.table_td}>
                            <a
                              href={item.redirectionMobileJson}
                              target="_blank"
                            >
                              <BsArrowDownRightSquare />
                            </a>
                          </td>
                          {/* <td className={classes.table_td}>{item.lottieBannerUUID}</td> */}
                          <td className={classes.table_td}>{item.IsDeleted}</td>
                          <td className={classes.table_td}>{item.IsLive}</td>
                          <td className={classes.table_td}>{item.pageName}</td>

                          <td className={classes.table_td}>
                            {item.redirectionDesktopJson ? (
                              <a
                                href={item.redirectionDesktopUrl}
                                target="_blank"
                                rel="noreferrer"
                              >
                                RedirectionUrl
                              </a>
                            ) : (
                              "NA"
                            )}
                          </td>
                          <td className={classes.table_td}>{item.createdAt}</td>
                          <td className={classes.table_td}>{item.updatedAt}</td>
                          <td className={classes.table_td}>
                            <Link
                              to={
                                "/BiDashboard/UpdateLottieJson/" +
                                item.lottieBannerUUID
                              }
                              className={classes.editbtn}
                            >
                              Edit
                            </Link>
                          </td>
                          <td className={classes.table_td}>
                            <button
                              onClick={() => {
                                DeleteApi(item.lottieBannerUUID);
                              }}
                              className={classes.delbtn}
                            >
                              Delete
                            </button>
                          </td>
                          {/* <td className={classes.table_td}>
                            <Link
                              to={"/BiDashboard/UpdatePolls/" + item.id}
                              className={classes.Edit_link_two}
                            >
                              Edit
                            </Link>
                          </td> */}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              ) : (
                <div className={classes.No_data}>No data found!</div>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default LottieFileList;
