import React, { useState, useEffect } from "react";

import classes from "../DataPoints/admindatapoints.module.css";
import { CSVLink } from "react-csv";
import LoadingSpinner from "../Components/LoadingSpinner";
import { Link } from "react-router-dom";
import ExcelDownload from "../Components/Articles/ExcelDownload";

const FeaturedCompanyDetail = () => {
  const [isLoading, setLoading] = useState(false);
  const [admindata, setAdmindata] = useState([]);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [firstdate, setFirstDate] = useState("");
  const [lastdate, setLastDate] = useState("");
  const [count, setCount] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [errors, setErrors] = useState(false);
  const currentURL = window.location.href.split("/").pop();
  useEffect(() => {
    AdmindataApi();
  }, [currentPage]);

  const AdmindataApi = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      JobId: currentURL,
      startDate: startDate,
      endDate: endDate,
      Page: currentPage,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_API_URL + "/adminapi/getFeaturedEmployersDetail",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setAdmindata(result.data);
        setCount(result.count);
      })
      .catch((error) => console.log("error", error))
      .finally(() => setLoading(false));
  };

  return (
    <div className={classes.dashboard_container}>
      <div className={classes.events_title}>
        Featured Comapany Job Detaild Page
      </div>
      <div className={classes.DateFilter_container}>
        <div className={classes.button_container}>
          <CSVLink
            data={admindata}
            filename={"FeaturedCompanyDetails.csv"}
            target="_blank"
          >
            <button className={classes.button_articleList}>CSV</button>
          </CSVLink>
          <ExcelDownload
            csvData={admindata}
            fileName="FeaturedCompanyDetails"
          />
        </div>
        <div className={classes.dateDiv}>
          <div>
            <label className={classes.dateLable}>Start Date</label>
            <input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
            <label className={classes.dateLable}>End Date</label>
            <input
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </div>

          <div className={classes.buttons}>
            <button
              type="button"
              className="button"
              onClick={() => AdmindataApi()}
            >
              Search
            </button>
          </div>
        </div>
      </div>
      <div className={classes.card}>
        <div className={classes.count}>Count :</div>
        <div className={classes.count_main}>{count}</div>
      </div>
      {count > 10 ? (
        <div className={classes.buttons1}>
          {currentPage !== 1 ? (
            <button
              onClick={() => {
                setCurrentPage(currentPage - 1);
              }}
              className={classes.buttonone}
            >
              Prev
            </button>
          ) : (
            <></>
          )}
          <span>{currentPage}</span>
          {admindata.length > 9 ? (
            <>
              <button
                onClick={() => {
                  setCurrentPage(currentPage + 1);
                }}
                className={classes.buttonone}
              >
                Next
              </button>
            </>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <></>
      )}
      {isLoading ? (
        <div className={classes.spinner}>
          <LoadingSpinner />
        </div>
      ) : (
        <div className={classes.table_container}>
          <div className={classes.container}>
            {admindata.length > 0 ? (
              <table className={classes.orders_table}>
                <tr className={classes.table_row}>
                  <th className={classes.table_heading}>Sno</th>
                  <th className={classes.table_heading}>Id</th>
                  <th className={classes.table_heading}>Position Title</th>

                  <th className={classes.table_heading}>Company Url</th>
                  <th className={classes.table_heading}>Redirected From</th>

                  <th className={classes.table_heading}>Date</th>
                </tr>

                <tbody>
                  {admindata.map((item, value) => {
                    return (
                      <tr className={classes.table_row}>
                        <td className={classes.table_td}>{item.sno}</td>
                        <td className={classes.table_td}>{item.id}</td>
                        <td className={classes.table_td}>
                          {item.positionTitle}
                        </td>
                        <td className={classes.table_td}>
                          <a href={item.cUrl} target="_blank">
                            Company Url
                          </a>
                        </td>

                        <td className={classes.table_td}>
                          <a href={item.redirectedFrom} target="_blank">
                            Redirected From
                          </a>
                        </td>

                        <td className={classes.table_td}>{item.date}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            ) : (
              <div className={classes.No_data}>No data found!</div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default FeaturedCompanyDetail;
