import React, { useState, useEffect } from "react";
import LoadingSpinner from "../../../Components/LoadingSpinner";
import classes from "../../Webstory/webstorylist.module.css";

import { Link } from "react-router-dom";
import { CSVLink } from "react-csv";
import ExcelDownload from "../../Articles/ExcelDownload";
import Loader from "react-loader-spinner";
import TimesBreadCrumbs from "../../TimesBreadCrumbs";
import Modal from "../../../Components/Modal";
import { useHistory } from "react-router-dom";
import EventInputs from "../../Events/EventInputs";
const WebstoryList = () => {
  const [loading, setLoading] = useState(false);
  const [webstorylist, setWebstorylist] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [errors, setErrors] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const history = useHistory();
  useEffect(() => {
    WebstoryListApi();
  }, [currentPage]);

  const WebstoryListApi = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      StartDate: startDate || date,
      EndDate:
        endDate ||
        new Date()
          .toLocaleDateString("in", {
            day: "2-digit",
            year: "numeric",
            month: "2-digit",
          })
          .split("/")
          .reverse()
          .join("-"),
      page: currentPage,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_API_URL + "/adminapi/PagewiseWebstoryList",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code === 200) {
          setWebstorylist(result.data);
        }
      })
      .catch((error) => console.log("error", error))
      .finally(() => setLoading(false));
  };

  const DeleteApi = (ID) => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      Id: ID,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_API_URL + "/adminapi/DeletePagewiseWebstory",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code === 200) {
          WebstoryListApi();
        }
      })
      .catch((error) => console.log("error", error));
  };
  const formValidation = () => {
    let isValid = true;
    let error = {};

    if (!startDate || !endDate) {
      isValid = false;
      error.startDate = "Please Select Both Dates";
    }

    setErrors(error);
    return isValid;
  };

  // const Live = (WebStoriesSummaryID, isLive) => {
  //     var myHeaders = new Headers();
  //     myHeaders.append(
  //         "Authorization",
  //         "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
  //     );
  //     myHeaders.append("Content-Type", "application/json");

  //     var raw = JSON.stringify({
  //         WebStoriesSummaryID: WebStoriesSummaryID,
  //         IsLive: isLive == "1" ? "0" : "1",
  //     });

  //     var requestOptions = {
  //         method: "POST",
  //         headers: myHeaders,
  //         body: raw,
  //         redirect: "follow",
  //     };

  //     fetch(

  //         process.env.REACT_APP_API_URL + "/v1/api/apiTimes/WebstoriesSummaryLive",
  //         requestOptions
  //     )
  //         .then((response) => response.json())
  //         .then((result) => {
  //             if (result.status_code === 200) {
  //                 WebstoryListApi()
  //             }
  //         })
  //         .catch((error) => console.log("error", error));
  // };

  let today = new Date();
  today.setDate(today.getDate() - 60);
  let date = new Date(today)
    .toLocaleDateString("in", {
      day: "2-digit",
      year: "numeric",
      month: "2-digit",
    })
    .split("/")
    .reverse()
    .join("-");

  const pageInfo = {
    currentPageName: "WebStory List",
  };
  return (
    <>
      {modalShow && (
        <Modal
          heading={"Data Updated successfully"}
          onClick={() => history.push("/BiDashboard/WeCareList")}
        />
      )}
      <div className={classes.dashboard_container}>
        <div className={classes.TimsbiBreadcrumb}>
          <TimesBreadCrumbs pageInfo={pageInfo} />{" "}
        </div>
        <div className={classes.DateFilter_container}>
          <div className={classes.button_container}>
            <CSVLink
              data={webstorylist ? webstorylist : ""}
              filename={"/AddWebstorySummary.csv"}
              target="_blank"
            >
              <button className={classes.button_articleList}>CSV</button>
            </CSVLink>
            <ExcelDownload
              csvData={webstorylist}
              fileName="/AddWebstorySummary"
            />
          </div>
          <div className={classes.dateDiv}>
            <div>
              <label className={classes.dateLable}>Start Date</label>
              <input
                type="date"
                value={startDate || date}
                onChange={(e) => setStartDate(e.target.value)}
              />
              <label className={classes.dateLable}>End Date</label>
              <input
                type="date"
                value={
                  endDate ||
                  new Date()
                    .toLocaleDateString("in", {
                      day: "2-digit",
                      year: "numeric",
                      month: "2-digit",
                    })
                    .split("/")
                    .reverse()
                    .join("-")
                }
                onChange={(e) => setEndDate(e.target.value)}
              />
            </div>
            <div className={classes.form_error}>{errors.startDate}</div>
            <div className={classes.buttons}>
              <button
                type="button"
                className="button"
                onClick={() =>
                  formValidation()
                    ? WebstoryListApi()
                    : console.log("Something Wrong")
                }
              >
                {" "}
                Search
              </button>
            </div>
          </div>
        </div>
        {loading ? (
          <div className={classes.spinner}>
            <LoadingSpinner />
          </div>
        ) : (
          <div className={classes.table_container}>
            <div className={classes.buttons1}>
              <div>
                {currentPage !== 1 ? (
                  <button
                    onClick={() => {
                      setCurrentPage(currentPage - 1);
                    }}
                    className={classes.buttonone}
                  >
                    Prev
                  </button>
                ) : (
                  <></>
                )}
                <span>{webstorylist?.length < 20 ? <></> : currentPage}</span>
                {webstorylist?.length < 20 ? (
                  <></>
                ) : (
                  <button
                    onClick={() => {
                      setCurrentPage(currentPage + 1);
                    }}
                    className={classes.buttonone}
                  >
                    Next
                  </button>
                )}
              </div>
              {/* <div>
                                <button className={classes.Addbtn}>
                                    <Link
                                        to="/BiDashboard/preview"
                                        className={classes.Edit_link}
                                    >
                                        Preview
                                    </Link>
                                </button>
                            </div> */}

              <div>
                <button className={classes.Addbtn}>
                  <Link
                    to="/BiDashboard/AddPageWiseWebstory"
                    className={classes.Edit_link}
                  >
                    Add Pagewise
                  </Link>
                </button>
              </div>
            </div>
            <div className={classes.container}>
              {webstorylist?.length > 0 ? (
                <table className={classes.orders_table}>
                  <tr className={classes.table_row}>
                    <th className={classes.table_heading}>Id</th>

                    <th className={classes.table_heading}>Image </th>

                    <th className={classes.table_heading}>Title</th>
                    <th className={classes.table_heading}>Page Name</th>
                    <th className={classes.table_heading}>Seo Url</th>

                    <th className={classes.table_heading}>Created At</th>
                    {/* <th className={classes.table_heading}>Updated At</th> */}
                    <th className={classes.table_heading}>Updated At</th>
                    <th className={classes.table_heading}>Status</th>

                    {/* <th className={classes.table_heading}>View Detail</th> */}

                    <th className={classes.table_heading}>Delete</th>
                  </tr>

                  <tbody>
                    {webstorylist?.map((item, value) => {
                      return (
                        <tr key={value} className={classes.table_row}>
                          <td className={classes.table_td}>{item.ID}</td>
                          <td className={classes.table_td}>
                            <img
                              src={item.Image}
                              alt="icon"
                              className={classes.IconImage}
                            />
                          </td>

                          <td className={classes.table_td}>{item.Title}</td>
                          <td className={classes.table_td}>{item.PageName}</td>
                          <td className={classes.table_td}>{item.SeoURL}</td>

                          <td className={classes.table_td}>
                            {item.CreatedAt.slice(0, 10)}
                          </td>
                          <td className={classes.table_td}>
                            {item.UpdatedAt.slice(0, 10)}
                          </td>
                          <td className={classes.table_td}>
                            {item.IsLive === "1" ? "Live" : "Not Live"}
                            {/* <EventInputs
                                                            input={"CheckBox"}
                                                            disabled={
                                                                item.IsApproved == 1
                                                                    ? true
                                                                    : false
                                                            }
                                                            // onChange={() =>
                                                            //     Live(
                                                            //         item.WebStoriesSummaryID,
                                                            //         item.IsLive
                                                            //     )
                                                            // }
                                                            label={
                                                                item.IsLive == 1
                                                                    ? "Live"
                                                                    : "Not Live"
                                                            }
                                                            value={item.IsLive == 1 ? true : false}
                                                        /> */}
                          </td>
                          {/* <td className={classes.table_td}>
                                                        <Link
                                                            to={
                                                                "/BiDashboard/Webstoryupdate/" +
                                                                item.WebStoriesSummaryID
                                                            }
                                                            className={classes.Edit_link_two}
                                                        >
                                                            Edit
                                                        </Link>
                                                    </td> */}
                          {/* <td className={classes.table_td}>
                                                        <Link
                                                            to={
                                                                "/BiDashboard/Webstorydetail/" +
                                                                item.WebStoriesSummaryID
                                                            }
                                                            className={classes.Edit_link_two}
                                                        >
                                                            View
                                                        </Link>
                                                    </td> */}

                          <td className={classes.table_td}>
                            <button
                              className={classes.delbtn}
                              onClick={() => DeleteApi(item.ID)}
                            >
                              {loading ? (
                                <Loader
                                  type="TailSpin"
                                  color="white"
                                  height={20}
                                  width={50}
                                  className="text-center"
                                />
                              ) : (
                                "Delete"
                              )}
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              ) : (
                <div className={classes.No_data}>No data found!</div>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default WebstoryList;
