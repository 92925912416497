import React, { useEffect, useState } from "react";

import classes from "./insertLottieFile.module.css";
import EventInputs from "../../Components/Events/EventInputs";
import Modal from "../../Components/Modal";
import { useHistory } from "react-router-dom";
import TimesBreadCrumbs from "../../Components/TimesBreadCrumbs";
import Loader from "react-loader-spinner";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { BsArrowDownSquare } from "react-icons/bs";

const UpdateLottieFile = () => {
  const [sno, setSno] = useState("");
  const [loading, setLoading] = useState("");
  const [pageName, setPageName] = useState("");
  const [desktopImage, setDesktopImage] = useState("");
  const [mobileImage, setMobileImage] = useState("");
  const [desktopurl, setDesktopurl] = useState("");
  const [mobileurl, setMobileurl] = useState("");
  const [oldDesktopJson, setOldDesktopJson] = useState("");
  const [oldMobileJson, setOldMobileJson] = useState("");

  const [check, setCheck] = useState(0);
  const [erorrshow, setErrorShow] = useState(false);
  const [internalErrorShow, setInternalErrorShow] = useState(false);
  const [modalShow, setModalShow] = useState(false);

  let { id } = useParams();
  let history = useHistory();



  const getBannerDetailById = (id) => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );

    var formdata = new FormData();
    formdata.append("lottieBannerUUID", id);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_API_URL + "/adminapi/BannerGetById",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "Success" && result.status_code === 200) {
          setSno(result.data.serialNumber);
          setPageName(result.data.pageName);
          setOldDesktopJson(result.data.redirectionDesktopJson);
          setOldMobileJson(result.data.redirectionMobileJson);
          setDesktopurl(result.data.redirectionDesktopUrl);
          setMobileurl(result.data.redirectionMobileUrl);
          setCheck(result.data.IsLive === "1" ? true : false);
        }
      })
      .catch((error) => console.log("error", error))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getBannerDetailById(id);
  }, []);

  const InsertbannerApi = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );

    var formdata = new FormData();

    formdata.append("lottieBannerUUID", id);
    formdata.append("serialNumber", sno);
    formdata.append("pageName", pageName);
    formdata.append("desktopImageJSON", desktopImage);
    formdata.append("mobileImageJSON", mobileImage);
    formdata.append("redirectionDesktopUrl", desktopurl);
    formdata.append("redirectionMobileUrl", mobileurl);
    formdata.append("IsLive", check);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_API_URL + "/adminapi/EditLottieBanner",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "SUCCESS" && result.status_code === 200) {
          setModalShow(true);
        } else if (result.status_code === 400 || result.status_code === 500) {
          setInternalErrorShow(true);
        } else if (result.status_code === 300) {
          setErrorShow(true);
        }
      })
      .catch((error) => console.log("error", error))
      .finally(() => setLoading(false));
  };

  const pageInfo = {
    currentPageName: "Update Lottie File",
    previousPageName: "Lottie File List Page",
    previousPage: "/BiDashboard/LottieJsonList",
  };

  return (
    <div className={classes.top_div}>
      {modalShow && (
        <Modal
          heading={"Data updated successfully"}
          onClick={() => history.push("/BiDashboard/LottieJsonList")}
        />
      )}
      {erorrshow && (
        <Modal
          heading={"Required Fields Are Missing"}
          onClick={() => setErrorShow(false)}
        />
      )}
      {internalErrorShow && (
        <Modal
          heading={"Internal server error"}
          onClick={() => setInternalErrorShow(false)}
        />
      )}

      <div className={classes.container}>
        <div className={classes.form}>
          <div className={classes.TimsbiBreadcrumb}>
            {" "}
            <TimesBreadCrumbs pageInfo={pageInfo} />{" "}
          </div>
          <div className={classes.form_header_container}>
            <div className={classes.form_header}>Update Lottie File</div>
            <div className={classes.checkbox}>
              <span className={classes.live}>Live</span>
              <EventInputs
                input={"CheckBox"}
                onChange={() => setCheck(!check === true ? 1 : 0)}
                value={check}
              />
            </div>
          </div>
          <div className={classes.form_input}>
            <div className={classes.input}>
              <EventInputs
                input={"Number"}
                label={"Serial Number"}
                placeholder={"Serial Number"}
                onChange={(e) => setSno(e.target.value)}
                value={sno}
              />

              <EventInputs
                input={"JsonInput"}
                label={"Desktop Json"}
                placeholder={"Desktop Json"}
                onChange={(e) => setDesktopImage(e.target.files[0])}
              />

              <div style={{ marginLeft: "10px" }}>
                <a href={oldDesktopJson} target={"_blank"}>
                  <BsArrowDownSquare />
                </a>
              </div>
              <EventInputs
                input={"SingleLineInput"}
                label={"Redirection Desktop Url"}
                placeholder={"Redirection Desktop Url"}
                onChange={(e) => setDesktopurl(e.target.value)}
                value={desktopurl}
              />
            </div>

            <div className={classes.input}>
              <EventInputs
                input={"SingleLineInput"}
                label={"Page Name"}
                placeholder={"Page Name"}
                onChange={(e) => setPageName(e.target.value)}
                value={pageName}
              />
              <EventInputs
                input={"JsonInput"}
                label={"Mobile Json"}
                placeholder={"Mobile Json"}
                onChange={(e) => {
                  setMobileImage(e.target.files[0]);
                }}
              />
              <div style={{ marginLeft: "10px" }}>
                <a href={oldMobileJson} target={"_blank"}>
                  <BsArrowDownSquare />
                </a>
              </div>
              <EventInputs
                input={"SingleLineInput"}
                label={"Redirection Mobile Url"}
                placeholder={"Redirection Mobile Url"}
                onChange={(e) => setMobileurl(e.target.value)}
                value={mobileurl}
              />
            </div>
          </div>
        </div>
        <button
          className={classes.Upload_button}
          onClick={() => InsertbannerApi()}
        >
          {loading ? (
            <Loader
              type="TailSpin"
              color="white"
              height={20}
              width={50}
              className="text-center"
            />
          ) : (
            "Submit"
          )}
        </button>
      </div>
    </div>
  );
};

export default UpdateLottieFile;
