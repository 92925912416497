import React, { useState } from "react";
//import EventInputs from "./EventInputs";
import classes from "../Global/AddEvents.module.css";
import { Link, useHistory } from "react-router-dom";
import BackButton from "../BackButton";
import Modal from "../Modal";
import TextEditor from "../TextEditor";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import EventInputs from "../Events/EventInputs";

const CreateTestimonials = () => {
  const history = useHistory();

  const [Name, setName] = useState("");

  const [description, setDescription] = useState("");

  const [Image, setImage] = useState("");
  const [companyImage, setCompanyImage] = useState("");
  const [check, setCheck] = useState("");

  const [modalShow, setModalShow] = useState(false);
  const [erorrshow, setErrorShow] = useState(false);
  const [internalErrorShow, setInternalErrorShow] = useState(false);

  const NewEvent = () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );

    var formdata = new FormData();
    formdata.append("Name", Name);

    formdata.append("Description", description);

    formdata.append("CompanyLogo", companyImage);
    formdata.append("AuthorImage", Image);

    formdata.append("IsLive", check);

    console.log(formdata);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };
    fetch(
      process.env.REACT_APP_API_URL + "/adminapi/InsertTestimonial",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "Success" && result.status_code === 200) {
          setModalShow(true);
        } else if (result.status_code === 400 || result.status_code === 500) {
          setInternalErrorShow(true);
        } else if (result.status_code === 300) {
          setErrorShow(true);
        }
      })
      .catch((error) => console.log("error", error));
  };

  const onBodyChangeHandler = (textEditorValue) => {
    setDescription(textEditorValue);
  };

  return (
    <div className={classes.top_div}>
      {modalShow && (
        <Modal
          heading={"Data inserted successfully"}
          onClick={() => history.push("/BiDashboard/TestimonialsList")}
        />
      )}
      {erorrshow && (
        <Modal
          heading={"Required Fields Missing"}
          onClick={() => setErrorShow(false)}
        />
      )}
      {internalErrorShow && (
        <Modal
          heading={"Internal server error"}
          onClick={() => setInternalErrorShow(false)}
        />
      )}

      <div className={classes.container}>
        <div className={classes.form}>
          <div className={classes.form_header_container}>
            <div className={classes.form_header}>Add Testimonials</div>
            <Link
              to="/BiDashboard/TestimonialsList"
              className={classes.BackButton}
            >
              <BackButton />
            </Link>
          </div>
          <div className={classes.form_input}>
            <div className={classes.input}>
              <EventInputs
                input={"SingleLineInput"}
                label={"Name*"}
                placeholder={"Name*"}
                onChange={(e) => setName(e.target.value)}
                value={Name}
              />

              <div className={classes.AddHrProfessional_Description}>
                Description
              </div>
              <div className={classes.AddHrProfessional_Desc}>
                <div>
                  <TextEditor
                    onChangeContent={setDescription}
                    value={description}
                  />
                </div>
              </div>
              {/* <div className={classes.AddHrProfessional_Description}>Description</div>
              <div className={classes.textEditorWrapper}>
                <TextEditor onChange={onBodyChangeHandler} value={description}/>
              </div> */}
            </div>
            <div className={classes.input}>
              <EventInputs
                input={"ImageInput"}
                label={"Author Image"}
                placeholder={"Event Speaker Image "}
                onChange={(e) => setImage(e.target.files[0])}
                type={"file"}
                accept=".png, .jpg, .jpeg, .webp"
              />
              <EventInputs
                input={"ImageInput"}
                label={"Company Logo"}
                placeholder={"Event Image*"}
                onChange={(e) => setCompanyImage(e.target.files[0])}
                type={"file"}
                accept=".png, .jpg, .jpeg, .webp"
              />
            </div>
            <div className={classes.checkbox}>
              <EventInputs
                input={"CheckBox"}
                onChange={() => setCheck(!check === true ? 1 : 0)}
                label={"Want to publish this Testimonials"}
                value={check}
              />
            </div>
          </div>
        </div>
        <button className={classes.Upload_button} onClick={() => NewEvent()}>
          submit
        </button>
      </div>
    </div>
  );
};

export default CreateTestimonials;
