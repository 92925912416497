import React, { useState } from "react";

import classes from "./AddForherById.module.css";
import EventInputs from "../Events/EventInputs";
import Modal from "../Modal";
import Loader from "react-loader-spinner";
import { useHistory } from "react-router-dom";
import TimesBreadCrumbs from "../TimesBreadCrumbs";

const AddWebstory = () => {
  const [loading, setLoading] = useState("");
  const [check, setCheck] = useState(0);
  const [erorrshow, setErrorShow] = useState(false);
  const [internalErrorShow, setInternalErrorShow] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const history = useHistory();
  const [postId, setPostId] = useState("");
  const [postType, setPostType] = useState("");

  const dropdownList = [
    {
      id: 1,
      type: "forherevents",
    },
    {
      id: 2,
      type: "forhercourses",
    },
    {
      id: 3,
      type: "forherjobs",
    },
  ];
  // const formValidation = () =>{
  //   let isValid = true;
  //   let error = {};
  //   if(postId===""){
  //     error.postid = "Please enter post id"
  //     isValid=false;
  //   }
  //   if(postType ===""){
  //     error.postType = "Please select post type"
  //     isValid=false
  //   }
  //   // setErrorShow(e)
  // }
  const AddWebstory = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      PageName: postType,
      Id: postId,
      Status: check,
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    console.log(raw, "chhhh");

    fetch(
      process.env.REACT_APP_API_URL + "/v1/admin1_1/ForHerPage",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "Success" && result.status_code === 200) {
          setModalShow(true);
        } else if (result.status_code === 400 || result.status_code === 500) {
          setInternalErrorShow(true);
        } else if (result.status_code === 300) {
          setErrorShow(true);
        }
      })
      .catch((error) => console.log("error", error))
      .finally(() => setLoading(false));
  };

  const pageInfo = {
    currentPageName: "For her",
    previousPage: "",
  };

  //   const imageHandlerChange = (e) => {
  //     if (e.target.files) {
  //       const fileArray = Array.from(e.target.files).map((file) =>
  //         URL.createObjectURL(file)
  //       );
  //       setUpload(fileArray);
  //       setUploadUrls(e.target.files);
  //     }
  //   };

  const onClickModal = () => {
    setModalShow(false);
    setCheck(0);
    setPostId("");
    setPostType("");
  };

  return (
    <div className={classes.top_div}>
      {modalShow && (
        <Modal
          heading={"Data inserted successfully"}
          onClick={() => onClickModal()}
        />
      )}
      {erorrshow && (
        <Modal
          heading={"Required Fields Are Missing"}
          onClick={() => setErrorShow(false)}
        />
      )}
      {internalErrorShow && (
        <Modal
          heading={"Internal server error"}
          onClick={() => setInternalErrorShow(false)}
        />
      )}

      <div className={classes.container}>
        <div className={classes.form}>
          <div className={classes.TimsbiBreadcrumb}>
            {" "}
            <TimesBreadCrumbs pageInfo={pageInfo} />{" "}
          </div>
          <div className={classes.form_header_container}>
            <div className={classes.form_header}>For her</div>
            <div className={classes.checkbox}>
              <span className={classes.live}>Live</span>
              <EventInputs
                input={"CheckBox"}
                onChange={() => setCheck(!check === true ? 1 : 0)}
                value={check}
              />
            </div>
          </div>
          <div className={classes.form_input}>
            <div className={classes.input}>
              <EventInputs
                input={"SingleLineInput"}
                label={"Key index"}
                placeholder={"Please enter id"}
                onChange={(e) => setPostId(e.target.value)}
                value={postId}
              />
            </div>
            <div className={classes.input}>
              <EventInputs
                input={"smalldropdown"}
                label={"Post type"}
                placeholder={"Post type"}
                displayData={dropdownList}
                onChange={(e) => setPostType(e.target.value)}
                value={postType}
              />
            </div>
          </div>
        </div>
        <button className={classes.Upload_button} onClick={() => AddWebstory()}>
          {loading ? (
            <Loader
              type="TailSpin"
              color="white"
              height={20}
              width={50}
              className="text-center"
            />
          ) : (
            "Submit"
          )}
        </button>
      </div>
    </div>
  );
};

export default AddWebstory;
