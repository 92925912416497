import React, { useEffect, useState } from "react";
import EventInputs from "./EventInputs";
import classes from "./AddEvents.module.css";
import { Link, useHistory, useParams } from "react-router-dom";
import BackButton from "../BackButton";
import Modal from "../Modal";

import "suneditor/dist/css/suneditor.min.css";

const EditOffer = () => {
  const history = useHistory();
  const { id } = useParams();
  const [offerTitle, setOfferTitle] = useState("");
  const [location, setLocation] = useState("");
  const [company, setCompany] = useState("");
  const [discount, setDiscount] = useState("");
  const [promo, setPromo] = useState("");
  const [terms, setTerms] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [webImage, setWebImage] = useState("");
  const [mobileImage, setMobileImage] = useState("");
  const [editableCImage, setEditableCImage] = useState(true);
  const [editableCImage1, setEditableCImage1] = useState(true);

  const [modalShow, setModalShow] = useState(false);
  const [check, setCheck] = useState(0);
  const [erorrshow, setErrorShow] = useState(false);
  const [internalErrorShow, setInternalErrorShow] = useState(false);
  const [url, setUrl] = useState("");
  useEffect(() => {
    NewEvent();
  }, []);

  const NewEvent = () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      WeCareOfferUUID: id,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_API_URL + "/v1/admin1_1/WeCareOffDetail",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "Success" && result.status_code === 200) {
          setOfferTitle(result.data.Title);
          setLocation(result.data.Location);
          setCompany(result.data.Company);
          setDiscount(result.data.Offer);
          setPromo(result.data.Promo);
          setTerms(result.data.Terms);
          setStartDate(result.data.StartDate.slice(0, 10));
          setEndDate(result.data.EndData.slice(0, 10));
          setWebImage(result.data.WebImage);
          setMobileImage(result.data.MobImage);
          setCheck(+result.data.IsLive);
          setUrl(result.data.Url);
        }
      })
      .catch((error) => console.log("error", error));
  };

  const updateOffer = () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );

    var formdata = new FormData();
    formdata.append("WeCareOfferUUID", id);
    formdata.append("Title", offerTitle);
    formdata.append("Location", location);
    formdata.append("Company", company);
    formdata.append("Offer", discount);
    formdata.append("Promo", promo);
    formdata.append("StartDate", startDate);
    formdata.append("EndData", endDate);
    formdata.append("Url", url);
    formdata.append("WebImage", typeof webImage === "object" ? webImage : "");
    formdata.append(
      "MobImage",
      typeof mobileImage === "object" ? mobileImage : ""
    );
    formdata.append("Terms", terms || null);
    formdata.append("IsLive", check);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_API_URL + "/v1/admin1_1/WeCareofferUpdate",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "Success" && result.status_code === 200) {
          setModalShow(true);
        } else if (result.status_code === 400 || result.status_code === 500) {
          setInternalErrorShow(true);
        } else if (result.status_code === 300) {
          setErrorShow(true);
        }
      })
      .catch((error) => console.log("error", error));
  };

  return (
    <div className={classes.top_div}>
      {modalShow && (
        <Modal
          heading={"Data inserted successfully"}
          onClick={() => history.push("/BiDashboard/forher-Offers")}
        />
      )}
      {erorrshow && (
        <Modal
          heading={"Required Fields Missing"}
          onClick={() => setErrorShow(false)}
        />
      )}
      {internalErrorShow && (
        <Modal
          heading={"Internal server error"}
          onClick={() => setInternalErrorShow(false)}
        />
      )}

      <div className={classes.container}>
        <div className={classes.form}>
          <div className={classes.form_header_container}>
            <div className={classes.form_header}>Edit Offer</div>
            <Link
              to="/BiDashboard/forher-Offers"
              className={classes.BackButton}
            >
              <BackButton />
            </Link>
          </div>
          <div className={classes.form_input}>
            <div className={classes.input}>
              <EventInputs
                input={"SingleLineInput"}
                label={"Offer Title"}
                placeholder={"Offer title"}
                onChange={(e) => setOfferTitle(e.target.value)}
                value={offerTitle}
              />
              <EventInputs
                input={"SingleLineInput"}
                label={"Location"}
                placeholder={"Location"}
                onChange={(e) => setLocation(e.target.value)}
                value={location}
              />
              <EventInputs
                input={"SingleLineInput"}
                label={"Company"}
                placeholder={"Company"}
                onChange={(e) => setCompany(e.target.value)}
                value={company}
              />

              <EventInputs
                input={"SingleLineInput"}
                label={"Discount"}
                placeholder={"Discount"}
                onChange={(e) => setDiscount(e.target.value)}
                value={discount}
              />

              <EventInputs
                input={"SingleLineInput"}
                label={"Promocode"}
                placeholder={"Promo"}
                onChange={(e) => setPromo(e.target.value)}
                value={promo}
              />

              <EventInputs
                input={"MultiLineInput"}
                label={"Terms & Condition"}
                placeholder={""}
                onChange={(e) => setTerms(e.target.value)}
                value={terms}
              />
            </div>
            <div className={classes.input}>
              <EventInputs
                input={"DateInput"}
                label={"Offer Start Date"}
                placeholder={"Offer Start Date"}
                onChange={(e) => setStartDate(e.target.value)}
                value={startDate}
              />
              <EventInputs
                input={"DateInput"}
                label={"Offer End Date"}
                placeholder={"Offer End Date"}
                onChange={(e) => setEndDate(e.target.value)}
                value={endDate}
              />

              <EventInputs
                input={"SingleLineInput"}
                label={"Redirection Url"}
                placeholder={"Redirection Url"}
                onChange={(e) => setUrl(e.target.value)}
                value={url}
              />
              <div className={classes.mt2}>
                {editableCImage ? (
                  <div>
                    <div className={classes.fieldName}>
                      <label htmlFor="input7">Web Image</label>
                    </div>
                    <div className={classes.field1}>
                      <div className={classes.inputField}>
                        <div className={classes.inputFieldText}>
                          {" "}
                          <img src={webImage} alt="" height={50} width={50} />
                        </div>
                        <div>
                          {" "}
                          <button
                            onClick={() => setEditableCImage(false)}
                            className={classes.input_button}
                          >
                            edit{" "}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <EventInputs
                    input={"ImageInput"}
                    label={"Web Image "}
                    placeholder={"Course Image"}
                    onChange={(e) => setWebImage(e.target.files[0])}
                    type={"file"}
                    accept=".png, .jpg, .jpeg"
                  />
                )}
              </div>
              <div className={classes.mt2}>
                {editableCImage1 ? (
                  <div>
                    <div className={classes.fieldName}>
                      <label htmlFor="input7">Mobile Image</label>
                    </div>
                    <div className={classes.field1}>
                      <div className={classes.inputField}>
                        <div className={classes.inputFieldText}>
                          {" "}
                          <img
                            src={mobileImage}
                            alt=""
                            height={50}
                            width={50}
                          />
                        </div>
                        <div>
                          {" "}
                          <button
                            onClick={() => setEditableCImage1(false)}
                            className={classes.input_button}
                          >
                            edit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <EventInputs
                    input={"ImageInput"}
                    label={"Mobile Image "}
                    placeholder={"Mobile Image"}
                    onChange={(e) => setMobileImage(e.target.files[0])}
                    type={"file"}
                    accept=".png, .jpg, .jpeg"
                  />
                )}
              </div>
            </div>
            <div className={classes.checkbox}>
              <EventInputs
                input={"CheckBox"}
                onChange={() => setCheck(!check === true ? 1 : 0)}
                label={"Want to publish this offer"}
                value={check}
              />
            </div>
          </div>
        </div>
        <button className={classes.Upload_button} onClick={() => updateOffer()}>
          submit
        </button>
      </div>
    </div>
  );
};

export default EditOffer;
