import React, { useState } from "react";
import classes from "./Sidebar.module.css";
import { Link, useLocation } from "react-router-dom";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";
const Sidebar = (props) => {
  const { pathname } = useLocation();
  const [subMenu, setSubMenu] = useState("");

  const showSubMenuFunc = (name) => {
    if (subMenu !== "") {
      setSubMenu("");
    } else {
      setSubMenu(name);
    }
  };

  const AccessLevel = (type) => {
    switch (type) {
      case "SAS":
        return (
          <div className={classes.sidebar_container}>
            <div className={classes.header_container}>
              <div className={classes.navbar}>
                <Link
                  to="/BiDashboard/Dashboard"
                  className={`${classes.link} ${pathname === "/BiDashboard/Dashboard" ||
                    pathname.includes("/Routing") ||
                    pathname.includes("/BiDashboard/AdvertiseNowDetails") ||
                    pathname.includes("/BiDashboard/ContactUsDetails") ||
                    pathname.includes("/BiDashboard/EasyApplyMonthly") ||
                    pathname.includes("/BiDashboard/EasyApplyToday") ||
                    pathname.includes("/BiDashboard/OnlineJobsMonthly") ||
                    pathname.includes("/BiDashboard/RecruiterSignupMonthlty") ||
                    pathname.includes("/BiDashboard/RecruiterSignupDet") ||
                    pathname.includes("/BiDashboard/PaymentSucc") ||
                    pathname.includes("/BiDashboard/OnlineJobs") ||
                    pathname.includes("/BiDashboard/PaymentFailToday") ||
                    pathname.includes("/BiDashboard/PaymentSuccMonthly") ||
                    pathname.includes("/BiDashboard/PaymentFailMonthly")
                    ? classes.active_link
                    : ""
                    }`}
                >
                  Dashboard
                </Link>
                <Link
                  to="/BiDashboard/DataPoints"
                  className={`${classes.link} ${pathname === "/BiDashboard/DataPoints" ||
                    pathname.includes("/Routing") ||
                    pathname.includes("/BiDashboard/SignupLastMonth") ||
                    pathname.includes("/BiDashboard/ResumeLastMonth") ||
                    pathname.includes(
                      "/BiDashboard/EasyApplyDataPointsLastMonth"
                    ) ||
                    pathname.includes("/BiDashboard/RediectionPartner") ||
                    pathname.includes(
                      "/BiDashboard//BiDashboard/ResumeLastMonth"
                    ) ||
                    pathname.includes(
                      "/BiDashboard/ResumeUploadsLastMonthDetail"
                    ) ||
                    pathname.includes("/BiDashboard/CourseDataPoints") ||
                    pathname.includes("/BiDashboard/CourseDetail") ||
                    pathname.includes(
                      "/BiDashboard/RecruiterLoginDataPoints"
                    ) ||
                    pathname.includes("/BiDashboard/RecruiterLogindetail") ||
                    pathname.includes("/BiDashboard/Login") ||
                    pathname.includes("/BiDashboard/Logindetail") ||
                    pathname.includes("/BiDashboard/ArticleData") ||
                    pathname.includes("/BiDashboard/ArticleDetail") ||
                    pathname.includes("/BiDashboard/ImpressionWidget") ||
                    pathname.includes(
                      "/BiDashboard/EasyApplyDataPointsLastMonth"
                    ) ||
                    pathname.includes("/BiDashboard/EasyApplyJobDetail") ||
                    pathname.includes("/BiDashboard/RedirectionDetail") ||
                    pathname.includes("/BiDashboard/RecruiterLoginDetail") ||
                    pathname.includes("/BiDashboard/ImpressionDetail") ||
                    pathname.includes("/BiDashboard/EasyApplyJobs") ||
                    pathname.includes("/BiDashboard/EasyApplyJobsDetail") ||
                    pathname.includes("/BiDashboard/landingpageDetail") ||
                    pathname.includes("/BiDashboard/landingpage") ||
                    pathname.includes("/BiDashboard/FeaturedJobdetail") ||
                    pathname.includes("/BiDashboard/admindatapoints") ||
                    pathname.includes("/BiDashboard/featuredemployersdetail") ||
                    pathname.includes("/BiDashboard/CourseById") ||
                    pathname.includes("/BiDashboard/EasyApplyThroghId") ||
                    pathname.includes("/BiDashboard/RedirectionPartnerDate") ||
                    pathname.includes("/BiDashboard/mentorkart") ||
                    pathname.includes("/BiDashboard/featuredApply")
                    ? classes.active_link
                    : ""
                    }`}
                >
                  Data Points
                </Link>
                <Link
                  to="/BiDashboard/updateJson"
                  className={`${classes.link} ${pathname === "/BiDashboard/updateJson"
                    ? classes.active_link
                    : ""
                    }`}
                >
                  Update Json
                </Link>
                <div>
                  <div
                    onClick={() => showSubMenuFunc("job")}
                    className={`${classes.sub_menu} ${subMenu === "job" ? classes.active_sub_menu : ""
                      }`}
                  >
                    Job{" "}
                    {subMenu === "job" ? (
                      <BiChevronUp className={classes.sub_menu_icon} />
                    ) : (
                      <BiChevronDown className={classes.sub_menu_icon} />
                    )}
                  </div>
                  {subMenu === "job" && (
                    <ul>
                      <li>
                        <Link
                          to="/BiDashboard/FeaturedCompanyList"
                          className={`${classes.link} ${pathname === "/BiDashboard/FeaturedCompanyList" ||
                            pathname.includes(
                              "/BiDashboard/featuredcompanies"
                            ) ||
                            pathname.includes(
                              "/BiDashboard/addfeaturedcompanies"
                            ) ||
                            pathname.includes(
                              "/BiDashboard/updatefeaturedcompanies"
                            )
                            ? classes.active_link
                            : ""
                            }`}
                        >
                          Featured Companies
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/BiDashboard/JobsList"
                          className={`${classes.link} ${pathname === "/BiDashboard/JobsList"
                            ? classes.active_link
                            : ""
                            }`}
                        >
                          Jobs List
                        </Link>
                      </li>
                    </ul>
                  )}
                </div>

                <div>
                  <div
                    onClick={() => showSubMenuFunc("articles")}
                    className={`${classes.sub_menu} ${subMenu === "articles" ? classes.active_sub_menu : ""
                      }`}
                  >
                    Articles{" "}
                    {subMenu === "articles" ? (
                      <BiChevronUp className={classes.sub_menu_icon} />
                    ) : (
                      <BiChevronDown className={classes.sub_menu_icon} />
                    )}
                  </div>
                  {subMenu === "articles" && (
                    <ul>
                      <li>
                        <Link
                          to="/BiDashboard/articles"
                          className={`${classes.link} ${pathname === "/BiDashboard/articles" ||
                            pathname.includes("/BiDashboard/editArticle") ||
                            pathname.includes("/BiDashboard/viewArticle")
                            ? classes.active_link
                            : ""
                            }`}
                        >
                          Articles
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/BiDashboard/GuestArticleList"
                          className={`${classes.link} ${pathname === "/BiDashboard/GuestArticleList" ||
                            pathname.includes("/BiDashboard/AddGuestArticle") ||
                            pathname.includes("/BiDashboard/EditGuestArticle")
                            ? classes.active_link
                            : ""
                            }`}
                        >
                          Guest Article
                        </Link>
                      </li>
                    </ul>
                  )}
                </div>

                <Link
                  to="/BiDashboard/articleManagers"
                  className={`${classes.link} ${pathname === "/BiDashboard/articleManagers"
                    ? classes.active_link
                    : ""
                    }`}
                >
                  Article Managers
                </Link>
                <Link
                  to="/BiDashboard/CustomJobContent"
                  className={`${classes.link} ${(pathname === "/BiDashboard/CustomJobContent" || pathname === "/BiDashboard/AddCustomJobContent" || pathname === "/BiDashboard/EditCustomJobContent/:id")
                    ? classes.active_link
                    : ""
                    }`}
                >
                  Custom Job Content
                </Link>

                <Link
                  to="/BiDashboard/FAQ"
                  className={`${classes.link} ${pathname === "/BiDashboard/FAQ"
                    ? classes.active_link
                    : ""
                    }`}
                >
                  FAQ
                </Link>

                {/* <Link
                  to="/BiDashboard/downloadArticles"
                  className={`${classes.link} ${pathname === "/BiDashboard/downloadArticles"
                    ? classes.active_link
                    : ""
                    }`}
                >
                  Download Articles
                </Link> */}

                <div>
                  <div
                    onClick={() => showSubMenuFunc("movementOfProfessional")}
                    className={`${classes.sub_menu} ${subMenu === "movementOfProfessional"
                      ? classes.active_sub_menu
                      : ""
                      }`}
                  >
                    MOP{" "}
                    {subMenu === "movementOfProfessional" ? (
                      <BiChevronUp className={classes.sub_menu_icon} />
                    ) : (
                      <BiChevronDown className={classes.sub_menu_icon} />
                    )}
                  </div>
                  {subMenu === "movementOfProfessional" && (
                    <ul>
                      <li>
                        <Link
                          to="/BiDashboard/MopJourney"
                          className={`${classes.link} ${pathname === "/BiDashboard/MopJourney"
                            ? classes.active_link
                            : ""
                            }`}
                        >
                          MopJourney Form
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/BiDashboard/MovementList"
                          className={`${classes.link} ${pathname === "/BiDashboard/MovementList" ||
                            pathname.includes("/BiDashboard/AddMovement") ||
                            pathname.includes("/BiDashboard/EditMovement") ||
                            pathname.includes("/BiDashboard/Movementdetail")
                            ? classes.active_link
                            : ""
                            }`}
                        >
                          Movement Of Professional
                        </Link>
                      </li>
                    </ul>
                  )}
                </div>


                <div>
                  <div
                    onClick={() => showSubMenuFunc("Quiz")}
                    className={`${classes.sub_menu} ${subMenu === "Quiz" ? classes.active_sub_menu : ""
                      }`}
                  >
                    Quiz{" "}
                    {subMenu === "Quiz" ? (
                      <BiChevronUp className={classes.sub_menu_icon} />
                    ) : (
                      <BiChevronDown className={classes.sub_menu_icon} />
                    )}
                  </div>
                  {subMenu === "Quiz" && (
                    <ul>
                      <li>
                        <Link
                          to="/BiDashboard/QuizCategoreisList"
                          className={`${classes.link} ${pathname.includes("/BiDashboard/QuizCategoreisList")
                            ? classes.active_link
                            : ""
                            }`}
                        >
                          Categories List
                        </Link>
                      </li>

                      <li>
                        <Link
                          to="/BiDashboard/QuizList"
                          className={`${classes.link} ${pathname.includes("/BiDashboard/QuizList")
                            ? classes.active_link
                            : ""
                            }`}
                        >
                          Quiz List
                        </Link>
                      </li>

                      <li>
                        <Link
                          to="/BiDashboard/QuizQuestionList"
                          className={`${classes.link} ${pathname.includes("/BiDashboard/QuizQuestionList") || pathname.includes("/BiDashboard/AddQuizQuestion")
                            ? classes.active_link
                            : ""
                            }`}
                        >
                          Questions
                        </Link>
                      </li>

                      <li>
                        <Link
                          to="/BiDashboard/QuizPlayers"
                          className={`${classes.link} ${pathname.includes("/BiDashboard/QuizPlayers")
                            ? classes.active_link
                            : ""
                            }`}
                        >
                          Quiz Players
                        </Link>
                      </li>
                    </ul>
                  )}
                </div>


                <div>
                  <div
                    onClick={() => showSubMenuFunc("Global")}
                    className={`${classes.sub_menu} ${subMenu === "Global" ? classes.active_sub_menu : ""
                      }`}
                  >
                    Global{" "}
                    {subMenu === "Global" ? (
                      <BiChevronUp className={classes.sub_menu_icon} />
                    ) : (
                      <BiChevronDown className={classes.sub_menu_icon} />
                    )}
                  </div>
                  {subMenu === "Global" && (
                    <ul>
                      <li>
                        <Link
                          to="/BiDashboard/TestimonialsList"
                          className={`${classes.link} ${pathname.includes("/BiDashboard/TestimonialsList")
                            ? classes.active_link
                            : ""
                            }`}
                        >
                          Testimonials List
                        </Link>
                      </li>

                      <li>
                        <Link
                          to="/BiDashboard/GlobalCompanyLogo"
                          className={`${classes.link} ${pathname.includes("/BiDashboard/GlobalCompanyLogo")
                            ? classes.active_link
                            : ""
                            }`}
                        >
                          Company Logo
                        </Link>
                      </li>

                      <li>
                        <Link
                          to="/BiDashboard/StudentInformation"
                          className={`${classes.link} ${pathname.includes("/BiDashboard/StudentInformation")
                            ? classes.active_link
                            : ""
                            }`}
                        >
                          Students information
                        </Link>
                      </li>

                      <li>
                        <Link
                          to="/BiDashboard/CompanyInformation"
                          className={`${classes.link} ${pathname.includes("/BiDashboard/CompanyInformation")
                            ? classes.active_link
                            : ""
                            }`}
                        >
                          Company information
                        </Link>
                      </li>

                      <li>
                        <Link
                          to="/BiDashboard/AddGlobalJobs"
                          className={`${classes.link} ${pathname.includes("/BiDashboard/AddGlobalJobs")
                            ? classes.active_link
                            : ""
                            }`}
                        >
                          Global Jobs
                        </Link>
                      </li>



                    </ul>
                  )}
                </div>

                <div>
                  <div
                    onClick={() => showSubMenuFunc("corporateCare")}
                    className={`${classes.sub_menu} ${subMenu === "corporateCare" ? classes.active_sub_menu : ""
                      }`}
                  >
                    Corporate Care{" "}
                    {subMenu === "corporateCare" ? (
                      <BiChevronUp className={classes.sub_menu_icon} />
                    ) : (
                      <BiChevronDown className={classes.sub_menu_icon} />
                    )}
                  </div>
                  {subMenu === "corporateCare" && (
                    <ul>
                      <li>
                        <Link
                          to="/BiDashboard/corporates-care"
                          className={`${classes.link} ${pathname === "/BiDashboard/corporates-care" ||
                            pathname.includes(
                              "/BiDashboard/Addcorporates-care"
                            ) ||
                            pathname.includes("/BiDashboard/NewAddCorporate") ||
                            pathname.includes(
                              "/BiDashboard/Editcorporates-care"
                            ) ||
                            pathname.includes("/BiDashboard/ViewDetail")
                            ? classes.active_link
                            : ""
                            }`}
                        >
                          Corporates Care
                        </Link>
                      </li>

                      <li>
                        <Link
                          to="/BiDashboard/WeCareMopFormList"
                          className={`${classes.link} ${pathname === "/BiDashboard/WeCareMopFormList" ||
                            pathname.includes(
                              "/BiDashboard/WeCareMopFormDetail"
                            )
                            ? classes.active_link
                            : ""
                            }`}
                        >
                          Corporate Care List
                        </Link>
                      </li>
                    </ul>
                  )}
                </div>
                <div>
                  <div
                    onClick={() => showSubMenuFunc("Hr Conclave")}
                    className={`${classes.sub_menu} ${subMenu === "Hr Conclave" ? classes.active_sub_menu : ""
                      }`}
                  >
                    Hr Conclave{" "}
                    {subMenu === "Hr Conclave" ? (
                      <BiChevronUp className={classes.sub_menu_icon} />
                    ) : (
                      <BiChevronDown className={classes.sub_menu_icon} />
                    )}
                  </div>
                  {subMenu === "Hr Conclave" && (
                    <ul>
                      <li>
                        <Link
                          to="/BiDashboard/HrConclave"
                          className={`${classes.link} ${pathname === "/BiDashboard/HrConclave" ||
                            pathname.includes(
                              "/BiDashboard/HrConclave"
                            )
                            ? classes.active_link
                            : ""
                            }`}
                        >

                          Hr Conclave list
                        </Link>
                      </li>

                      <li>
                        <Link
                          to="/BiDashboard/HrVideos"
                          className={`${classes.link} ${pathname === "/BiDashboard/HrVideos" ||
                            pathname.includes(
                              "/BiDashboard/HrVideos"
                            )
                            ? classes.active_link
                            : ""
                            }`}
                        >

                          Hr Conclave Video
                        </Link>
                      </li>

                      <li>
                        <Link
                          to="/BiDashboard/HrAbout"
                          className={`${classes.link} ${pathname === "/BiDashboard/HrAbout" ||
                            pathname.includes(
                              "/BiDashboard/HrAbout"
                            )
                            ? classes.active_link
                            : ""
                            }`}
                        >

                          Hr About
                        </Link>
                      </li>
                    </ul>
                  )}
                </div>


                <div>
                  <div
                    onClick={() => showSubMenuFunc("SHERO")}
                    className={`${classes.sub_menu} ${subMenu === "SHERO" ? classes.active_sub_menu : ""
                      }`}
                  >
                    SHERO{" "}
                    {subMenu === "SHERO" ? (
                      <BiChevronUp className={classes.sub_menu_icon} />
                    ) : (
                      <BiChevronDown className={classes.sub_menu_icon} />
                    )}
                  </div>
                  {subMenu === "SHERO" && (
                    <ul>
                      <li>
                        <Link
                          to="/BiDashboard/SheroVideo"
                          className={`${classes.link} ${pathname === "/BiDashboard/SheroVideo" ||
                            pathname.includes(
                              "/BiDashboard/SheroVideo"
                            )
                            ? classes.active_link
                            : ""
                            }`}
                        >

                          Video list
                        </Link>
                      </li>

                      <li>
                        <Link
                          to="/BiDashboard/SheroWebinarVideos"
                          className={`${classes.link} ${pathname === "/BiDashboard/SheroWebinarVideos" ||
                            pathname.includes(
                              "/BiDashboard/SheroWebinarVideos"
                            )
                            ? classes.active_link
                            : ""
                            }`}
                        >

                          Shero Webinar Videos
                        </Link>
                      </li>

                      <li>
                        <Link
                          to="/BiDashboard/SheroEvent"
                          className={`${classes.link} ${pathname === "/BiDashboard/SheroEvent" ||
                            pathname.includes(
                              "/BiDashboard/SheroEvent"
                            )
                            ? classes.active_link
                            : ""
                            }`}
                        >

                          Shero Event
                        </Link>
                      </li>


                      <li>
                        <Link
                          to="/BiDashboard/SheroCompanyLogo"
                          className={`${classes.link} ${pathname === "/BiDashboard/SheroCompanyLogo" ||
                            pathname.includes(
                              "/BiDashboard/SheroCompanyLogo"
                            )
                            ? classes.active_link
                            : ""
                            }`}
                        >
                          Shero Company Logo

                        </Link>
                      </li>

                    </ul>
                  )}
                </div>
                <div>
                  <div
                    onClick={() => showSubMenuFunc("event")}
                    className={`${classes.sub_menu} ${subMenu === "event" ? classes.active_sub_menu : ""
                      }`}
                  >
                    Event{" "}
                    {subMenu === "event" ? (
                      <BiChevronUp className={classes.sub_menu_icon} />
                    ) : (
                      <BiChevronDown className={classes.sub_menu_icon} />
                    )}
                  </div>
                  {subMenu === "event" && (
                    <ul>
                      <li>
                        <Link
                          to="/BiDashboard/eventList"
                          className={`${classes.link} ${pathname.includes("/BiDashboard/eventList")
                            ? classes.active_link
                            : ""
                            }`}
                        >
                          Event List
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/BiDashboard/events"
                          className={`${classes.link} ${pathname.includes("/BiDashboard/events") ||
                            pathname.includes("/BiDashboard/addevents") ||
                            pathname.includes("/BiDashboard/eventdetail") ||
                            pathname.includes("/BiDashboard/updateevent")
                            ? classes.active_link
                            : ""
                            }`}
                        >
                          Events
                        </Link>
                      </li>
                    </ul>
                  )}
                </div>


                <div>
                  <div
                    onClick={() => showSubMenuFunc("Ascent To Wealth")}
                    className={`${classes.sub_menu} ${subMenu === "Webinar." ? classes.active_sub_menu : ""
                      }`}
                  >
                    Ascent To Wealth{" "}
                    {subMenu === "Ascent To Wealth" ? (
                      <BiChevronUp className={classes.sub_menu_icon} />
                    ) : (
                      <BiChevronDown className={classes.sub_menu_icon} />
                    )}
                  </div>
                  {subMenu === "Ascent To Wealth" && (
                    <ul>
                      <li>
                        <Link
                          to="/BiDashboard/WebinarVideos"
                          className={`${classes.link} ${pathname === "/BiDashboard/WebinarVideos"
                            ? classes.active_link
                            : ""
                            }`}
                        >
                          Webinar Videos
                        </Link>
                      </li>
                      {/* <li>
                        <Link
                          to="/BiDashboard/FAQ"
                          className={`${classes.link} ${pathname === "/BiDashboard/FAQ"
                            ? classes.active_link
                            : ""
                            }`}
                        >
                          FAQ
                        </Link>
                      </li> */}
                      <li>
                        <Link
                          to="/BiDashboard/YoutubeVideos"
                          className={`${classes.link} ${pathname === "/BiDashboard/YoutubeVideos"
                            ? classes.active_link
                            : ""
                            }`}
                        >
                          Youtube Videos
                        </Link>


                      </li>

                      <li>
                        <Link
                          to="/BiDashboard/WealthCourse"
                          className={`${classes.link} ${pathname === "/BiDashboard/WealthCourse"
                            ? classes.active_link
                            : ""
                            }`}
                        >
                          Wealth Course
                        </Link>


                      </li>
                      <li>
                        <Link
                          to="/BiDashboard/WealthEvent"
                          className={`${classes.link} ${pathname === "/BiDashboard/WealthEvent"
                            ? classes.active_link
                            : ""
                            }`}
                        >
                          Wealth Event
                        </Link>


                      </li>
                      <li>
                        <Link
                          to="/BiDashboard/CompanyLogo"
                          className={`${classes.link} ${pathname === "/BiDashboard/CompanyLogo"
                            ? classes.active_link
                            : ""
                            }`}
                        >
                          Wealth Company Logo
                        </Link>


                      </li>

                      <li>
                        <Link
                          to="/BiDashboard/WealthArticle"
                          className={`${classes.link} ${pathname === "/BiDashboard/WealthArticle"
                            ? classes.active_link
                            : ""
                            }`}
                        >
                          Wealth Article
                        </Link>


                      </li>

                      <li>
                        <Link
                          to="/BiDashboard/WebinarForm"
                          className={`${classes.link} ${pathname === "/BiDashboard/WebinarForm"
                            ? classes.active_link
                            : ""
                            }`}
                        >
                          Webinar Form List
                        </Link>


                      </li>
                      <li>
                        <Link
                          to="/BiDashboard/EventForm"
                          className={`${classes.link} ${pathname === "/BiDashboard/EventForm"
                            ? classes.active_link
                            : ""
                            }`}
                        >
                          Event Form List
                        </Link>


                      </li>
                    </ul>
                  )}
                </div>



                <Link
                  to="/BiDashboard/forumlist"
                  className={`${classes.link} ${pathname === "/BiDashboard/forumlist"
                    ? classes.active_link
                    : ""
                    }`}
                >
                  Forum
                </Link>
                <Link
                  to="/BiDashboard/ActOfCompassionList"
                  className={`${classes.link} ${pathname === "/BiDashboard/ActOfCompassionList"
                    ? classes.active_link
                    : ""
                    }`}
                >
                  Act of Compassion
                </Link>

                <Link
                  to="/BiDashboard/Mailer"
                  className={`${classes.link} ${pathname === "/BiDashboard/Mailer"
                    ? classes.active_link
                    : ""
                    }`}
                >
                  Mailer
                </Link>

                <Link
                  to="/BiDashboard/Eventregistered"
                  className={`${classes.link} ${pathname === "/BiDashboard/Eventregistered"
                    ? classes.active_link
                    : ""
                    }`}
                >
                  Event registered
                </Link>



                <Link
                  to="/BiDashboard/HomeYouTubeVideos"
                  className={`${classes.link} ${pathname === "/BiDashboard/HomeYouTubeVideos"
                    ? classes.active_link
                    : ""
                    }`}
                >
                  YouTube Videos
                </Link>



                {/* <Link
                  to="/BiDashboard/webstorylist"
                  className={`${classes.link} ${pathname === "/BiDashboard/webstorylist" ||
                    pathname.includes("/BiDashboard/AddWebstory") ||
                    pathname.includes("/BiDashboard/AddWebstorySummary") ||
                    pathname.includes("/BiDashboard/Webstorydetail") ||
                    pathname.includes("/BiDashboard/Webstoryupdate") ||
                    pathname.includes("/BiDashboard/updatewebstorydetail")
                    ? classes.active_link
                    : ""
                    }`}
                >
                  Webstory
                </Link> */}

                <div>
                  <div
                    onClick={() => showSubMenuFunc("webstory")}
                    className={`${classes.sub_menu} ${subMenu === "webstory" ? classes.active_sub_menu : ""
                      }`}
                  >
                    Webstory{" "}
                    {subMenu === "webstory" ? (
                      <BiChevronUp className={classes.sub_menu_icon} />
                    ) : (
                      <BiChevronDown className={classes.sub_menu_icon} />
                    )}
                  </div>
                  {subMenu === "webstory" && (
                    <ul>
                      <li>
                        <Link
                          to="/BiDashboard/webstorylist"
                          className={`${classes.link} ${pathname === "/BiDashboard/webstorylist" ||
                            pathname.includes("/BiDashboard/AddWebstory") ||
                            pathname.includes("/BiDashboard/AddWebstorySummary") ||
                            pathname.includes("/BiDashboard/Webstorydetail") ||
                            pathname.includes("/BiDashboard/Webstoryupdate") ||
                            pathname.includes("/BiDashboard/updatewebstorydetail")
                            ? classes.active_link
                            : ""
                            }`}
                        >
                          Webstory
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/BiDashboard/PageWiseWebstory"
                          className={`${classes.link} ${pathname === "/BiDashboard/PageWiseWebstory" ||
                            pathname.includes("/BiDashboard/AddPageWiseWebstory") ||
                            pathname.includes("/BiDashboard/EditPageWiseWebstory")
                            ? classes.active_link
                            : ""
                            }`}
                        >
                          Page Wise
                        </Link>
                      </li>
                    </ul>
                  )}
                </div>

                <div>
                  <div
                    onClick={() => showSubMenuFunc("googleIndexing")}
                    className={`${classes.sub_menu} ${subMenu === "googleIndexing"
                      ? classes.active_sub_menu
                      : ""
                      }`}
                  >
                    Google Indexing{" "}
                    {subMenu === "googleIndexing" ? (
                      <BiChevronUp className={classes.sub_menu_icon} />
                    ) : (
                      <BiChevronDown className={classes.sub_menu_icon} />
                    )}
                  </div>
                  {subMenu === "googleIndexing" && (
                    <ul>
                      <li>
                        <Link
                          to="/BiDashboard/inspectionUrl-list"
                          className={`${classes.link} ${pathname === "/BiDashboard/inspectionUrl-list"
                            ? classes.active_link
                            : ""
                            }`}
                        >
                          Inspection Url List
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/BiDashboard/deleteUrl-list"
                          className={`${classes.link} ${pathname === "/BiDashboard/deleteUrl-list"
                            ? classes.active_link
                            : ""
                            }`}
                        >
                          Delete Url List
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/BiDashboard/checkUrl-list"
                          className={`${classes.link} ${pathname === "/BiDashboard/checkUrl-list"
                            ? classes.active_link
                            : ""
                            }`}
                        >
                          Update Url List
                        </Link>
                      </li>
                    </ul>
                  )}
                </div>

                <div>
                  <div
                    onClick={() => showSubMenuFunc("course")}
                    className={`${classes.sub_menu} ${subMenu === "course" ? classes.active_sub_menu : ""
                      }`}
                  >
                    Course{" "}
                    {subMenu === "course" ? (
                      <BiChevronUp className={classes.sub_menu_icon} />
                    ) : (
                      <BiChevronDown className={classes.sub_menu_icon} />
                    )}
                  </div>
                  {subMenu === "course" && (
                    <ul>
                      <li>
                        <Link
                          to="/BiDashboard/courses"
                          className={`${classes.link} ${pathname === "/BiDashboard/courses" ||
                            pathname.includes("/BiDashboard/coursedetail") ||
                            pathname.includes("/BiDashboard/addcourses") ||
                            pathname.includes("/BiDashboard/updatecourses")
                            ? classes.active_link
                            : ""
                            }`}
                        >
                          Courses
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/BiDashboard/courses-list"
                          className={`${classes.link} ${pathname === "/BiDashboard/courses-list"
                            ? classes.active_link
                            : ""
                            }`}
                        >
                          Courses List
                        </Link>
                      </li>
                    </ul>
                  )}
                </div>

                <div>
                  <div
                    onClick={() => showSubMenuFunc("hrProfessional")}
                    className={`${classes.sub_menu} ${subMenu === "hrProfessional"
                      ? classes.active_sub_menu
                      : ""
                      }`}
                  >
                    HR Professional{" "}
                    {subMenu === "hrProfessional" ? (
                      <BiChevronUp className={classes.sub_menu_icon} />
                    ) : (
                      <BiChevronDown className={classes.sub_menu_icon} />
                    )}
                  </div>
                  {subMenu === "hrProfessional" && (
                    <ul>
                      <li>
                        <Link
                          to="/BiDashboard/ShareHrprofilelist"
                          className={`${classes.link} ${pathname === "/BiDashboard/ShareHrprofilelist"
                            ? classes.active_link
                            : ""
                            }`}
                        >
                          Share Your Profile
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/BiDashboard/hrprofessionnal"
                          className={`${classes.link} ${pathname === "/BiDashboard/hrprofessionnal" ||
                            pathname.includes(
                              "/BiDashboard/hrProfessionalDetails"
                            ) ||
                            pathname.includes(
                              "/BiDashboard/addHrProfessional"
                            ) ||
                            pathname.includes("/BiDashboard/updateHr")
                            ? classes.active_link
                            : ""
                            }`}
                        >
                          Hr Professional
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/BiDashboard/PageWiseFeaturedHR"
                          className={`${classes.link} ${pathname === "/BiDashboard/PageWiseFeaturedHR" ||
                            pathname.includes(
                              "/BiDashboard/AddPageWiseFeaturedHR"
                            )
                            ? classes.active_link
                            : ""
                            }`}
                        >
                          Page Wise HR
                        </Link>
                      </li>
                    </ul>
                  )}
                </div>

                <div>
                  <div
                    onClick={() => showSubMenuFunc("carousel")}
                    className={`${classes.sub_menu} ${subMenu === "carousel" ? classes.active_sub_menu : ""
                      }`}
                  >
                    Carousel{" "}
                    {subMenu === "carousel" ? (
                      <BiChevronUp className={classes.sub_menu_icon} />
                    ) : (
                      <BiChevronDown className={classes.sub_menu_icon} />
                    )}
                  </div>
                  {subMenu === "carousel" && (
                    <ul>
                      <li>
                        <Link
                          to="/BiDashboard/BannerImageList"
                          className={`${classes.link} ${pathname === "/BiDashboard/BannerImageList" ||
                            pathname.includes("/BiDashboard/Insertbanner") ||
                            pathname.includes("/BiDashboard/UpdateImage")
                            ? classes.active_link
                            : ""
                            }`}
                        >
                          BannerCarousel
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/BiDashboard/LottieJsonList"
                          className={`${classes.link} ${pathname === "/BiDashboard/LottieJsonList" ||
                            pathname.includes(
                              "/BiDashboard/InsertLottieJson"
                            ) ||
                            pathname.includes("/BiDashboard/UpdateLottieJson")
                            ? classes.active_link
                            : ""
                            }`}
                        >
                          Lottie Files
                        </Link>
                      </li>
                    </ul>
                  )}
                </div>

                <Link
                  to="/BiDashboard/PartnerCompanies"
                  className={`${classes.link} ${pathname === "/BiDashboard/PartnerCompanies"
                    ? classes.active_link
                    : ""
                    }`}
                >
                  Partner Companies
                </Link>

                <Link
                  to="/BiDashboard/eventsregisterlist"
                  className={`${classes.link} ${pathname === "/BiDashboard/eventsregisterlist" ||
                    pathname.includes("/BiDashboard/EventRegisterDetail")
                    ? classes.active_link
                    : ""
                    }`}
                >
                  Event Register
                </Link>
                <Link
                  to="/BiDashboard/CandidateResumeList"
                  className={`${classes.link} ${pathname === "/BiDashboard/CandidateResumeList"
                    ? classes.active_link
                    : ""
                    }`}
                >
                  Candidates Resume
                </Link>

                <div>
                  <div
                    onClick={() => showSubMenuFunc("recruiter")}
                    className={`${classes.sub_menu} ${subMenu === "recruiter" ? classes.active_sub_menu : ""
                      }`}
                  >
                    Recruiter{" "}
                    {subMenu === "recruiter" ? (
                      <BiChevronUp className={classes.sub_menu_icon} />
                    ) : (
                      <BiChevronDown className={classes.sub_menu_icon} />
                    )}
                  </div>
                  {subMenu === "recruiter" && (
                    <ul>
                      <li>
                        <Link
                          to="/BiDashboard/recruiterSignUp"
                          className={`${classes.link} ${pathname === "/BiDashboard/recruiterSignUp"
                            ? classes.active_link
                            : ""
                            }`}
                        >
                          Recruiter Sign Up
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/BiDashboard/PostAJobs"
                          className={`${classes.link} ${pathname === "/BiDashboard/PostAJobs"
                            ? classes.active_link
                            : ""
                            }`}
                        >
                          PostAJobs Data
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/BiDashboard/postAJob"
                          className={`${classes.link} ${pathname === "/BiDashboard/postAJob"
                            ? classes.active_link
                            : ""
                            }`}
                        >
                          Post A Job
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/BiDashboard/orders"
                          className={`${classes.link} ${pathname === "/BiDashboard/orders"
                            ? classes.active_link
                            : ""
                            }`}
                        >
                          Orders
                        </Link>
                      </li>
                    </ul>
                  )}
                </div>

                <div>
                  <div
                    onClick={() => showSubMenuFunc("signUp")}
                    className={`${classes.sub_menu} ${subMenu === "signUp" ? classes.active_sub_menu : ""
                      }`}
                  >
                    Sign Up{" "}
                    {subMenu === "signUp" ? (
                      <BiChevronUp className={classes.sub_menu_icon} />
                    ) : (
                      <BiChevronDown className={classes.sub_menu_icon} />
                    )}
                  </div>
                  {subMenu === "signUp" && (
                    <ul>
                      <li>
                        <Link
                          to="/BiDashboard/loginData"
                          className={`${classes.link} ${pathname === "/BiDashboard/loginData"
                            ? classes.active_link
                            : ""
                            }`}
                        >
                          User's Login Data
                        </Link>
                      </li>
                      {/* <li>
                        <Link
                          to="/BiDashboard/PostAJobs"
                          className={`${classes.link} ${
                            pathname === "/BiDashboard/PostAJobs"
                              ? classes.active_link
                              : ""
                          }`}
                        >
                          PostAJobs Data
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/BiDashboard/postAJob"
                          className={`${classes.link} ${
                            pathname === "/BiDashboard/postAJob"
                              ? classes.active_link
                              : ""
                          }`}
                        >
                          Post A Job
                        </Link>
                      </li> */}
                    </ul>
                  )}
                </div>

                <div>
                  <div
                    onClick={() => showSubMenuFunc("contact")}
                    className={`${classes.sub_menu} ${subMenu === "contact" ? classes.active_sub_menu : ""
                      }`}
                  >
                    Contact
                    {subMenu === "contact" ? (
                      <BiChevronUp className={classes.sub_menu_icon} />
                    ) : (
                      <BiChevronDown className={classes.sub_menu_icon} />
                    )}
                  </div>
                  {subMenu === "contact" && (
                    <ul>
                      <li>
                        <Link
                          to="/BiDashboard/ContactUsDetails"
                          className={`${classes.link} ${pathname === "/BiDashboard/ContactUsDetails"
                            ? classes.active_link
                            : ""
                            }`}
                        >
                          ContactUs Details
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/BiDashboard/advertisewithus"
                          className={`${classes.link} ${pathname === "/BiDashboard/advertisewithus"
                            ? classes.active_link
                            : ""
                            }`}
                        >
                          Advertisewithus
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/BiDashboard/report-a-bug"
                          className={`${classes.link} ${pathname === "/BiDashboard/report-a-bug"
                            ? classes.active_link
                            : ""
                            }`}
                        >
                          Report a bug
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/BiDashboard/write-for-us-list"
                          className={`${classes.link} ${pathname === "/BiDashboard/write-for-us-list"
                            ? classes.active_link
                            : ""
                            }`}
                        >
                          Write for us list
                        </Link>
                      </li>
                    </ul>
                  )}
                </div>

                <Link
                  to="/BiDashboard/coporate-care-requests"
                  className={`${classes.link} ${pathname === "/BiDashboard/coporate-care-requests"
                    ? classes.active_link
                    : ""
                    }`}
                >
                  We Care Request list
                </Link>

                <div>
                  <div
                    onClick={() => showSubMenuFunc("forHer")}
                    className={`${classes.sub_menu} ${subMenu === "forHer" ? classes.active_sub_menu : ""
                      }`}
                  >
                    For Her
                    {subMenu === "forHer" ? (
                      <BiChevronUp className={classes.sub_menu_icon} />
                    ) : (
                      <BiChevronDown className={classes.sub_menu_icon} />
                    )}
                  </div>
                  {subMenu === "forHer" && (
                    <ul>
                      <li>
                        <Link
                          to="/BiDashboard/forher-post-by-id"
                          className={`${classes.link} ${pathname === "/BiDashboard/forher-post-by-id"
                            ? classes.active_link
                            : ""
                            }`}
                        >
                          Forher posts by Id
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/BiDashboard/forher-post-list"
                          className={`${classes.link} ${pathname === "/BiDashboard/forher-post-list"
                            ? classes.active_link
                            : ""
                            }`}
                        >
                          Forher posts list
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/BiDashboard/forher-Offers"
                          className={`${classes.link} ${pathname === "/BiDashboard/forher-Offers"
                            ? classes.active_link
                            : ""
                            }`}
                        >
                          Forher Offers
                        </Link>
                      </li>
                    </ul>
                  )}
                </div>
                <div>
                  <div
                    onClick={() => showSubMenuFunc("podcasts")}
                    className={`${classes.sub_menu} ${subMenu === "podcasts" ? classes.active_sub_menu : ""
                      }`}
                  >
                    Podcasts{" "}
                    {subMenu === "podcasts" ? (
                      <BiChevronUp className={classes.sub_menu_icon} />
                    ) : (
                      <BiChevronDown className={classes.sub_menu_icon} />
                    )}
                  </div>
                  {subMenu === "podcasts" && (
                    <ul>
                      <li>
                        <Link
                          to="/BiDashboard/podcasts"
                          className={`${classes.link} ${pathname === "/BiDashboard/podcasts"
                            ? classes.active_link
                            : ""
                            }`}
                        >
                          Podcast Audios
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/BiDashboard/podcast-videos"
                          className={`${classes.link} ${pathname === "/BiDashboard/podcast-ideos"
                            ? classes.active_link
                            : ""
                            }`}
                        >
                          Podcast Videos
                        </Link>
                      </li>
                    </ul>
                  )}
                </div>

                {/* <Link
                  to="/BiDashboard/podcasts"
                  className={`${classes.link} ${pathname === "/BiDashboard/podcasts"
                    ? classes.active_link
                    : ""
                    }`}
                >
                  Podcasts
                </Link> */}

                <div>
                  <div
                    onClick={() => showSubMenuFunc("gmi")}
                    className={`${classes.sub_menu} ${subMenu === "gmi" ? classes.active_sub_menu : ""
                      }`}
                  >
                    Great Manager Institute
                    {subMenu === "gmi" ? (
                      <BiChevronUp className={classes.sub_menu_icon} />
                    ) : (
                      <BiChevronDown className={classes.sub_menu_icon} />
                    )}
                  </div>
                  {subMenu === "gmi" && (
                    <ul>
                      <li>
                        <Link
                          to="/BiDashboard/greatManagerInstitute"
                          className={`${classes.link} ${pathname === "/BiDashboard/greatManagerInstitute"
                            ? classes.active_link
                            : ""
                            }`}
                        >
                          Top 100 Managers
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/BiDashboard/leadershipFactory"
                          className={`${classes.link} ${pathname === "/BiDashboard/leadershipFactory"
                            ? classes.active_link
                            : ""
                            }`}
                        >
                          Leadership Factory
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/BiDashboard/certificationOfWinners"
                          className={`${classes.link} ${pathname ===
                            "/BiDashboard/certificationOfWinners" ||
                            pathname ===
                            "/BiDashboard/addCertificationOfWinners" ||
                            pathname.includes(
                              "/BiDashboard/editCertificationOfWinners"
                            )
                            ? classes.active_link
                            : ""
                            }`}
                        >
                          Certification of Winners
                        </Link>
                      </li>
                    </ul>
                  )}
                </div>

                {/* <Link
                  to="/BiDashboard/nominateYourManager"
                  className={`${classes.link} ${
                    pathname === "/BiDashboard/nominateYourManager"
                      ? classes.active_link
                      : ""
                  }`}
                >
                  Nominate Your Manager
                </Link> */}

                <Link
                  to="/BiDashboard/leadersOfChange"
                  className={`${classes.link} ${pathname === "/BiDashboard/leadersOfChange" ||
                    pathname === "/BiDashboard/addLeadersOfChange" ||
                    pathname.includes("/BiDashboard/editLeadersOfChange")
                    ? classes.active_link
                    : ""
                    }`}
                >
                  Leaders of Change
                </Link>

                <div>
                  <div
                    onClick={() => showSubMenuFunc("other")}
                    className={`${classes.sub_menu} ${subMenu === "other" ? classes.active_sub_menu : ""
                      }`}
                  >
                    Other{" "}
                    {subMenu === "other" ? (
                      <BiChevronUp className={classes.sub_menu_icon} />
                    ) : (
                      <BiChevronDown className={classes.sub_menu_icon} />
                    )}
                  </div>
                  {subMenu === "other" && (
                    <ul>
                      <li>
                        <Link
                          to="/BiDashboard/TOIWidgetList"
                          className={`${classes.link} ${pathname === "/BiDashboard/TOIWidgetList" ||
                            pathname.includes("/BiDashboard/TOIWidget") ||
                            pathname.includes("/BiDashboard/UpdateTOIWidget")
                            ? classes.active_link
                            : ""
                            }`}
                        >
                          TOIWidget
                        </Link>
                        {/* <Link to="/BiDashboard/PivotTable" className={`${classes.link} ${pathname === "/BiDashboard/PivotTable" ? classes.active_link : ""}`}>Partners Data Admin Report</Link>*/}
                        {/* <Link to="/BiDashboard/articles" className={`${classes.link} ${pathname === "/BiDashboard/articles" || pathname.includes("/BiDashboard/NewArtical") ? classes.active_link : ""}`}>Articles</Link>  */}
                        {/* <Link to="/BiDashboard/TOIWidgetList" className={`${classes.link} ${pathname === "/BiDashboard/TOIWidgetList" || pathname.includes("/BiDashboard/TOIWidget") || pathname.includes("/BiDashboard/UpdateTOIWidget") ? classes.active_link : ""}`}>TOIWidget</Link> */}
                      </li>
                      <li>
                        <Link
                          to="/BiDashboard/youtube-video"
                          className={`${classes.link} ${pathname === "/BiDashboard/youtube-video"
                            ? classes.active_link
                            : ""
                            }`}
                        >
                          Youtube Video
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/BiDashboard/PollsList"
                          className={`${classes.link} ${pathname === "/BiDashboard/PollsList" ||
                            pathname.includes("/BiDashboard/CreatePoll") ||
                            pathname.includes("/BiDashboard/UpdatePolls")
                            ? classes.active_link
                            : ""
                            }`}
                        >
                          PostAPoll
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/BiDashboard/InterstitialAd"
                          className={`${classes.link} ${pathname === "/BiDashboard/InterstitialAd"
                            ? classes.active_link
                            : ""
                            }`}
                        >
                          Interstitial Ad
                        </Link>
                        {/* <Link to="/BiDashboard/articles" className={`${classes.link} ${pathname === "/BiDashboard/articles" || pathname.includes("/BiDashboard/NewArtical") ? classes.active_link : ""}`}>Articles</Link>  */}
                        {/* <NavLink to="InterstitialAds" activeClassName={classes.active_link} className={classes.link}>Interstitial Ads</NavLink> */}
                      </li>
                      <li>
                        <Link
                          to="/BiDashboard/dynamicwidget"
                          className={`${classes.link} ${pathname === "/BiDashboard/dynamicwidget" ||
                            pathname.includes("/BiDashboard/EditWidget") ||
                            pathname.includes("/BiDashboard/CreateWidget")
                            ? classes.active_link
                            : ""
                            }`}
                        >
                          TimesWidget
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/BiDashboard/GetListofUserspostingJob"
                          className={`${classes.link} ${pathname === "/BiDashboard/GetListofUserspostingJob"
                            ? classes.active_link
                            : ""
                            }`}
                        >
                          User Posting Jobs
                        </Link>
                      </li>
                    </ul>
                  )}
                </div>
              </div>
            </div>
          </div>
        );

      case "TIMES":
        return (
          <div className={classes.sidebar_container}>
            <div className={classes.header_container}>
              <div className={classes.navbar}>
                <Link
                  to="/BiDashboard/Dashboard"
                  className={`${classes.link} ${pathname === "/BiDashboard/Dashboard" ||
                    pathname.includes("/Routing") ||
                    pathname.includes("/BiDashboard/AdvertiseNowDetails") ||
                    // pathname.includes("/BiDashboard/ContactUsDetails") ||
                    pathname.includes("/BiDashboard/EasyApplyMonthly") ||
                    pathname.includes("/BiDashboard/EasyApplyToday") ||
                    pathname.includes("/BiDashboard/OnlineJobsMonthly") ||
                    pathname.includes("/BiDashboard/RecruiterSignupMonthlty") ||
                    pathname.includes("/BiDashboard/RecruiterSignupDet") ||
                    pathname.includes("/BiDashboard/PaymentSucc") ||
                    pathname.includes("/BiDashboard/OnlineJobs") ||
                    pathname.includes("/BiDashboard/PaymentFailToday") ||
                    pathname.includes("/BiDashboard/PaymentSuccMonthly") ||
                    pathname.includes("/BiDashboard/PaymentFailMonthly")
                    ? classes.active_link
                    : ""
                    }`}
                >
                  Dashboard
                </Link>


                <Link
                  to="/BiDashboard/webstorylist"
                  className={`${classes.link} ${pathname === "/BiDashboard/webstorylist" ||
                    pathname.includes("/BiDashboard/AddWebstory") ||
                    pathname.includes("/BiDashboard/AddWebstorySummary") ||
                    pathname.includes("/BiDashboard/Webstorydetail") ||
                    pathname.includes("/BiDashboard/Webstoryupdate") ||
                    pathname.includes("/BiDashboard/updatewebstorydetail")
                    ? classes.active_link
                    : ""
                    }`}
                >
                  Webstory
                </Link>

                <Link
                  to="/BiDashboard/DataPoints"
                  className={`${classes.link} ${pathname === "/BiDashboard/DataPoints" ||
                    pathname.includes("/Routing") ||
                    pathname.includes("/BiDashboard/SignupLastMonth") ||
                    pathname.includes("/BiDashboard/ResumeLastMonth") ||
                    pathname.includes(
                      "/BiDashboard/EasyApplyDataPointsLastMonth"
                    ) ||
                    pathname.includes("/BiDashboard/RediectionPartner") ||
                    pathname.includes(
                      "/BiDashboard//BiDashboard/ResumeLastMonth"
                    ) ||
                    pathname.includes(
                      "/BiDashboard/ResumeUploadsLastMonthDetail"
                    ) ||
                    pathname.includes("/BiDashboard/CourseDataPoints") ||
                    pathname.includes("/BiDashboard/CourseDetail") ||
                    pathname.includes(
                      "/BiDashboard/RecruiterLoginDataPoints"
                    ) ||
                    pathname.includes("/BiDashboard/RecruiterLogindetail") ||
                    pathname.includes("/BiDashboard/Login") ||
                    pathname.includes("/BiDashboard/Logindetail") ||
                    pathname.includes("/BiDashboard/ArticleData") ||
                    pathname.includes("/BiDashboard/ArticleDetail") ||
                    pathname.includes("/BiDashboard/ImpressionWidget") ||
                    pathname.includes(
                      "/BiDashboard/EasyApplyDataPointsLastMonth"
                    ) ||
                    pathname.includes("/BiDashboard/EasyApplyJobDetail") ||
                    pathname.includes("/BiDashboard/RedirectionDetail") ||
                    pathname.includes("/BiDashboard/RecruiterLoginDetail") ||
                    pathname.includes("/BiDashboard/ImpressionDetail") ||
                    pathname.includes("/BiDashboard/EasyApplyJobs") ||
                    pathname.includes("/BiDashboard/EasyApplyJobsDetail") ||
                    pathname.includes("/BiDashboard/landingpageDetail") ||
                    pathname.includes("/BiDashboard/landingpage") ||
                    pathname.includes("/BiDashboard/FeaturedJobdetail") ||
                    pathname.includes("/BiDashboard/admindatapoints") ||
                    pathname.includes("/BiDashboard/featuredemployersdetail") ||
                    pathname.includes("/BiDashboard/CourseById") ||
                    pathname.includes("/BiDashboard/EasyApplyThroghId") ||
                    pathname.includes("/BiDashboard/RedirectionPartnerDate") ||
                    pathname.includes("/BiDashboard/mentorkart") ||
                    pathname.includes("/BiDashboard/featuredApply")
                    ? classes.active_link
                    : ""
                    }`}
                >
                  Data Points
                </Link>


                <Link
                  to="/BiDashboard/PartnerCompanies"
                  className={`${classes.link} ${pathname === "/BiDashboard/PartnerCompanies"
                    ? classes.active_link
                    : ""
                    }`}
                >
                  Partner Companies
                </Link>
                <Link
                  to="/BiDashboard/orders"
                  className={`${classes.link} ${pathname === "/BiDashboard/orders"
                    ? classes.active_link
                    : ""
                    }`}
                >
                  Orders
                </Link>

                <Link
                  to="/BiDashboard/PostAJobs"
                  className={`${classes.link} ${pathname === "/BiDashboard/PostAJobs"
                    ? classes.active_link
                    : ""
                    }`}
                >
                  PostAJobs
                </Link>

                <Link
                  to="/BiDashboard/FeaturedCompanyList"
                  className={`${classes.link} ${pathname === "/BiDashboard/FeaturedCompanyList"
                    ? classes.active_link
                    : ""
                    }`}
                >
                  Featured Companies
                </Link>

                <div>
                  <div
                    onClick={() => showSubMenuFunc("course")}
                    className={`${classes.sub_menu} ${subMenu === "course" ? classes.active_sub_menu : ""
                      }`}
                  >
                    Course{" "}
                    {subMenu === "course" ? (
                      <BiChevronUp className={classes.sub_menu_icon} />
                    ) : (
                      <BiChevronDown className={classes.sub_menu_icon} />
                    )}
                  </div>
                  {subMenu === "course" && (
                    <ul>
                      <li>
                        <Link
                          to="/BiDashboard/courses"
                          className={`${classes.link} ${pathname === "/BiDashboard/courses" ||
                            pathname.includes("/BiDashboard/coursedetail")
                            ? classes.active_link
                            : ""
                            }`}
                        >
                          Courses
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/BiDashboard/courses-list"
                          className={`${classes.link} ${pathname === "/BiDashboard/courses-list"
                            ? classes.active_link
                            : ""
                            }`}
                        >
                          Courses List
                        </Link>
                      </li>
                    </ul>
                  )}
                </div>

                <div>
                  <div
                    onClick={() => showSubMenuFunc("event")}
                    className={`${classes.sub_menu} ${subMenu === "event" ? classes.active_sub_menu : ""
                      }`}
                  >
                    Event{" "}
                    {subMenu === "event" ? (
                      <BiChevronUp className={classes.sub_menu_icon} />
                    ) : (
                      <BiChevronDown className={classes.sub_menu_icon} />
                    )}
                  </div>
                  {subMenu === "event" && (
                    <ul>
                      <li>
                        <Link
                          to="/BiDashboard/eventList"
                          className={`${classes.link} ${pathname.includes("/BiDashboard/eventList")
                            ? classes.active_link
                            : ""
                            }`}
                        >
                          Event List
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/BiDashboard/events"
                          className={`${classes.link} ${pathname.includes("/BiDashboard/events") ||
                            pathname.includes("/BiDashboard/addevents") ||
                            pathname.includes("/BiDashboard/eventdetail") ||
                            pathname.includes("/BiDashboard/updateevent")
                            ? classes.active_link
                            : ""
                            }`}
                        >
                          Events
                        </Link>
                      </li>
                    </ul>
                  )}
                </div>

                <div>
                  <div
                    onClick={() => showSubMenuFunc("signUp")}
                    className={`${classes.sub_menu} ${subMenu === "signUp" ? classes.active_sub_menu : ""
                      }`}
                  >
                    Sign Up{" "}
                    {subMenu === "signUp" ? (
                      <BiChevronUp className={classes.sub_menu_icon} />
                    ) : (
                      <BiChevronDown className={classes.sub_menu_icon} />
                    )}
                  </div>
                  {subMenu === "signUp" && (
                    <ul>
                      <li>
                        <Link
                          to="/BiDashboard/loginData"
                          className={`${classes.link} ${pathname === "/BiDashboard/loginData"
                            ? classes.active_link
                            : ""
                            }`}
                        >
                          User's Login Data
                        </Link>
                      </li>
                    </ul>
                  )}
                </div>

                <div>
                  <div
                    onClick={() => showSubMenuFunc("contact")}
                    className={`${classes.sub_menu} ${subMenu === "contact" ? classes.active_sub_menu : ""
                      }`}
                  >
                    Contact
                    {subMenu === "contact" ? (
                      <BiChevronUp className={classes.sub_menu_icon} />
                    ) : (
                      <BiChevronDown className={classes.sub_menu_icon} />
                    )}
                  </div>
                  {subMenu === "contact" && (
                    <ul>
                      <li>
                        <Link
                          to="/BiDashboard/ContactUsDetails"
                          className={`${classes.link} ${pathname === "/BiDashboard/ContactUsDetails"
                            ? classes.active_link
                            : ""
                            }`}
                        >
                          ContactUs Details
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/BiDashboard/write-for-us-list"
                          className={`${classes.link} ${pathname === "/BiDashboard/write-for-us-list"
                            ? classes.active_link
                            : ""
                            }`}
                        >
                          Write for us list
                        </Link>
                      </li>
                    </ul>
                  )}
                </div>

                <div>
                  <div
                    onClick={() => showSubMenuFunc("hrProfessional")}
                    className={`${classes.sub_menu} ${subMenu === "hrProfessional"
                      ? classes.active_sub_menu
                      : ""
                      }`}
                  >
                    HR Professional{" "}
                    {subMenu === "hrProfessional" ? (
                      <BiChevronUp className={classes.sub_menu_icon} />
                    ) : (
                      <BiChevronDown className={classes.sub_menu_icon} />
                    )}
                  </div>
                  {subMenu === "hrProfessional" && (
                    <ul>
                      <li>
                        <Link
                          to="/BiDashboard/ShareHrprofilelist"
                          className={`${classes.link} ${pathname === "/BiDashboard/ShareHrprofilelist"
                            ? classes.active_link
                            : ""
                            }`}
                        >
                          Share Your Profile
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/BiDashboard/hrprofessionnal"
                          className={`${classes.link} ${pathname === "/BiDashboard/hrprofessionnal" ||
                            pathname.includes(
                              "/BiDashboard/hrProfessionalDetails"
                            ) ||
                            pathname.includes(
                              "/BiDashboard/addHrProfessional"
                            ) ||
                            pathname.includes("/BiDashboard/updateHr")
                            ? classes.active_link
                            : ""
                            }`}
                        >
                          Hr Professional
                        </Link>
                      </li>
                    </ul>
                  )}
                </div>
                <div>
                  <div
                    onClick={() => showSubMenuFunc("movementOfProfessional")}
                    className={`${classes.sub_menu} ${subMenu === "movementOfProfessional"
                      ? classes.active_sub_menu
                      : ""
                      }`}
                  >
                    MOP{" "}
                    {subMenu === "movementOfProfessional" ? (
                      <BiChevronUp className={classes.sub_menu_icon} />
                    ) : (
                      <BiChevronDown className={classes.sub_menu_icon} />
                    )}
                  </div>
                  {subMenu === "movementOfProfessional" && (
                    <ul>
                      <li>
                        <Link
                          to="/BiDashboard/MopJourney"
                          className={`${classes.link} ${pathname === "/BiDashboard/MopJourney"
                            ? classes.active_link
                            : ""
                            }`}
                        >
                          MopJourney Form
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/BiDashboard/MovementList"
                          className={`${classes.link} ${pathname === "/BiDashboard/MovementList" ||
                            pathname.includes("/BiDashboard/AddMovement") ||
                            pathname.includes("/BiDashboard/EditMovement") ||
                            pathname.includes("/BiDashboard/Movementdetail")
                            ? classes.active_link
                            : ""
                            }`}
                        >
                          Movement Of Professional
                        </Link>
                      </li>
                    </ul>
                  )}
                </div>


                <Link
                  to="/BiDashboard/WeCareMopFormList"
                  className={`${classes.link} ${pathname === "/BiDashboard/WeCareMopFormList" ||
                    pathname.includes("/BiDashboard/WeCareMopFormDetail")
                    ? classes.active_link
                    : ""
                    }`}
                >
                  Corporate Care List
                </Link>
                <Link
                  to="/BiDashboard/articles"
                  className={`${classes.link} ${pathname === "/BiDashboard/articles" ||
                    pathname.includes("/BiDashboard/editArticle") ||
                    pathname.includes("/BiDashboard/viewArticle")
                    ? classes.active_link
                    : ""
                    }`}
                >
                  Articles
                </Link>
                <Link
                  to="/BiDashboard/dynamicwidget"
                  className={`${classes.link} ${pathname === "/BiDashboard/dynamicwidget"
                    ? classes.active_link
                    : ""
                    }`}
                >
                  TimesWidget
                </Link>

                {/* <NavLink to="InterstitialAds" activeClassName={classes.active_link} className={classes.link}>Interstitial Ads</NavLink> */}
              </div>
            </div>
          </div>
        );
      default:
        console.log("invalid cases");
    }
  };

  return <div>{AccessLevel(props.userType)}</div>;
};

export default Sidebar;
