import React, { useEffect, useState } from "react";
import classes from "./AddCustomJobContent.module.css";
import { Link, useHistory } from "react-router-dom";
import BackButton from "../../Components/BackButton";
import Modal from "../../Components/Modal";
import EventInputs from "../../Components/Events/EventInputs";
import Loader from "react-loader-spinner";
import TextEditor from "../../Components/TextEditor";
import { AiOutlineClose } from "react-icons/ai";
import { useParams } from "react-router-dom";
const staticContents = [
    { contentTitle: "Static Title 1", contentDescription: "Static Description 1" },
    { contentTitle: "Static Title 2", contentDescription: "Static Description 2" }
];
const EditCustomJobContent = () => {
    const history = useHistory();
    const [modalShow, setModalShow] = useState(false);
    const [errorShow, setErrorShow] = useState(false);
    const [loading, setLoading] = useState(false);

    const [pageTitle, setPageTitle] = useState("");
    const [pageSubTitle, setPageSubTitle] = useState("");
    const [webImage, setWebImage] = useState("");
    const [uploadImage, setUploadImage] = useState("");
    const [altImage, setAltImage] = useState("");
    const [pageUrl, setPageUrl] = useState("");
    const [pageDescription, setPageDescription] = useState("");
    const [featuredCompanies, setFeaturedCompanies] = useState("");
    const [isLive, setIsLive] = useState(false);
    const { id } = useParams();

    const [noOfContent, setNoOfContent] = useState(1);
    const [contents, setContents] = useState([{ contentTitle: "", contentDescription: "" }]);


    const handleContentNumberChange = (e) => {
        let contentCount = Number(e.target.value);

        if (contentCount && contentCount > 0) {
            setNoOfContent(contentCount);
            const updatedContents = [...contents];
            while (updatedContents.length < contentCount) {
                updatedContents.push({ contentTitle: "", contentDescription: "" });
            }
            while (updatedContents.length > contentCount) {
                updatedContents.pop();
            }
            setContents(updatedContents);
        } else {
            setNoOfContent(1);
            setContents([{ contentTitle: "", contentDescription: "" }]);
        }
    };

    // Handle content field changes
    // const handleContentChange = (index, field, value) => {
    //     const updatedContents = [...contents];
    //     updatedContents[index][field] = value;
    //     setContents(updatedContents);
    // };

    // Handle removal of content item
    const handleRemoveContent = (index) => {
        const updatedContents = contents.filter((_, i) => i !== index);
        setContents(updatedContents);
        setNoOfContent(updatedContents.length);
    };

    const UpdateCustomJobPageApi = (e) => {
        e.preventDefault();
        setLoading(true);

        const headers = new Headers();
        headers.append("Authorization", "Token 77927b69bb144b065ca11bf2a9d452819cd852db");

        const formData = new FormData();
        formData.append("CustomJobPageUUID", id);
        formData.append("Title", pageTitle);
        formData.append("SubTitle", pageSubTitle);
        formData.append("Image", uploadImage);
        formData.append("PageSeoURL", pageUrl);
        formData.append("PageDescription", pageDescription);
        formData.append("FeaturedCompany", featuredCompanies);
        formData.append("ImageAltTag", altImage);
        formData.append("IsLive", isLive ? 1 : 0);
        formData.append("Content", JSON.stringify(contents));

        const requestOptions = {
            method: "POST",
            headers: headers,
            body: formData,
            redirect: "follow",
        };

        fetch(process.env.REACT_APP_API_URL + "/adminapi/UpdateCustomJobPage", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.status === "Success" && result.status_code === 200) {
                    setModalShow(true)
                    setPageTitle("");
                    setPageSubTitle("");
                    setWebImage("");
                    setPageUrl("");
                    setPageDescription("");
                    setAltImage("");
                    setIsLive(false);
                    setContents([{ contentTitle: "", contentDescription: "" }]);
                    setFeaturedCompanies("")
                    setNoOfContent(1);
                } else if (result.status_code === 300) {
                    setErrorShow(true);
                }
            })
            .catch((error) => console.log("error", error))
            .finally(() => setLoading(false));
    };

    const GetCustomJobPageByIdApi = () => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Token 77927b69bb144b065ca11bf2a9d452819cd852db");
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            CustomJobPageUUID: id,
        });

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };

        fetch(process.env.REACT_APP_API_URL + "/adminapi/GetCustomJobPageById", requestOptions)
            .then((response) => response.json())
            .then((result) => {

                // Ensure we are receiving and parsing the correct data
                if (result.data && result.data.Content) {
                    const parsedContent = JSON.parse(result.data.Content);

                    // Verify if content is correctly parsed and contains the expected number of items
                    if (Array.isArray(parsedContent) && parsedContent.length > 0) {
                        setContents(parsedContent);
                        setNoOfContent(parsedContent.length);  // Ensure number of content fields matches the length of content array
                    } else {
                        console.error("Parsed content is not an array or is empty:", parsedContent);
                    }
                } else {
                    console.error("Invalid data received from the API:", result.data);
                }

                // Set other states as needed
                setPageTitle(result.data.Title || "");
                setPageSubTitle(result.data.SubTitle || "");
                setWebImage(result.data.Image || "");
                setPageUrl(result.data.PageSeoURL || "");
                setPageDescription(result.data.PageDescription || "");
                setAltImage(result.data.ImageAltTag || "");
                setIsLive(result.data.IsLive == "1" ? true : false);
                setFeaturedCompanies(result.data.FeaturedCompany || "");
            })
            .catch((error) => console.error("Error fetching content data:", error));
    };

    const handleContentChange = (index, field, value) => {
        const updatedContents = [...contents];
        updatedContents[index][field] = value;

        setTimeout(() => {
            setContents(updatedContents);
        }, 1000);
    };


    useEffect(() => {
        GetCustomJobPageByIdApi();
    }, []);

    useEffect(() => {
    }, [contents]);


    return (
        <div className={classes.top_div}>
            {modalShow && (
                <Modal heading={"Data updated successfully"} onClick={() => history.push("/BiDashboard/CustomJobContent")} />
            )}
            {errorShow && <Modal heading={"Required Fields Missing"} onClick={() => setErrorShow(false)} />}

            <div className={classes.container}>
                <div className={classes.form}>
                    <div className={classes.form_header_container}>
                        <div className={classes.form_header}>Update Custom Job Content</div>
                        <Link to="/BiDashboard/CustomJobContent" className={classes.BackButton}>
                            <BackButton />
                        </Link>
                    </div>
                    <form onSubmit={UpdateCustomJobPageApi} className={classes.form_input}>
                        {/* Non-content fields */}
                        <EventInputs
                            input={"SingleLineInput"}
                            label={"Page Title"}
                            required={true}
                            placeholder={"Enter Page Title..."}
                            onChange={(e) => setPageTitle(e.target.value)}
                            value={pageTitle}
                        />
                        <EventInputs
                            input={"SingleLineInput"}
                            label={"Page Sub Title"}
                            required={true}
                            placeholder={"Enter Page Sub Title..."}
                            onChange={(e) => setPageSubTitle(e.target.value)}
                            value={pageSubTitle}
                        />
                        <div className={classes.ImgContainer}>
                            <EventInputs
                                input={"ImageInput"}
                                label={"Image"}
                                placeholder={"Image"}
                                onChange={(e) => setUploadImage(e.target.files[0])}
                            />
                            {uploadImage ? (
                                // eslint-disable-next-line jsx-a11y/alt-text
                                <img
                                    src={URL.createObjectURL(uploadImage)}
                                    className={classes.imgicon}
                                />
                            ) : (
                                <img src={webImage} alt="icon" className={classes.imgicon} />
                            )}
                        </div>
                        <EventInputs
                            input={"SingleLineInput"}
                            label={"Image Alt title"}
                            required={true}
                            placeholder={"Enter Image Alt title..."}
                            onChange={(e) => setAltImage(e.target.value)}
                            value={altImage}
                        />
                        <EventInputs
                            input={"SingleLineInput"}
                            label={"Page Url"}
                            placeholder={"Enter SEO Url..."}
                            required={true}
                            onChange={(e) => setPageUrl(e.target.value)}
                            value={pageUrl}
                            disabled={true}
                        />
                        <EventInputs
                            input={"MultiLineInput"}
                            label={"Page Description"}
                            placeholder={"Page Description"}
                            onChange={(e) => setPageDescription(e.target.value)}
                            value={pageDescription}
                        />

                        <EventInputs
                            input={"SingleLineInput"}
                            label={"Featured Companies"}
                            placeholder={"Enter companies (comma-separated)"}
                            onChange={(e) => setFeaturedCompanies(e.target.value)}
                            value={featuredCompanies}
                        />

                        <div className={classes.form_input}>
                            <EventInputs
                                input={"Select"}
                                label={"No. of Content"}
                                required={true}
                                onChange={handleContentNumberChange}
                                value={noOfContent}
                                options={[
                                    { value: 1, label: "1" },
                                    { value: 2, label: "2" },
                                    { value: 3, label: "3" },
                                    { value: 4, label: "4" },
                                    { value: 5, label: "5" },
                                    { value: 6, label: "6" },
                                    { value: 7, label: "7" },
                                    { value: 8, label: "8" },
                                    { value: 9, label: "9" },
                                    { value: 10, label: "10" }
                                ]}
                            />
                        </div>


                        {/* Content Fields */}
                        {contents.map((content, index) => (
                            <div key={`content-${index}`} className={classes.formGroup}>
                                <div className={classes.contentHeader}>
                                    <h3>Content {index + 1}</h3>
                                    <button
                                        type="button"
                                        className={classes.removeContentBtn}
                                        onClick={() => handleRemoveContent(index)}
                                        title="Remove Content"
                                    >
                                        <AiOutlineClose size={20} />
                                    </button>
                                </div>
                                <EventInputs
                                    input={"SingleLineInput"}
                                    label={`Content Title ${index + 1}`}
                                    placeholder={`Enter Content Title ${index + 1}...`}
                                    onChange={(e) => handleContentChange(index, "contentTitle", e.target.value)}
                                    value={content.contentTitle || ""}
                                />
                                <TextEditor
                                    value={(() => {
                                        return content.contentDescription || "";
                                    })()}
                                    onChangeContent={(value) => {
                                        handleContentChange(index, "contentDescription", value);
                                    }}
                                    placeholder={`Content Description ${index + 1}`}
                                />

                            </div>
                        ))}

                        {/* Submit and Checkbox */}
                        <div className={classes.checkbox}>
                            <EventInputs input={"CheckBox"} onChange={() => setIsLive(!isLive)} label={"Want to publish"} value={isLive} />
                            <div className={classes.btnWrapper}>
                                <button type="submit" className={classes.Upload_button} disabled={loading}>
                                    {loading ? (
                                        <Loader type="ThreeDots" color="#fff" height={10} width={70} visible={true} />
                                    ) : (
                                        "Update"
                                    )}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};


export default EditCustomJobContent;
