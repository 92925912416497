import React, { useState, useEffect } from "react";
import LoadingSpinner from "../Components/LoadingSpinner";
import classes from "./mentor.module.css";
import { CSVLink } from "react-csv";
import ExcelDownload from "../Components/Articles/ExcelDownload";
import Card from "../Components/Dashboard/Card";
import { Link } from "react-router-dom";
import TimesBreadCrumbs from "../Components/TimesBreadCrumbs";
import { AiOutlineArrowUp } from "react-icons/ai";
import { AiOutlineArrowDown } from "react-icons/ai";
const FeaturedApplyresume = (props) => {
  const [isLoading, setLoading] = useState(false);
  const [today, setToday] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [firstdate, setFirstDate] = useState("");
  const [lastdate, setLastDate] = useState("");
  const [count, setCount] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [errors, setErrors] = useState(false);
  const [order, setOrder] = useState("ASC");
  const [isfirstname, setIsfirstname] = useState("ASC");
  const [islast, setIslast] = useState("ASC");
  const [mobile, setMobile] = useState("ASC");
  const [isemail, setIsemail] = useState("ASC");
  const [isid, setIsid] = useState("ASC");
  const [isdate, setIsdate] = useState("ASC");
  const [iscity, setIscity] = useState("ASC");
  useEffect(() => {
    featuredapply();
  }, [currentPage]);

  const featuredapply = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      startDate: startDate,
      endDate: endDate,
      Page: currentPage,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_API_URL + "/adminapi/featured-apply-resume",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setToday(result.data);
        setCount(result.count);
        setFirstDate(result.startdate);
        setLastDate(result.endDate);
      })
      .catch((error) => console.log("error", error))
      .finally(() => setLoading(false));
  };

  const formValidation = () => {
    let isValid = true;
    let error = {};

    if (!startDate || !endDate) {
      isValid = false;
      error.startDate = "Please Select Both Dates";
    }

    setErrors(error);
    return isValid;
  };
  // const pageInfo = {
  //   currentPageName: "Mentorkart Detail Page",
  //   previousPageName: "DataPoints",
  //   previousPage: "DataPoints",
  // };

  const sortingemail = (col) => {
    if (isemail === "ASC") {
      const sortedemail = [...today].sort((a, b) => (a[col] > b[col] ? 1 : -1));
      setToday(sortedemail);
      setIsemail("DSC");
    }
    if (isemail === "DSC") {
      const sortedemail = [...today].sort((a, b) => (a[col] < b[col] ? 1 : -1));
      setToday(sortedemail);
      setIsemail("ASC");
    }
  };

  const sortingmobile = (col) => {
    if (mobile === "ASC") {
      const sortedmobile = [...today].sort((a, b) =>
        a[col] > b[col] ? 1 : -1
      );
      setToday(sortedmobile);
      setMobile("DSC");
    }
    if (mobile === "DSC") {
      const sortedmobile = [...today].sort((a, b) =>
        a[col] < b[col] ? 1 : -1
      );
      setToday(sortedmobile);
      setMobile("ASC");
    }
  };
  const sortingid = (col) => {
    if (isid === "ASC") {
      const sortedid = [...today].sort((a, b) => (a[col] > b[col] ? 1 : -1));
      setToday(sortedid);
      setIsid("DSC");
    }
    if (isid === "DSC") {
      const sortedid = [...today].sort((a, b) => (a[col] < b[col] ? 1 : -1));
      setToday(sortedid);
      setIsid("ASC");
    }
  };
  const sortingfirstname = (col) => {
    if (isfirstname === "ASC") {
      const sortedfirst = [...today].sort((a, b) => (a[col] > b[col] ? 1 : -1));
      setToday(sortedfirst);
      setIsfirstname("DSC");
    }
    if (isfirstname === "DSC") {
      const sortedfirst = [...today].sort((a, b) => (a[col] < b[col] ? 1 : -1));
      setToday(sortedfirst);
      setIsfirstname("ASC");
    }
  };
  const sortinglast = (col) => {
    if (islast === "ASC") {
      const sortedlast = [...today].sort((a, b) => (a[col] > b[col] ? 1 : -1));
      setToday(sortedlast);
      setIslast("DSC");
    }
    if (islast === "DSC") {
      const sortedlast = [...today].sort((a, b) => (a[col] < b[col] ? 1 : -1));
      setToday(sortedlast);
      setIslast("ASC");
    }
  };
  const sortingdate = (col) => {
    if (isdate === "ASC") {
      const sorteddate = [...today].sort((a, b) => (a[col] > b[col] ? 1 : -1));
      setToday(sorteddate);
      setIsdate("DSC");
    }
    if (isdate === "DSC") {
      const sorteddate = [...today].sort((a, b) => (a[col] < b[col] ? 1 : -1));
      setToday(sorteddate);
      setIsdate("ASC");
    }
  };
  const sortingcity = (col) => {
    if (iscity === "ASC") {
      const sortedcity = [...today].sort((a, b) => (a[col] > b[col] ? 1 : -1));
      setToday(sortedcity);
      setIscity("DSC");
    }
    if (iscity === "DSC") {
      const sortedcity = [...today].sort((a, b) => (a[col] < b[col] ? 1 : -1));
      setToday(sortedcity);
      setIscity("ASC");
    }
  };
  const pageInfo = {
    currentPageName: "Featured Apply Via Resume",
    previousPageName: "DataPoints",
    previousPage: "DataPoints",
  };
  return (
    <div className={classes.dashboard_container}>
      <div className={classes.TimsbiBreadcrumb}>
        {" "}
        <TimesBreadCrumbs pageInfo={pageInfo} />{" "}
      </div>
      <div className={classes.events_title}>Apply Via Resume Data</div>
      <div className={classes.DateFilter_container}>
        <div className={classes.button_container}>
          <CSVLink
            data={today}
            filename={"featuredapplyviaresume.csv"}
            target="_blank"
          >
            <button className={classes.button_articleList}>CSV</button>
          </CSVLink>
          <ExcelDownload
            csvData={today}
            fileName="featuredapplyviaresume-table"
          />
        </div>
        <div className={classes.dateDiv}>
          <div>
            <label className={classes.dateLable}>Start Date</label>
            <input
              type="date"
              value={startDate || firstdate}
              onChange={(e) => setStartDate(e.target.value)}
            />
            <label className={classes.dateLable}>End Date</label>
            <input
              type="date"
              value={endDate || lastdate}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </div>
          <div className={classes.form_error}>{errors.startDate}</div>
          <div className={classes.buttons}>
            <button
              type="button"
              className="button"
              onClick={() =>
                formValidation()
                  ? featuredapply()
                  : console.log("Something Wrong")
              }
            >
              {" "}
              Search
            </button>
          </div>
        </div>
      </div>
      <div className={classes.headerPageNavigation}>
        <div className={classes.card}>
          <div className={classes.count}>Count :</div>
          <div className={classes.count_main}>{count}</div>
        </div>
        <div className={classes.buttons1}>
          {currentPage !== 1 ? (
            <button
              onClick={() => {
                setCurrentPage(currentPage - 1);
              }}
              className={classes.buttonone}
            >
              Prev
            </button>
          ) : (
            <></>
          )}
          <span>{currentPage}</span>
          {today.length > 9 ? (
            <button
              onClick={() => {
                setCurrentPage(currentPage + 1);
              }}
              className={classes.buttonone}
            >
              Next
            </button>
          ) : (
            <></>
          )}
        </div>
      </div>

      {isLoading ? (
        <div className={classes.spinner}>
          <LoadingSpinner />
        </div>
      ) : (
        <div className={classes.table_container}>
          <div className={classes.container}>
            {today.length > 0 ? (
              <table className={classes.orders_table}>
                <tr className={classes.table_row}>
                  <th className={classes.table_heading}>Sno</th>
                  <th className={classes.table_heading}>
                    First Name
                    {isfirstname === "ASC" ? (
                      <span
                        className={classes.arrow}
                        onClick={() => sortingfirstname("firstName")}
                      >
                        <AiOutlineArrowDown />
                      </span>
                    ) : (
                      <span
                        className={classes.arrow}
                        onClick={() => sortingfirstname("firstName")}
                      >
                        <AiOutlineArrowUp />
                      </span>
                    )}
                  </th>
                  <th className={classes.table_heading}>
                    Last Name
                    {islast === "ASC" ? (
                      <span
                        className={classes.arrow}
                        onClick={() => sortinglast("lastName")}
                      >
                        <AiOutlineArrowDown />
                      </span>
                    ) : (
                      <span
                        className={classes.arrow}
                        onClick={() => sortinglast("lastName")}
                      >
                        <AiOutlineArrowUp />
                      </span>
                    )}
                  </th>

                  <th className={classes.table_heading}>
                    Mobile
                    {mobile === "ASC" ? (
                      <span
                        className={classes.arrow}
                        onClick={() => sortingmobile("phone")}
                      >
                        <AiOutlineArrowDown />
                      </span>
                    ) : (
                      <span
                        className={classes.arrow}
                        onClick={() => sortingmobile("phone")}
                      >
                        <AiOutlineArrowUp />
                      </span>
                    )}
                  </th>
                  <th className={classes.table_heading}>
                    Email
                    {isemail === "ASC" ? (
                      <span
                        className={classes.arrow}
                        onClick={() => sortingemail("email")}
                      >
                        <AiOutlineArrowDown />
                      </span>
                    ) : (
                      <span
                        className={classes.arrow}
                        onClick={() => sortingemail("email")}
                      >
                        <AiOutlineArrowUp />
                      </span>
                    )}
                  </th>

                  <th className={classes.table_heading}>
                    Date
                    {isdate === "ASC" ? (
                      <span
                        className={classes.arrow}
                        onClick={() => sortingdate("Date")}
                      >
                        <AiOutlineArrowDown />
                      </span>
                    ) : (
                      <span
                        className={classes.arrow}
                        onClick={() => sortingdate("Date")}
                      >
                        <AiOutlineArrowUp />
                      </span>
                    )}
                  </th>
                  <th className={classes.table_heading}>
                    City
                    {iscity === "ASC" ? (
                      <span
                        className={classes.arrow}
                        onClick={() => sortingcity("city")}
                      >
                        <AiOutlineArrowDown />
                      </span>
                    ) : (
                      <span
                        className={classes.arrow}
                        onClick={() => sortingcity("city")}
                      >
                        <AiOutlineArrowUp />
                      </span>
                    )}
                  </th>
                  <th className={classes.table_heading}>Resume Url</th>
                </tr>

                <tbody>
                  {today.map((item, value) => {
                    return (
                      <tr className={classes.table_row}>
                        <td className={classes.table_td}>{item.sno}</td>
                        <td className={classes.table_td}>{item.firstName}</td>
                        <td className={classes.table_td}>{item.lastName}</td>
                        <td className={classes.table_td}>{item.phone}</td>
                        <td className={classes.table_td}>{item.email}</td>
                        <td className={classes.table_td}>{item.Date}</td>
                        <td className={classes.table_td}>{item.city}</td>
                        <td className={classes.table_td}>
                          <a
                            href={item.resumeUrl}
                            download={item.resumeUrl}
                            target="_blank"
                          >
                            Resume Url
                          </a>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            ) : (
              <div className={classes.No_data}>No data found!</div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default FeaturedApplyresume;
