import React, { useState } from "react";

import classes from "../Webinarvideos/InsertVideo.module.css";

import EventInputs from "../Components/Events/EventInputs";
import Modal from "../Components/Modal";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import TimesBreadCrumbs from "../Components/TimesBreadCrumbs";
import Loader from "react-loader-spinner";
import TextEditor from "../Components/TextEditor";

const AddAbout = () => {
  const [loading, setLoading] = useState(false);

  const [erorrshow, setErrorShow] = useState(false);
  const [internalErrorShow, setInternalErrorShow] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [Title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  let history = useHistory();

  const AddVideoApi = () => {
    setLoading(true);
    const myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 4YzpGk8Vf8IcxzxQ68teZ2KM5iX8SktW5RN4iz92"
    );
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      Title: Title,
      Description: description,
      PageName: "Hrconclave",
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_API_URL + "/adminapi/InsertAboutUs",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "Success" && result.status_code === 200) {
          setModalShow(true);
          setDescription("");
        } else if (result.status_code === 400 || result.status_code === 500) {
          setInternalErrorShow(true);
        } else if (result.status_code === 300) {
          setErrorShow(true);
        }
      })
      .catch((error) => console.log("error", error))
      .finally(() => setLoading(false));
  };

  const pageInfo = {
    currentPageName: "Add HR",
    previousPageName: "About List",
    previousPage: "/BiDashboard/Hrabout",
  };
  const currentURL = window.location.href;

  console.log(currentURL);
  return (
    <div className={classes.top_div}>
      {modalShow && (
        <Modal
          heading={"About added successfully"}
          onClick={() => history.push("/BiDashboard/Hrabout")}
        />
      )}
      {erorrshow && (
        <Modal
          heading={"Required Fields Are Missing"}
          onClick={() => setErrorShow(false)}
        />
      )}
      {internalErrorShow && (
        <Modal
          heading={"Internal server error"}
          onClick={() => setInternalErrorShow(false)}
        />
      )}

      <div className={classes.container}>
        <div className={classes.form}>
          <div className={classes.TimsbiBreadcrumb}>
            {" "}
            <TimesBreadCrumbs pageInfo={pageInfo} />{" "}
          </div>
          <div className={classes.form_header_container}>
            <div className={classes.form_header}>Add HR About</div>
          </div>
          <div className={classes.form_input}>
            <div className={classes.input}>
              <EventInputs
                input={"SingleLineInput"}
                label={"Title"}
                placeholder={"Title"}
                onChange={(e) => setTitle(e.target.value)}
                value={Title}
              />

              <div className={classes.formGroup}>
                <label htmlFor="Content">Description :</label>

                <div>
                  <TextEditor
                    onChangeContent={setDescription}
                    value={description}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <button className={classes.Upload_button} onClick={() => AddVideoApi()}>
          {loading ? (
            <Loader
              type="TailSpin"
              color="white"
              height={20}
              width={50}
              className="text-center"
            />
          ) : (
            "Add"
          )}
        </button>
      </div>
    </div>
  );
};

export default AddAbout;
