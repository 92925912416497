import React, { useState, useEffect } from "react";
import classes from "./ArticlesDownload.module.css";
import LoadingSpinner from "../../Components/LoadingSpinner";
import { BiLeftArrow } from "react-icons/bi";

const ArticlesDownload = (props) => {
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [downloadPath, setDownloadPath] = useState("");
  const [month, setMonth] = useState("");
  const [date, setDate] = useState("");
  // const [fileName, setFileName] = useState("");



  const ArticleList = (month, date) => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      Month: month ? month : "",
      Date: date ? date : "",
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_API_URL + "/adminapi/FtpFileList",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        if (result.status_code == 200 && result.status === "Success") {
          setData(result.data);
          setDownloadPath(result.path);
        }
      })
      .catch((error) => console.log("error", error))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    ArticleList();
  }, []);

  const downloadArticleFunc = (filename, filepath) => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      filename: filename ? filename : "",
      path: filepath ? filepath : "",
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_API_URL + "/adminapi/FtpFileDownload",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        if (result.status_code == 200 && result.status === "Success") {
          window.open(result.data);
        }
      })
      .catch((error) => console.log("error", error))
      .finally(() => setLoading(false));
  };

  const searchArticleFunc = (item) => {
    if (downloadPath === "/") {
      setMonth(item);
      ArticleList(item, "");
    } else if (downloadPath === `/Ascent/${month}`) {
      setDate(item);
      ArticleList(month, item);
    } else if (downloadPath === `/Ascent/${month}/${date}`) {
      // setFileName(item);
      downloadArticleFunc(item, downloadPath);
    }
  };

  const goBackFunc = () => {
    if (downloadPath === `/Ascent/${month}`) {
      ArticleList();
    } else if (downloadPath === `/Ascent/${month}/${date}`) {
      ArticleList(month);
    }
  };

  return (
    <div className={classes.main_div}>
      <div className={classes.container}>
        <div className={classes.header}>Search Articles:</div>

        <div className={classes.backButtonContainer}>
          <button
            type="button"
            className={classes.backButton}
            onClick={goBackFunc}
          >
            <BiLeftArrow />
          </button>
        </div>

        <div className={classes.containerMain}>
          {isLoading ? (
            <div className={classes.spinner}>
              <LoadingSpinner />{" "}
            </div>
          ) : data.length ? (
            <div className={classes.cardContainer}>
              {data.map((item) => (
                <>
                  <div
                    className={classes.cardUI}
                    onClick={() => {
                      searchArticleFunc(item);
                    }}
                  >
                    {item}
                  </div>
                </>
              ))}
            </div>
          ) : (
            <h2 className={classes.serachErrorText}>"No Articles found !"</h2>
          )}
        </div>
      </div>
    </div>
  );
};

export default ArticlesDownload;
