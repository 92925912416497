import React, { useState, useEffect } from "react";
import LoadingSpinner from "../Components/LoadingSpinner";
import classes from "./Redirectionpartner.module.css";
import { CSVLink } from "react-csv";
import ExcelDownload from "../Components/Articles/ExcelDownload";
import { Link } from "react-router-dom";
import TimesBreadCrumbs from "../Components/TimesBreadCrumbs";
import { AiOutlineArrowUp } from "react-icons/ai";
import { AiOutlineArrowDown } from "react-icons/ai";

const RedirectionPartnerDate = () => {
  const [isLoading, setLoading] = useState(false);
  const [redirection, setRedirection] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const currentURL = window.location.href.split("/").pop();
  const [count, setCount] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [order, setOrder] = useState("ASC");
  const [entrance, setEntrance] = useState("ASC");

  useEffect(() => {
    RedirectionApi();
  }, [currentPage]);

  const RedirectionApi = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      startDate: startDate || currentURL,
      endDate: endDate || currentURL,

      Page: currentPage,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_API_URL + "/adminapi/getRedirectionOnPartnerPage",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setRedirection(result.data);
        setCount(result.count);
      })
      .catch((error) => console.log("error", error))
      .finally(() => setLoading(false));
  };

  const pageInfo = {
    currentPageName: "Partners Clicks Data By Date",
    previousPageName: "Partners Clicks Data Points",
    previousPage: "/BiDashboard/RediectionPartner",
  };
  const sorting = (col) => {
    if (order === "ASC") {
      const sorted = [...redirection].sort((a, b) =>
        a[col] > b[col] ? 1 : -1
      );
      setRedirection(sorted);
      setOrder("DSC");
    }
    if (order === "DSC") {
      const sorted = [...redirection].sort((a, b) =>
        a[col] < b[col] ? 1 : -1
      );
      setRedirection(sorted);
      setOrder("ASC");
    }
  };
  const sortingentrance = (col) => {
    if (entrance === "ASC") {
      const sortedentrnce = [...redirection].sort((a, b) =>
        a[col] > b[col] ? 1 : -1
      );
      setRedirection(sortedentrnce);
      setEntrance("DSC");
    }
    if (entrance === "DSC") {
      const sortedentrnce = [...redirection].sort((a, b) =>
        a[col] < b[col] ? 1 : -1
      );
      setRedirection(sortedentrnce);
      setEntrance("ASC");
    }
  };
  return (
    <div className={classes.dashboard_container}>
      <div className={classes.TimsbiBreadcrumb}>
        {" "}
        <TimesBreadCrumbs pageInfo={pageInfo} />{" "}
      </div>
      <div className={classes.events_title}>Partners Clicks Page By Date</div>
      <div className={classes.DateFilter_container}>
        <div className={classes.button_container}>
          <CSVLink
            data={redirection}
            filename={"RePARTNERSBydate.csv"}
            target="_blank"
          >
            <button className={classes.button_articleList}>CSV</button>
          </CSVLink>
          <ExcelDownload csvData={redirection} fileName="RePARTNERSBydate" />
        </div>
        <div className={classes.dateDiv}>
          <div>
            <label className={classes.dateLable}>Start Date</label>
            <input
              type="date"
              value={startDate || currentURL}
              onChange={(e) => setStartDate(e.target.value)}
            />
            <label className={classes.dateLable}>End Date</label>
            <input
              type="date"
              value={endDate || currentURL}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </div>

          <div className={classes.buttons}>
            <button
              type="button"
              className="button"
              onClick={() => RedirectionApi()}
            >
              Search
            </button>
          </div>
        </div>
      </div>
      <div className={classes.card}>
        <div className={classes.count}>Count :</div>
        <div className={classes.count_main}>{count}</div>
      </div>

      {isLoading ? (
        <div className={classes.spinner}>
          <LoadingSpinner />
        </div>
      ) : (
        <div className={classes.table_container}>
          <div className={classes.container}>
            {redirection.length > 0 ? (
              <table className={classes.orders_table}>
                <tr className={classes.table_row}>
                  <th className={classes.table_heading}>Sno</th>
                  <th className={classes.table_heading}>Company Name</th>
                  <th className={classes.table_heading}>Date</th>
                  <th className={classes.table_heading}>
                    Entrance
                    {entrance === "ASC" ? (
                      <span
                        className={classes.arrow}
                        onClick={() => sortingentrance("entrence")}
                      >
                        <AiOutlineArrowDown />
                      </span>
                    ) : (
                      <span
                        className={classes.arrow}
                        onClick={() => sortingentrance("entrence")}
                      >
                        <AiOutlineArrowUp />
                      </span>
                    )}
                  </th>
                  <th className={classes.table_heading}>
                    Page View
                    {order === "ASC" ? (
                      <span
                        className={classes.arrow}
                        onClick={() => sorting("pageview")}
                      >
                        <AiOutlineArrowDown />
                      </span>
                    ) : (
                      <span
                        className={classes.arrow}
                        onClick={() => sorting("pageview")}
                      >
                        <AiOutlineArrowUp />
                      </span>
                    )}
                  </th>

                  <th className={classes.table_heading}>View</th>
                </tr>

                <tbody>
                  {redirection.map((item, value) => {
                    return (
                      <tr className={classes.table_row}>
                        <td className={classes.table_td}>{item.sno}</td>
                        <td className={classes.table_td}>{item.companyName}</td>
                        <td className={classes.table_td}>{item.date}</td>
                        <td className={classes.table_td}>{item.entrence}</td>
                        <td className={classes.table_td}>{item.pageview}</td>

                        <td className={classes.table_td}>
                          {" "}
                          <Link
                            to={
                              "/BiDashboard/RedirectionDetail/" +
                              item.companyName +
                              "/" +
                              item.date
                            }
                          >
                            View{" "}
                          </Link>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            ) : (
              <div className={classes.No_data}>No data found!</div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default RedirectionPartnerDate;
