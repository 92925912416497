import React, { useState, useEffect } from "react";
import LoadingSpinner from "../Components/LoadingSpinner";
import classes from "./coursedatapoints.module.css";
import { CSVLink } from "react-csv";
import ExcelDownload from "../Components/Articles/ExcelDownload";
import Card from "../Components/Dashboard/Card";
import { Link } from "react-router-dom";
import TimesBreadCrumbs from "../Components/TimesBreadCrumbs";
import { AiOutlineArrowUp } from "react-icons/ai";
import { AiOutlineArrowDown } from "react-icons/ai";
const CourseById = (props) => {
  const [isLoading, setLoading] = useState(false);
  const [coursedata, setCoursedata] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [count, setCount] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [order, setOrder] = useState("ASC");
  const [entrance, setEntrance] = useState("ASC");
  const [redirectedFrom, setRedirectedFrom] = useState("ASC");
  const [isfirstname, setIsfirstname] = useState("ASC");
  const currentURL = window.location.href.split("/").pop();
  useEffect(() => {
    CourseByIdApi();
  }, [currentPage]);

  const courseid = localStorage.setItem("Id", currentURL);

  console.log(courseid);

  const CourseByIdApi = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      CourseProviderId: currentURL,
      Page: currentPage,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_API_URL + "/adminapi/getCourseThroughId",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setCoursedata(result.data);
        setCount(result.count);
      })
      .catch((error) => console.log("error", error))
      .finally(() => setLoading(false));
  };
  console.log(startDate, "Start Date");
  const pageInfo = {
    currentPageName: "Course Data Points",
    previousPageName: "Course Data Points",
    previousPage: "/BiDashboard/CourseDataPoints",
  };
  const sorting = (col) => {
    if (order === "ASC") {
      const sorted = [...coursedata].sort((a, b) => (a[col] > b[col] ? 1 : -1));
      setCoursedata(sorted);
      setOrder("DSC");
    }
    if (order === "DSC") {
      const sorted = [...coursedata].sort((a, b) => (a[col] < b[col] ? 1 : -1));
      setCoursedata(sorted);
      setOrder("ASC");
    }
  };
  const sortingentrance = (col) => {
    if (entrance === "ASC") {
      const sortedentrnce = [...coursedata].sort((a, b) =>
        a[col] > b[col] ? 1 : -1
      );
      setCoursedata(sortedentrnce);
      setEntrance("DSC");
    }
    if (entrance === "DSC") {
      const sortedentrnce = [...coursedata].sort((a, b) =>
        a[col] < b[col] ? 1 : -1
      );
      setCoursedata(sortedentrnce);
      setEntrance("ASC");
    }
  };
  const sortingredirected = (col) => {
    if (redirectedFrom === "ASC") {
      const sortedredirected = [...coursedata].sort((a, b) =>
        a[col] > b[col] ? 1 : -1
      );
      setCoursedata(sortedredirected);
      setRedirectedFrom("DSC");
    }
    if (redirectedFrom === "DSC") {
      const sortedredirected = [...coursedata].sort((a, b) =>
        a[col] < b[col] ? 1 : -1
      );
      setCoursedata(sortedredirected);
      setRedirectedFrom("ASC");
    }
  };
  const sortingfirstname = (col) => {
    if (isfirstname === "ASC") {
      const sortedfirst = [...coursedata].sort((a, b) =>
        a[col] > b[col] ? 1 : -1
      );
      setCoursedata(sortedfirst);
      setIsfirstname("DSC");
    }
    if (isfirstname === "DSC") {
      const sortedfirst = [...coursedata].sort((a, b) =>
        a[col] < b[col] ? 1 : -1
      );
      setCoursedata(sortedfirst);
      setIsfirstname("ASC");
    }
  };
  return (
    <div className={classes.dashboard_container}>
      <div className={classes.TimsbiBreadcrumb}>
        {" "}
        <TimesBreadCrumbs pageInfo={pageInfo} />{" "}
      </div>
      <div className={classes.events_title}>Course Id : {currentURL}</div>

      <div className={classes.DateFilter_container}>
        <div className={classes.button_container}>
          <CSVLink
            data={coursedata}
            filename={"Course_Table.csv"}
            target="_blank"
          >
            <button className={classes.button_articleList}>CSV</button>
          </CSVLink>
          <ExcelDownload csvData={coursedata} fileName="Course_Table" />
        </div>
      </div>
      <div className={classes.card}>
        <div className={classes.count}>Count :</div>
        <div className={classes.count_main}>{count}</div>
      </div>
      {count > 10 ? (
        <div className={classes.buttons1}>
          {currentPage !== 1 ? (
            <button
              onClick={() => {
                setCurrentPage(currentPage - 1);
              }}
              className={classes.buttonone}
            >
              Prev
            </button>
          ) : (
            <></>
          )}
          <span>{currentPage}</span>
          {coursedata.length > 9 ? (
            <>
              <button
                onClick={() => {
                  setCurrentPage(currentPage + 1);
                }}
                className={classes.buttonone}
              >
                Next
              </button>
            </>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <></>
      )}
      {isLoading ? (
        <div className={classes.spinner}>
          <LoadingSpinner />
        </div>
      ) : (
        <div className={classes.table_container}>
          <div className={classes.container}>
            {coursedata.length > 0 ? (
              <table className={classes.orders_table}>
                <tr className={classes.table_row}>
                  <th className={classes.table_heading}>Sno</th>
                  <th className={classes.table_heading}>
                    Course Title
                    {isfirstname === "ASC" ? (
                      <span
                        className={classes.arrow}
                        onClick={() => sortingfirstname("course_title")}
                      >
                        <AiOutlineArrowDown />
                      </span>
                    ) : (
                      <span
                        className={classes.arrow}
                        onClick={() => sortingfirstname("course_title")}
                      >
                        <AiOutlineArrowUp />
                      </span>
                    )}
                  </th>
                  <th className={classes.table_heading}>
                    Page View
                    {order === "ASC" ? (
                      <span
                        className={classes.arrow}
                        onClick={() => sorting("pageview")}
                      >
                        <AiOutlineArrowDown />
                      </span>
                    ) : (
                      <span
                        className={classes.arrow}
                        onClick={() => sorting("pageview")}
                      >
                        <AiOutlineArrowUp />
                      </span>
                    )}
                  </th>
                  <th className={classes.table_heading}>
                    Entrance
                    {entrance === "ASC" ? (
                      <span
                        className={classes.arrow}
                        onClick={() => sortingentrance("entrence")}
                      >
                        <AiOutlineArrowDown />
                      </span>
                    ) : (
                      <span
                        className={classes.arrow}
                        onClick={() => sortingentrance("entrence")}
                      >
                        <AiOutlineArrowUp />
                      </span>
                    )}
                  </th>
                  <th className={classes.table_heading}>
                    Redirected From
                    {redirectedFrom === "ASC" ? (
                      <span
                        className={classes.arrow}
                        onClick={() => sortingredirected("redirectedFrom")}
                      >
                        <AiOutlineArrowDown />
                      </span>
                    ) : (
                      <span
                        className={classes.arrow}
                        onClick={() => sortingredirected("redirectedFrom")}
                      >
                        <AiOutlineArrowUp />
                      </span>
                    )}
                  </th>
                  <th className={classes.table_heading}>View</th>
                </tr>

                <tbody>
                  {coursedata.map((item, value) => {
                    return (
                      <tr className={classes.table_row}>
                        <td className={classes.table_td}>{item.sno}</td>
                        <td className={classes.table_td}>
                          {item.course_title}
                        </td>
                        <td className={classes.table_td}>{item.pageview}</td>
                        <td className={classes.table_td}>{item.entrence}</td>
                        <td className={classes.table_td}>
                          {item.redirectedFrom}
                        </td>
                        <td className={classes.table_td}>
                          <Link
                            to={"/BiDashboard/CourseDetail/" + item.course_id}
                          >
                            View
                          </Link>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            ) : (
              <div className={classes.No_data}>No data found!</div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default CourseById;
