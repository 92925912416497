import React, { useEffect, useState } from "react";

import classes from "../TimesWidget/editwidget.module.css";
import EventInputs from "../Events/EventInputs";
import Modal from "../../Components/Modal";
import Loader from "react-loader-spinner";
import { useHistory, useParams } from "react-router-dom";
import TimesBreadCrumbs from "../TimesBreadCrumbs";

const EditWidget = () => {
  const [loading, setLoading] = useState("");
  const [pagename, setPagename] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [redirection, setRedirection] = useState("");
  const [image, setImage] = useState("");
  const [ButtonTxt, setButtonTxt] = useState("");
  const [check, setCheck] = useState(0);
  const [erorrshow, setErrorShow] = useState(false);
  const [internalErrorShow, setInternalErrorShow] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [newimage, setNewimage] = useState("");
  const [imageAlt, setImageAlt] = useState("");
  const { id } = useParams();
  const history = useHistory();

  useEffect(() => {
    ViewApi();
  }, []);

  const ViewApi = () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      DynamicWidgetID: id,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_API_URL + "/adminapi/GetDynamicWidget",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setButtonTxt(result.data.ButtonText);
        setCheck(result.data.IsPublished);
        setPagename(result.data.PageName);
        setDescription(result.data.Description);
        setTitle(result.data.Title);
        setRedirection(result.data.RedirectionUrl);
        setImage(result.data.IconImage);
        setImageAlt(result.data.IconAltTag)
      })
      .catch((error) => console.log("error", error));
  };

  const UpdateApi = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );

    var formdata = new FormData();
    formdata.append("DynamicWidgetID", id);
    formdata.append("Title", title);
    formdata.append("Description", description);
    formdata.append("RedirectionUrl", redirection);
    formdata.append("PageName", pagename);
    formdata.append("IconImage", newimage);
    formdata.append("IsPublished", check);
    formdata.append("ButtonText ", ButtonTxt);
    formdata.append("IconAltTag", imageAlt);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_API_URL + "/adminapi/UpdateDynamicWidget",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "SUCCESS" && result.status_code === 200) {
          setModalShow(true);
        } else if (result.status_code === 400 || result.status_code === 500) {
          setInternalErrorShow(true);
        } else if (result.status_code === 300) {
          setErrorShow(true);
        }
      })
      .catch((error) => console.log("error", error))
      .finally(() => setLoading(false));
  };
  const pageInfo = {
    currentPageName: "Update Widget",
    previousPageName: "Widget List",
    previousPage: "/BiDashboard/dynamicwidget",
  };
  return (
    <div className={classes.top_div}>
      {modalShow && (
        <Modal
          heading={"Data Updated successfully"}
          onClick={() => history.push("/BiDashboard/dynamicwidget")}
        />
      )}
      {erorrshow && (
        <Modal
          heading={"Required Fields Are Missing"}
          onClick={() => setErrorShow(false)}
        />
      )}
      {internalErrorShow && (
        <Modal
          heading={"Internal server error"}
          onClick={() => setInternalErrorShow(false)}
        />
      )}

      <div className={classes.container}>
        <div className={classes.form}>
          <div className={classes.TimsbiBreadcrumb}>
            {" "}
            <TimesBreadCrumbs pageInfo={pageInfo} />{" "}
          </div>
          <div className={classes.form_header_container}>
            <div className={classes.form_header}>Update A Widget</div>
            <div className={classes.checkbox}>
              <span className={classes.live}>Live</span>
              <EventInputs
                input={"CheckBox"}
                onChange={() => setCheck(!check === true ? 1 : 0)}
                value={check}
              />
            </div>
          </div>
          <div className={classes.form_input}>
            <div className={classes.input}>
              <EventInputs
                input={"SingleLineInput"}
                label={"PageName"}
                placeholder={"PageName"}
                onChange={(e) => setPagename(e.target.value)}
                value={pagename}
              />
              <EventInputs
                input={"SingleLineInput"}
                label={"Description"}
                placeholder={"Description"}
                onChange={(e) => setDescription(e.target.value)}
                value={description}
              />
              <EventInputs
                input={"SingleLineInput"}
                label={"RedirectionUrl"}
                placeholder={"RedirectionUrl"}
                onChange={(e) => setRedirection(e.target.value)}
                value={redirection}
              />
              <EventInputs
                input={"SingleLineInput"}
                label={"Image Alt"}
                placeholder={"Image Alt"}
                onChange={(e) => setImageAlt(e.target.value)}
                value={imageAlt}
              />
            </div>

            <div className={classes.input}>
              <EventInputs
                input={"SingleLineInput"}
                label={"Button TXT"}
                placeholder={"Button TXT"}
                onChange={(e) => setButtonTxt(e.target.value)}
                value={ButtonTxt}
              />
              <EventInputs
                input={"SingleLineInput"}
                label={"Title"}
                placeholder={"Title"}
                onChange={(e) => setTitle(e.target.value)}
                value={title}
              />
              <EventInputs
                input={"ImageInput"}
                label={"Desktop Image"}
                placeholder={"Desktop Image"}
                onChange={(e) => setNewimage(e.target.files[0])}
              />
              {newimage ? (
                // eslint-disable-next-line jsx-a11y/alt-text
                <img
                  src={URL.createObjectURL(newimage)}
                  className={classes.imgicon}
                />
              ) : (
                <img src={image} alt="icon" className={classes.imgicon} />
              )}
            </div>
          </div>
        </div>
        <button className={classes.Upload_button} onClick={() => UpdateApi()}>
          {loading ? (
            <Loader
              type="TailSpin"
              color="white"
              height={20}
              width={50}
              className="text-center"
            />
          ) : (
            "Update"
          )}
        </button>
      </div>
    </div>
  );
};

export default EditWidget;
