import React from "react";
import classes from "./FormViewModule.module.css";
import { FaCopy } from "react-icons/fa";

const FormViewModal = (props) => {
  if (!props.isModalOpen) {
    return null;
  }

  const copyToClipboard = (url) => {
    navigator.clipboard
      .writeText(url)
      .then(() => {
        alert("URL copied to clipboard!");
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  const { data } = props;

  return (
    <>
      <div className={classes.overlay} onClick={props.onClose}></div>
      <div className={classes.modal}>
        {/* Modal Header */}
        <div className={classes.header}>
          <h2>Student Details</h2>
          <button className={classes.closeButton} onClick={props.onClose}>
            &times;
          </button>
        </div>

        {/* Modal Content */}
        <div className={classes.contentWrapper}>
          {/* Left Section: Image and Video */}
          <h4>Personal Details</h4>
          <div className={classes.leftSection}>
            <div className={classes.imageContainer}>
              {/* <h3>Profile Image</h3> */}
              <img
                src={data.ImageURL}
                alt={`${data.Name}'s Profile`}
                className={classes.image}
              />
            </div>

            <div className={classes.section}>

              <table className={classes.detailsTable}>
                <tbody>
                  <tr>
                    <th>Name</th>
                    <td>{data.Name || 'Not Provided'}</td>
                  </tr>
                  <tr>
                    <th>Email</th>
                    <td>{data.EmailId || 'Not Provided'}</td>
                  </tr>
                  <tr>
                    <th>Mobile</th>
                    <td>{data.MobileNo || 'Not Provided'}</td>
                  </tr>
                  <tr>
                    <th>Street Address</th>
                    <td>{data.StreetAddress || 'Not Provided'}</td>
                  </tr>

                </tbody>
              </table>
            </div>
            <div className={classes.section}>
              {/* <h3>Profile Details</h3> */}
              <table className={classes.detailsTable}>
                <tbody>
                  <tr>
                    <th>City</th>
                    <td>{data.City || 'Not Provided'}</td>
                  </tr>
                  <tr>
                    <th>State</th>
                    <td>{data.State || 'Not Provided'}</td>
                  </tr>
                  <tr>
                    <th>Country</th>
                    <td>{data.Country || 'Not Provided'}</td>
                  </tr>
                </tbody>
              </table>

            </div>
          </div>

          {/* Right Section: Details */}
          <div className={classes.rightSection}>
            {/* Profile Details */}

            <div className={classes.section}>
              <hr />
              <h4>Education Details</h4>
              <table className={classes.detailsTable}>
                <tbody>
                  <tr>
                    <th>Institution</th>
                    <td>{data.Institutionname || 'Not Provided'}</td>
                  </tr>
                  <tr>
                    <th>Degree</th>
                    <td>{data.DegreeName || 'Not Provided'}</td>
                  </tr>
                  <tr>
                    <th>Graduation Year</th>
                    <td>{data.GraduationYear || 'Not Provided'}</td>
                  </tr>
                  <tr>
                    <th>Major</th>
                    <td>{data.Major || "Not Provided"}</td>
                  </tr>
                </tbody>
              </table>

            </div>
            <div className={classes.section}>
              <hr />
              <h4>Work Experience</h4>
              {data.Experience && data.Experience.length > 0 ? (
                data.Experience.map((job, index) => (
                  <>
                    <table key={index} className={classes.detailsTable}>
                      <tbody>
                        <tr>
                          <th>Company Name</th>
                          <td>{job.CompanyName || "Not Provided"}</td>
                        </tr>
                        <tr>
                          <th>Job Title</th>
                          <td>{job.JobTitle || "Not Provided"}</td>
                        </tr>
                        <tr>
                          <th>Employment Date</th>
                          <td>{(job.Employmentdate?.split("T")[0]) || "Not Provided"}</td>
                        </tr>
                        <tr>
                          <th>Key Responsibilities</th>
                          <td>{job.KeyResponsibilities || "Not Provided"}</td>
                        </tr>
                        <tr>
                          <th>Achievements</th>
                          <td>{job.Achievements || "Not Provided"}</td>
                        </tr>
                      </tbody>
                    </table>
                    <hr />
                  </>
                ))
              ) : (
                <p className={classes.noDataText}>No job experience provided.</p>
              )}
            </div>

            {/* Career Goals */}
            <div className={classes.section}>
              <h4>Career Details</h4>
              <table className={classes.detailsTable}>
                <tbody>
                  <tr>
                    <th>Career Goals</th>
                    <td>{data.CareerGoals || "Not Provided"}</td>
                  </tr>
                  <th>Skills & Expertise</th>
                  <td>{data.Skills || "Not Provided"}</td>
                </tbody>
              </table>
            </div>

            {/* Skills */}
            <div className={classes.section}>
              <hr />
              <h4>Additional Information (Optional)</h4>
              <table className={classes.detailsTable}>
                <tbody>
                  <tr>
                    <th>PortfolioLink</th>
                    <td>{<a
                      href={data.PortfolioLink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {data.PortfolioLink}
                    </a> || "Not Provided"}</td>
                  </tr>
                  <tr>
                    <th>Certifications</th>
                    <td>{data.Certifications || "Not Provided"}</td>
                  </tr>
                  <tr>
                    <th>Language</th>
                    <td>{data.Language || "Not Provided"}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            {/* Certifications */}
            <div className={classes.section}>
              <hr />
              <h4>Optional Fields</h4>
              <table className={classes.detailsTable}>
                <tbody>
                  <tr>
                    <th>Resume</th>
                    <td>{<a
                      href={data.ResumeURL}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {data.ResumeURL}
                    </a> || "Not Provided"}</td>
                  </tr>
                  <tr>
                    <th>Cover Letter</th>
                    <td>{<a
                      href={data.CoverLetterURL}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {data.CoverLetterURL}
                    </a> || "Not Provided"}</td>
                  </tr>
                  <tr>
                    <th>Video Resume</th>
                    <td><div className={classes.videoContainer}>

                      <video
                        src={data.VideoResumeURL}
                        className={classes.video}
                        controls
                      >
                        Not Provided
                      </video>
                    </div></td>
                  </tr>
                  <tr>
                    <th>LinkedIn</th>
                    <td>{<a
                      href={data.LinkedinURL}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {data.LinkedinURL}
                    </a> || "Not Provided"}</td>
                  </tr>
                  <tr>
                    <th>Cover Letter</th>
                    <td>{<a
                      href={data.CoverLetterURL}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {data.CoverLetterURL}
                    </a> || "Not Provided"}</td>
                  </tr>
                  <tr>
                    <th>Salary</th>
                    <td>₹{Number(data.SalaryExpectations).toLocaleString() || 'Not Provided'}</td>
                  </tr>
                  <tr>
                    <th>Interests & Hobbies</th>
                    <td>{data.Hobbies || 'Not Provided'}</td>
                  </tr>
                </tbody>
              </table>
            </div>

          </div>

          {/* Close Button */}
          <div className={classes.footer}>
            <button className={classes.closeFooterButton} onClick={props.onClose}>
              Close
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default FormViewModal;
