import React, { useState, useEffect } from "react";

import classes from "./wecaremoplist.module.css";
import { CSVLink } from "react-csv";

import { useParams } from "react-router-dom";
import TimesBreadCrumbs from "../TimesBreadCrumbs";
import ExcelDownload from "../Articles/ExcelDownload";
import LoadingSpinner from "../LoadingSpinner";

const WeCareMopFormDetail = () => {
  const [isLoading, setLoading] = useState(false);
  const [registerdetail, setRegisterdetail] = useState([]);

  const { id } = useParams();
  useEffect(() => {
    ResterDetailApi();
  }, []);

  const ResterDetailApi = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      WecareMOPRequestID: id,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_API_URL + "/adminapi/WecareMOPDetail",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setRegisterdetail(result.data);
      })
      .catch((error) => console.log("error", error))
      .finally(() => setLoading(false));
  };
  const pageInfo = {
    currentPageName: "We Care MOP Form Detail",
    previousPageName: "We Care MOP Form List",
    previousPage: "/BiDashboard/WeCareMopFormList",
  };

  let newdata = [];
  newdata.push(registerdetail);
  console.log("data", registerdetail);
  return (
    <div className={classes.dashboard_container}>
      <div className={classes.TimsbiBreadcrumb}>
        {" "}
        <TimesBreadCrumbs pageInfo={pageInfo} />{" "}
      </div>
      <div className={classes.events_title}>We Care MOP Form Detail Page</div>

      <div className={classes.button_container}>
        <CSVLink
          data={newdata}
          filename={"We Care Mop Form Detail.csv"}
          target="_blank"
        >
          <button className={classes.button_articleList}>CSV</button>
        </CSVLink>
        <ExcelDownload csvData={newdata} fileName="We Care Mop Form Detail" />
      </div>

      {isLoading ? (
        <div className={classes.spinner}>
          <LoadingSpinner />
        </div>
      ) : (
        <div className={classes.table_container}>
          <div className={classes.container}>
            <table className={classes.orders_table}>
              <tr className={classes.table_row}>
                <th className={classes.table_heading}>ID</th>
                <th className={classes.table_heading}>Type</th>
                <th className={classes.table_heading}>Name</th>
                <th className={classes.table_heading}>Email</th>

                <th className={classes.table_heading}>Mobile</th>

                <th className={classes.table_heading}>ShortDescription</th>
              </tr>

              <tbody>
                <tr className={classes.table_row}>
                  <td className={classes.table_td}>{registerdetail.ID}</td>
                  <td className={classes.table_td_type}>
                    {registerdetail.Type}
                  </td>
                  <td className={classes.table_td}>{registerdetail.Name}</td>
                  <td className={classes.table_td}>{registerdetail.Email}</td>
                  <td className={classes.table_td}>{registerdetail.Mobile}</td>

                  <td className={classes.table_td_short}>
                    {registerdetail.ShortDescription}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default WeCareMopFormDetail;
