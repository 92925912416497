import React from "react";
import classes from "./ViewCustomJobContent.module.css";

const ViewContentModal = (props) => {
    if (!props.isModalOpen) {
        return null;
    }

    const {
        Title,
        SubTitle,
        Image,
        ImageAltTag,
        FeaturedCompany,
        PageSeoURL,
        PageDescription,
        Content,
        IsLive,
    } = props.data;

    const parsedContents = JSON.parse(Content);

    return (
        <>
            <div className={classes.overlay} onClick={props.onClose}></div>
            <div className={classes.modal}>
                {/* Modal Header */}
                <div className={classes.header}>
                    <h2>Page Details</h2>
                    <button className={classes.closeButton} onClick={props.onClose}>
                        &times;
                    </button>
                </div>

                {/* Modal Content */}
                <div className={classes.contentWrapper}>
                    {/* Image Section */}
                    {Image && (
                        <div className={classes.section}>
                            <div className={classes.headingCustom}>Page Image- Banner</div>
                            <div className={classes.imageContainer}>
                                <img src={Image} alt={ImageAltTag || "Page Image"} className={classes.image} />
                            </div>
                        </div>
                    )}

                    {/* Page Information */}
                    <div className={classes.section}>
                        <div className={classes.headingCustom}>Page Information</div>
                        <table className={classes.detailsTable}>
                            <tbody>
                                <tr>
                                    <th>Page Title</th>
                                    <td>{Title || "Not Provided"}</td>
                                </tr>
                                <tr>
                                    <th>Page Subtitle</th>
                                    <td>{SubTitle || "Not Provided"}</td>
                                </tr>
                                <tr>
                                    <th>Featured Companies</th>
                                    <td>{FeaturedCompany || "Not Provided"}</td>
                                </tr>
                                <tr>
                                    <th>Page URL</th>
                                    <td>{PageSeoURL || "Not Provided"}</td>
                                </tr>
                                <tr>
                                    <th>Page Description</th>
                                    <td>
                                        <div dangerouslySetInnerHTML={{ __html: PageDescription }} />
                                    </td>
                                </tr>

                                <tr>
                                    <th>Live</th>
                                    <td>{IsLive ? "Yes" : "No"}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>


                    {/* Dynamic Content Sections */}
                    <div className={classes.section}>
                        <div className={classes.headingCustom}>Content Details</div >
                        {parsedContents && parsedContents.length > 0 ? (
                            parsedContents.map((content, index) => (
                                <div key={index} className={classes.contentItem}>
                                    <div className={classes.headingCustomContent}>Content {index + 1}</div>
                                    <table className={classes.detailsTable}>
                                        <tbody className={classes.contentdiv}>
                                            <tr>
                                                <th>Title</th>
                                                <td ><div className={classes.description}>{content.contentTitle || "Not Provided"}</div></td>
                                            </tr>
                                            <tr>
                                                <th>Description</th>
                                                <td>
                                                    <div className={classes.description} dangerouslySetInnerHTML={{ __html: content.contentDescription }} />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            ))
                        ) : (
                            <p>No Content Available</p>
                        )}
                    </div>

                    {/* Modal Footer */}
                    <div className={classes.footer}>
                        <button className={classes.closeFooterButton} onClick={props.onClose}>
                            Close
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ViewContentModal;