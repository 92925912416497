import { Fragment } from "react";
import classes from "./Modal.module.css";
const Backdrop = () => {
  return <div className={classes.backdrop} />;
};

const ConfirmModal = (props) => {
  return (
    <Fragment>
      <Backdrop />
      <div className={classes.modal}>
        <div className={classes.heading}>{props.heading}</div>
        <div className={classes.buttonWrapper}>
          <button className={classes.button} onClick={props.onClick}>
            ok
          </button>
          <button className={classes.button} onClick={props.onClose}>
            Cancel
          </button>
        </div>
      </div>
    </Fragment>
  );
};

export default ConfirmModal;
