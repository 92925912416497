import React, { useState, useEffect } from "react";
import LoadingSpinner from "../Components/LoadingSpinner";
import classes from "./signupLastmonth.module.css";
import { CSVLink } from "react-csv";
import ExcelDownload from "../Components/Articles/ExcelDownload";
import TimesBreadCrumbs from "../Components/TimesBreadCrumbs";
import { AiOutlineArrowUp } from "react-icons/ai";
import { AiOutlineArrowDown } from "react-icons/ai";
const SignupLastMonthDetail = () => {
  const [isLoading, setLoading] = useState(false);
  const [lastsignup, setLastsignup] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [count, setCount] = useState("");
  const currentURL = window.location.href.split("/").pop();
  const [currentPage, setCurrentPage] = useState(1);
  const [issno, setIssno] = useState("DSC");
  const [isid, setIsid] = useState("ASC");
  const [mobile, setMobile] = useState("ASC");
  const [isemail, setIsemail] = useState("ASC");

  const [isfirstname, setIsfirstname] = useState("ASC");
  const [islastname, setIslastname] = useState("ASC");
  const [isaddress, setIsaddress] = useState("ASC");
  const [iscountry, setIscountry] = useState("ASC");
  useEffect(() => {
    LastMonthSignupApi();
  }, [currentPage]);

  const LastMonthSignupApi = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");
    console.log(typeof currentURL);
    var raw = JSON.stringify({
      startDate: startDate || currentURL,
      endDate: endDate || currentURL,
      Page: currentPage,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_API_URL + "/adminapi/getSignupDetail",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setLastsignup(result.data);
        setCount(result.count);
      })
      .catch((error) => console.log("error", error))
      .finally(() => setLoading(false));
  };
  const pageInfo = {
    currentPageName: "Sign Up Detail",
    previousPageName: "Sign Up Count Page",
    previousPage: "/BiDashboard/SignupLastMonth",
  };

  const sortingsrno = (col) => {
    if (issno === "ASC") {
      const sortsno = [...lastsignup].sort((a, b) =>
        a[col] > b[col] ? 1 : -1
      );
      setLastsignup(sortsno);
      setIssno("DSC");
    }
    if (issno === "DSC") {
      const sortsno = [...lastsignup].sort((a, b) =>
        a[col] < b[col] ? 1 : -1
      );
      setLastsignup(sortsno);
      setIssno("ASC");
    }
  };
  const sortingid = (col) => {
    if (isid === "ASC") {
      const sortedid = [...lastsignup].sort((a, b) =>
        a[col] > b[col] ? 1 : -1
      );
      setLastsignup(sortedid);
      setIsid("DSC");
    }
    if (isid === "DSC") {
      const sortedid = [...lastsignup].sort((a, b) =>
        a[col] < b[col] ? 1 : -1
      );
      setLastsignup(sortedid);
      setIsid("ASC");
    }
  };
  const sortingemail = (col) => {
    if (isemail === "ASC") {
      const sortedemail = [...lastsignup].sort((a, b) =>
        a[col] > b[col] ? 1 : -1
      );
      setLastsignup(sortedemail);
      setIsemail("DSC");
    }
    if (isemail === "DSC") {
      const sortedemail = [...lastsignup].sort((a, b) =>
        a[col] < b[col] ? 1 : -1
      );
      setLastsignup(sortedemail);
      setIsemail("ASC");
    }
  };

  const sortingmobile = (col) => {
    if (mobile === "ASC") {
      const sortedmobile = [...lastsignup].sort((a, b) =>
        a[col] > b[col] ? 1 : -1
      );
      setLastsignup(sortedmobile);
      setMobile("DSC");
    }
    if (mobile === "DSC") {
      const sortedmobile = [...lastsignup].sort((a, b) =>
        a[col] < b[col] ? 1 : -1
      );
      setLastsignup(sortedmobile);
      setMobile("ASC");
    }
  };
  const sortingfirstname = (col) => {
    if (isfirstname === "ASC") {
      const sortedfirst = [...lastsignup].sort((a, b) =>
        a[col] > b[col] ? 1 : -1
      );
      setLastsignup(sortedfirst);
      setIsfirstname("DSC");
    }
    if (isfirstname === "DSC") {
      const sortedfirst = [...lastsignup].sort((a, b) =>
        a[col] < b[col] ? 1 : -1
      );
      setLastsignup(sortedfirst);
      setIsfirstname("ASC");
    }
  };
  const sortinglastname = (col) => {
    if (islastname === "ASC") {
      const sortedlast = [...lastsignup].sort((a, b) =>
        a[col] > b[col] ? 1 : -1
      );
      setLastsignup(sortedlast);
      setIslastname("DSC");
    }
    if (islastname === "DSC") {
      const sortedlast = [...lastsignup].sort((a, b) =>
        a[col] < b[col] ? 1 : -1
      );
      setLastsignup(sortedlast);
      setIslastname("ASC");
    }
  };
  const sortingaddress = (col) => {
    if (isaddress === "ASC") {
      const sortedaddress = [...lastsignup].sort((a, b) =>
        a[col] > b[col] ? 1 : -1
      );
      setLastsignup(sortedaddress);
      setIsaddress("DSC");
    }
    if (isaddress === "DSC") {
      const sortedaddress = [...lastsignup].sort((a, b) =>
        a[col] < b[col] ? 1 : -1
      );
      setLastsignup(sortedaddress);
      setIsaddress("ASC");
    }
  };
  const sortingcountry = (col) => {
    if (iscountry === "ASC") {
      const sortedcountry = [...lastsignup].sort((a, b) =>
        a[col] > b[col] ? 1 : -1
      );
      setLastsignup(sortedcountry);
      setIscountry("DSC");
    }
    if (iscountry === "DSC") {
      const sortedcountry = [...lastsignup].sort((a, b) =>
        a[col] < b[col] ? 1 : -1
      );
      setLastsignup(sortedcountry);
      setIscountry("ASC");
    }
  };
  return (
    <div className={classes.dashboard_container}>
      <div className={classes.TimsbiBreadcrumb}>
        {" "}
        <TimesBreadCrumbs pageInfo={pageInfo} />{" "}
      </div>
      <div className={classes.events_title}>Sign Up Detailed Page</div>
      <div className={classes.DateFilter_container}>
        <div className={classes.button_container}>
          <CSVLink
            data={lastsignup}
            filename={"LastMonthSignUpDetail.csv"}
            target="_blank"
          >
            <button className={classes.button_articleList}>CSV</button>
          </CSVLink>
          <ExcelDownload csvData={lastsignup} fileName="LastMonthSignUp" />
        </div>
        <div className={classes.dateDiv}>
          <div>
            <label className={classes.dateLable}>Start Date</label>
            <input
              type="date"
              value={startDate || currentURL}
              onChange={(e) => setStartDate(e.target.value)}
            />
            <label className={classes.dateLable}>End Date</label>
            <input
              type="date"
              value={endDate || currentURL}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </div>
          <div className={classes.buttons}>
            <button
              type="button"
              className="button"
              onClick={() => LastMonthSignupApi()}
            >
              {" "}
              Search
            </button>
          </div>
        </div>
      </div>
      <div className={classes.card}>
        <div className={classes.count}>Count :</div>
        <div className={classes.count_main}>{count}</div>
      </div>
      <div className={classes.buttons1}>
        {currentPage !== 1 ? (
          <button
            onClick={() => {
              setCurrentPage(currentPage - 1);
            }}
            className={classes.buttonone}
          >
            Prev
          </button>
        ) : (
          <></>
        )}
        <span>{currentPage}</span>
        {lastsignup.length > 9 ? (
          <button
            onClick={() => {
              setCurrentPage(currentPage + 1);
            }}
            className={classes.buttonone}
          >
            Next
          </button>
        ) : (
          <></>
        )}
      </div>
      {isLoading ? (
        <div className={classes.spinner}>
          <LoadingSpinner />
        </div>
      ) : (
        <div className={classes.table_container}>
          <div className={classes.container}>
            {lastsignup.length > 0 ? (
              <table className={classes.orders_table}>
                <tr className={classes.table_row}>
                  <th className={classes.table_heading}>
                    Sno
                    {issno === "ASC" ? (
                      <span
                        className={classes.arrow}
                        onClick={() => sortingsrno("sno")}
                      >
                        <AiOutlineArrowDown />
                      </span>
                    ) : (
                      <span
                        className={classes.arrow}
                        onClick={() => sortingsrno("sno")}
                      >
                        <AiOutlineArrowUp />
                      </span>
                    )}
                  </th>
                  <th className={classes.table_heading}>Date</th>
                  <th className={classes.table_heading}>
                    Id
                    {isid === "ASC" ? (
                      <span
                        className={classes.arrow}
                        onClick={() => sortingid("id")}
                      >
                        <AiOutlineArrowDown />
                      </span>
                    ) : (
                      <span
                        className={classes.arrow}
                        onClick={() => sortingid("id")}
                      >
                        <AiOutlineArrowUp />
                      </span>
                    )}
                  </th>

                  <th className={classes.table_heading}>
                    Email
                    {isemail === "ASC" ? (
                      <span
                        className={classes.arrow}
                        onClick={() => sortingemail("email")}
                      >
                        <AiOutlineArrowDown />
                      </span>
                    ) : (
                      <span
                        className={classes.arrow}
                        onClick={() => sortingemail("email")}
                      >
                        <AiOutlineArrowUp />
                      </span>
                    )}
                  </th>
                  <th className={classes.table_heading}>
                    Mobile
                    {mobile === "ASC" ? (
                      <span
                        className={classes.arrow}
                        onClick={() => sortingmobile("mobile")}
                      >
                        <AiOutlineArrowDown />
                      </span>
                    ) : (
                      <span
                        className={classes.arrow}
                        onClick={() => sortingmobile("mobile")}
                      >
                        <AiOutlineArrowUp />
                      </span>
                    )}
                  </th>
                  <th className={classes.table_heading}>
                    First Name
                    {isfirstname === "ASC" ? (
                      <span
                        className={classes.arrow}
                        onClick={() => sortingfirstname("firstName")}
                      >
                        <AiOutlineArrowDown />
                      </span>
                    ) : (
                      <span
                        className={classes.arrow}
                        onClick={() => sortingfirstname("firstName")}
                      >
                        <AiOutlineArrowUp />
                      </span>
                    )}
                  </th>
                  <th className={classes.table_heading}>
                    Last Name
                    {islastname === "ASC" ? (
                      <span
                        className={classes.arrow}
                        onClick={() => sortinglastname("lastName")}
                      >
                        <AiOutlineArrowDown />
                      </span>
                    ) : (
                      <span
                        className={classes.arrow}
                        onClick={() => sortinglastname("lastName")}
                      >
                        <AiOutlineArrowUp />
                      </span>
                    )}
                  </th>
                  <th className={classes.table_heading}>
                    Address
                    {isaddress === "ASC" ? (
                      <span
                        className={classes.arrow}
                        onClick={() => sortingaddress("address")}
                      >
                        <AiOutlineArrowDown />
                      </span>
                    ) : (
                      <span
                        className={classes.arrow}
                        onClick={() => sortingaddress("address")}
                      >
                        <AiOutlineArrowUp />
                      </span>
                    )}
                  </th>
                  <th className={classes.table_heading}>
                    Country
                    {iscountry === "ASC" ? (
                      <span
                        className={classes.arrow}
                        onClick={() => sortingcountry("country")}
                      >
                        <AiOutlineArrowDown />
                      </span>
                    ) : (
                      <span
                        className={classes.arrow}
                        onClick={() => sortingcountry("country")}
                      >
                        <AiOutlineArrowUp />
                      </span>
                    )}
                  </th>
                </tr>

                <tbody>
                  {lastsignup.map((item, value) => {
                    return (
                      <tr className={classes.table_row}>
                        <td className={classes.table_td}>{item.sno}</td>
                        <td className={classes.table_td}>{item.Date}</td>
                        <td className={classes.table_td}>{item.id}</td>

                        <td className={classes.table_td}>{item.email}</td>
                        <td className={classes.table_td}>{item.mobile}</td>
                        <td className={classes.table_td}>{item.firstName}</td>
                        <td className={classes.table_td}>{item.lastName}</td>
                        <td className={classes.table_td}>{item.address}</td>
                        <td className={classes.table_td}>{item.country}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            ) : (
              <div className={classes.No_data}>No data found!</div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default SignupLastMonthDetail;
