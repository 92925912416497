import React, { useState, useEffect } from "react";
import LoadingSpinner from "../Components/LoadingSpinner";
import classes from "./eventregisterdetail.module.css";
import { CSVLink } from "react-csv";
import ExcelDownload from "../Components/Articles/ExcelDownload";
import TimesBreadCrumbs from "../Components/TimesBreadCrumbs";
import { useParams } from "react-router-dom";

const EventRegisterDetail = () => {
  const [isLoading, setLoading] = useState(false);
  const [registerdetail, setRegisterdetail] = useState([]);
  //   const [startDate, setStartDate] = useState("");
  //   const [endDate, setEndDate] = useState("");
  //   const [count, setCount] = useState("");
  //   const currentURL = window.location.href.split("/").pop();

  const { id } = useParams();
  useEffect(() => {
    ResterDetailApi();
  }, []);

  const ResterDetailApi = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      EventRegistrationId: id,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_API_URL + "/adminapi/EventRegistrationDetails",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setRegisterdetail(result.data);
      })
      .catch((error) => console.log("error", error))
      .finally(() => setLoading(false));
  };
  const pageInfo = {
    currentPageName: "Event Register Detail",
    previousPageName: "Event Register",
    previousPage: "/BiDashboard/eventsregisterlist",
  };

  //   const sortingsrno = (col) => {
  //     if (issno === "ASC") {
  //       const sortsno = [...eventregisterdetail].sort((a, b) =>
  //         a[col] > b[col] ? 1 : -1
  //       );
  //       setLastsignup(sortsno);
  //       setIssno("DSC");
  //     }
  //     if (issno === "DSC") {
  //       const sortsno = [...lastsignup].sort((a, b) =>
  //         a[col] < b[col] ? 1 : -1
  //       );
  //       setLastsignup(sortsno);
  //       setIssno("ASC");
  //     }
  //   };
  //   const sortingid = (col) => {
  //     if (isid === "ASC") {
  //       const sortedid = [...lastsignup].sort((a, b) =>
  //         a[col] > b[col] ? 1 : -1
  //       );
  //       setLastsignup(sortedid);
  //       setIsid("DSC");
  //     }
  //     if (isid === "DSC") {
  //       const sortedid = [...lastsignup].sort((a, b) =>
  //         a[col] < b[col] ? 1 : -1
  //       );
  //       setLastsignup(sortedid);
  //       setIsid("ASC");
  //     }
  //   };
  //   const sortingemail = (col) => {
  //     if (isemail === "ASC") {
  //       const sortedemail = [...lastsignup].sort((a, b) =>
  //         a[col] > b[col] ? 1 : -1
  //       );
  //       setLastsignup(sortedemail);
  //       setIsemail("DSC");
  //     }
  //     if (isemail === "DSC") {
  //       const sortedemail = [...lastsignup].sort((a, b) =>
  //         a[col] < b[col] ? 1 : -1
  //       );
  //       setLastsignup(sortedemail);
  //       setIsemail("ASC");
  //     }
  //   };

  //   const sortingmobile = (col) => {
  //     if (mobile === "ASC") {
  //       const sortedmobile = [...lastsignup].sort((a, b) =>
  //         a[col] > b[col] ? 1 : -1
  //       );
  //       setLastsignup(sortedmobile);
  //       setMobile("DSC");
  //     }
  //     if (mobile === "DSC") {
  //       const sortedmobile = [...lastsignup].sort((a, b) =>
  //         a[col] < b[col] ? 1 : -1
  //       );
  //       setLastsignup(sortedmobile);
  //       setMobile("ASC");
  //     }
  //   };
  //   const sortingfirstname = (col) => {
  //     if (isfirstname === "ASC") {
  //       const sortedfirst = [...lastsignup].sort((a, b) =>
  //         a[col] > b[col] ? 1 : -1
  //       );
  //       setLastsignup(sortedfirst);
  //       setIsfirstname("DSC");
  //     }
  //     if (isfirstname === "DSC") {
  //       const sortedfirst = [...lastsignup].sort((a, b) =>
  //         a[col] < b[col] ? 1 : -1
  //       );
  //       setLastsignup(sortedfirst);
  //       setIsfirstname("ASC");
  //     }
  //   };
  //   const sortinglastname = (col) => {
  //     if (islastname === "ASC") {
  //       const sortedlast = [...lastsignup].sort((a, b) =>
  //         a[col] > b[col] ? 1 : -1
  //       );
  //       setLastsignup(sortedlast);
  //       setIslastname("DSC");
  //     }
  //     if (islastname === "DSC") {
  //       const sortedlast = [...lastsignup].sort((a, b) =>
  //         a[col] < b[col] ? 1 : -1
  //       );
  //       setLastsignup(sortedlast);
  //       setIslastname("ASC");
  //     }
  //   };
  //   const sortingaddress = (col) => {
  //     if (isaddress === "ASC") {
  //       const sortedaddress = [...lastsignup].sort((a, b) =>
  //         a[col] > b[col] ? 1 : -1
  //       );
  //       setLastsignup(sortedaddress);
  //       setIsaddress("DSC");
  //     }
  //     if (isaddress === "DSC") {
  //       const sortedaddress = [...lastsignup].sort((a, b) =>
  //         a[col] < b[col] ? 1 : -1
  //       );
  //       setLastsignup(sortedaddress);
  //       setIsaddress("ASC");
  //     }
  //   };
  //   const sortingcountry = (col) => {
  //     if (iscountry === "ASC") {
  //       const sortedcountry = [...lastsignup].sort((a, b) =>
  //         a[col] > b[col] ? 1 : -1
  //       );
  //       setLastsignup(sortedcountry);
  //       setIscountry("DSC");
  //     }
  //     if (iscountry === "DSC") {
  //       const sortedcountry = [...lastsignup].sort((a, b) =>
  //         a[col] < b[col] ? 1 : -1
  //       );
  //       setLastsignup(sortedcountry);
  //       setIscountry("ASC");
  //     }
  //   };

  let newdata = [];
  newdata.push(registerdetail);

  // console.log(newdata, "lo");
  return (
    <div className={classes.dashboard_container}>
      <div className={classes.TimsbiBreadcrumb}>
        {" "}
        <TimesBreadCrumbs pageInfo={pageInfo} />{" "}
      </div>
      <div className={classes.events_title}>Event Register Details Page</div>

      {/* <div className={classes.card}>
        <div className={classes.count}>Count :</div>
        <div className={classes.count_main}>{count}</div>
      </div> */}
      {/* <div className={classes.buttons1}>
        {currentPage !== 1 ? (
          <button
            onClick={() => {
            //   setCurrentPage(currentPage - 1);
            }}
            className={classes.buttonone}
          >
            Prev
          </button>
        ) : (
          <></>
        )}
        <span>{currentPage}</span>
        {registerdetail.length > 9 ? (
          <button
            onClick={() => {
              setCurrentPage(currentPage + 1);
            }}
            className={classes.buttonone}
          >
            Next
          </button>
        ) : (
          <></>
        )}
      </div> */}
      <div className={classes.button_container}>
        <CSVLink data={newdata} filename={"Register_List.csv"} target="_blank">
          <button className={classes.button_articleList}>CSV</button>
        </CSVLink>
        <ExcelDownload csvData={newdata} fileName="Register_List" />
      </div>

      {isLoading ? (
        <div className={classes.spinner}>
          <LoadingSpinner />
        </div>
      ) : (
        <div className={classes.table_container}>
          <div className={classes.container}>
            <table className={classes.orders_table}>
              <tr className={classes.table_row}>
                <th className={classes.table_heading}>FullName</th>
                <th className={classes.table_heading}>Mobile</th>
                <th className={classes.table_heading}>Email</th>

                <th className={classes.table_heading}>Age</th>

                <th className={classes.table_heading}>Event Id</th>
                <th className={classes.table_heading}>Event Name</th>
                <th className={classes.table_heading}>Location</th>
                <th className={classes.table_heading}>Work Experience</th>
                <th className={classes.table_heading}>Qualification</th>
                <th className={classes.table_heading}>Create Date</th>
                <th className={classes.table_heading}>Update Date</th>
              </tr>

              <tbody>
                <tr className={classes.table_row}>
                  <td className={classes.table_td}>
                    {registerdetail.fullName}
                  </td>
                  <td className={classes.table_td}>{registerdetail.phone}</td>
                  <td className={classes.table_td}>{registerdetail.email}</td>

                  <td className={classes.table_td}>{registerdetail.age}</td>

                  <td className={classes.table_td}>{registerdetail.events}</td>
                  <td className={classes.table_td}>
                    {registerdetail.eventNames}
                  </td>
                  <td className={classes.table_td}>
                    {registerdetail.location}
                  </td>
                  <td className={classes.table_td}>
                    {registerdetail.workExperience}
                  </td>
                  <td className={classes.table_td}>
                    {registerdetail.qualification}
                  </td>
                  <td className={classes.table_td}>
                    {registerdetail.created_at}
                  </td>
                  <td className={classes.table_td}>
                    {registerdetail.updated_at}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default EventRegisterDetail;
