import React, { useState, useEffect, useCallback } from "react";
import LoadingSpinner from "../LoadingSpinner";
import classes from "../Quiz/Questiondetail.module.css";

import { Link, useParams } from "react-router-dom";
import { CSVLink } from "react-csv";
import ExcelDownload from "../Articles/ExcelDownload";
import Loader from "react-loader-spinner";
import TimesBreadCrumbs from "../TimesBreadCrumbs";
import Modal from "../Modal";
import { useHistory } from "react-router-dom";
import EventInputs from "../Events/EventInputs";
import debounce from 'lodash.debounce';

const Questiondetails = () => {


  const [loading, setLoading] = useState(false);
  const [quizlist, setQuizlist] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [internalErrorShow, setInternalErrorShow] = useState(false);
  const [erorrshow, setErrorShow] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const history = useHistory();
  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const [type, setType] = useState('');
  const [categoryList, setCategoryList] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  let { id } = useParams();
  useEffect(() => {
    QuizDetailApi();
  }, [currentPage]);

  const QuizDetailApi = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      QuizDataSetUUID: id,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(process.env.REACT_APP_API_URL + "/adminapi/GetQuizSetById", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code === 200) {
          setQuizlist(result.data.Questions); // Capture the Questions array
        }
      })
      .catch((error) => console.log("error", error))
      .finally(() => setLoading(false));
  };

  // const handleCheckboxChange = (QAUUID) => {
  //   setSelectedQuestions((prevSelected) => {
  //     if (prevSelected.includes(QAUUID)) {
  //       // If already selected, deselect it by filtering it out
  //       return prevSelected.filter((id) => id !== QAUUID);
  //     } else {
  //       // If not selected, add it to the selected list
  //       return [...prevSelected, QAUUID];
  //     }
  //   });
  // };

  const UpdateMovementApi = () => {
    setLoading(true);
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Token 77927b69bb144b065ca11bf2a9d452819cd852db");

    const formdata = new FormData();
    formdata.append("QuizDataSetUUID", id);

    // Append selected questions (QAUUIDs) as a comma-separated string
    const selectedQAUUIDs = selectedQuestions.join(","); // Join all selected QAUUIDs into a single string
    formdata.append("QAUUID", selectedQAUUIDs);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(global.apiLink + "/adminapi/UpdateQuizSet", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "Success" && result.status_code === 200) {
          setModalShow(true);
        } else if (result.status_code === 400 || result.status_code === 500) {
          setInternalErrorShow(true);
        } else if (result.status_code === 300) {
          setErrorShow(true);
        }
      })
      .catch((error) => console.log("error", error))
      .finally(() => setLoading(false));
  };

  const searchCategory = useCallback(debounce((term) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Token 77927b69bb144b065ca11bf2a9d452819cd852db");

    const formdata = new FormData();
    formdata.append("SearchTerm", term);
    formdata.append("Type", "All");

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow"
    };

    setLoading(true);

    fetch(global.apiLink + "/adminapi/QuizSearchAPI", requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result, "QuizListCategory");
        setCategoryList(result.data || []);
        setLoading(false);
      })
      .catch(error => {
        console.error("Error fetching data:", error);
        setCategoryList([]);
        setLoading(false);
      });
  }, 500), [type]);

  useEffect(() => {
    if (type) {
      searchCategory(""); // Trigger search with an empty term on type change
    }
  }, [type, searchCategory]);



  const handleCheckboxChang = (qaUUID) => {
    setSelectedQuestions(prevSelected => {
      if (prevSelected.includes(qaUUID)) {
        return prevSelected.filter(uuid => uuid !== qaUUID);
      }
      if (prevSelected.length < 100) {
        return [...prevSelected, qaUUID];
      }
      return prevSelected;
    });
  };

  const handleSearchChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    searchCategory(term);
  };

  const deleteApi = (QAUUID) => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      QuizDataSetUUID: id,
      QAUUID: QAUUID
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      global.apiLink + "/adminapi/DeleteSetQuistionById",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code === 200) {
          QuizDetailApi();
        }
      })
      .catch((error) => console.log("error", error));
  };


  const pageInfo = {
    currentPageName: "Quiz Detail Page",
    previousPageName: "QuizList",
    previousPage: "/BiDashboard/QuizList",
  };
  return (
    <>
      {modalShow && (
        <Modal
          heading={"Data Updated successfully"}
          onClick={() => history.push("/BiDashboard/QuizList")}
        />
      )}
      {erorrshow && <Modal heading={"Required Fields Missing"} onClick={() => setErrorShow(false)} />}
      {internalErrorShow && <Modal heading={"Internal server error"} onClick={() => setInternalErrorShow(false)} />}

      <div className={classes.dashboard_container}>
        <div className={classes.TimsbiBreadcrumb}>
          <TimesBreadCrumbs pageInfo={pageInfo} />{" "}
        </div>
        <div className={classes.DateFilter_container}>
          <div className={classes.button_container}>
            <CSVLink
              data={quizlist ? quizlist : ""}
              filename={"/AddWebstorySummary.csv"}
              target="_blank"
            >
              <button className={classes.button_articleList}>CSV</button>
            </CSVLink>
            <ExcelDownload
              csvData={quizlist}
              fileName="/AddWebstorySummary"
            />
          </div>
        </div>
        <div className={classes.main_table}>
          <EventInputs
            input={"SingleLineInput"} // Change input to text for a search box
            label={"Search Quiz Question"}
            placeholder={"Type to search..."}
            onChange={handleSearchChange}
            displayData={categoryList}
            value={searchTerm}
          />


          {categoryList.length > 0 && (
            <div className={classes.table_div}>
              <table>
                <thead>
                  <tr>
                    <td>Sr No.</td>
                    <td>Select</td>
                    <td>Question Name</td>
                    <td>Correct Answer</td>
                    <td>QuizUUID</td>
                  </tr>
                </thead>
                <tbody>
                  {categoryList.map((item, index) => (
                    <tr key={item.QAUUID}>
                      <td>{index + 1}</td>
                      <td>
                        <input
                          type="checkbox"
                          checked={selectedQuestions.includes(item.QAUUID)}
                          onChange={() => handleCheckboxChang(item.QAUUID)}
                          disabled={
                            !selectedQuestions.includes(item.QAUUID) &&
                            selectedQuestions.length >= 100
                          }
                        />
                      </td>
                      <td>{item.Question}</td>
                      <td>{item.CorrectAnswer}</td>
                      <td>{item.QuizUUID || item.QAUUID}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className={classes.center}>
                {loading && (
                  <Loader type="TailSpin" color="#00BFFF" height={80} width={80} />
                )}
              </div>
              <div>
                {selectedQuestions.length >= 100 && (
                  <p>You can only select up to 100 questions.</p>
                )}
              </div>
            </div>
          )}
          {categoryList.length === 0 && !loading && (
            <p>No data found</p>
          )}
          <button onClick={UpdateMovementApi} className={classes.updateButton}>
            Update Selected Questions
          </button>
        </div>
        {loading ? (
          <div className={classes.spinner}>
            <LoadingSpinner />
          </div>
        ) : (
          <div className={classes.table_container}>
            <div className={classes.buttons1}>
              <div>
                {currentPage !== 1 ? (
                  <button
                    onClick={() => setCurrentPage(currentPage - 1)}
                    className={classes.buttonone}
                  >
                    Prev
                  </button>
                ) : null}
                <span>{quizlist?.length < 20 ? null : currentPage}</span>
                {quizlist?.length < 20 ? null : (
                  <button
                    onClick={() => setCurrentPage(currentPage + 1)}
                    className={classes.buttonone}
                  >
                    Next
                  </button>
                )}
              </div>
            </div>
            <div className={classes.container}>
              {quizlist?.length > 0 ? (
                <table className={classes.orders_table}>
                  <thead>
                    <tr className={classes.table_row}>
                      <th className={classes.table_heading}>Id</th>
                      <th className={classes.table_heading}>Category Name</th>
                      <th className={classes.table_heading}>Question</th>

                      <th className={classes.table_heading}>Correct Answer</th>
                      <th className={classes.table_heading}>Explanation</th>

                      <th className={classes.table_heading}>Delete</th>

                    </tr>
                  </thead>
                  <tbody>
                    {quizlist?.map((item) => (
                      <tr className={classes.table_row} key={item.QAUUID}>
                        <td className={classes.table_td}>{item.Id}</td>
                        <td className={classes.table_td}>{item.QACategoryName}</td>
                        <td className={classes.table_td}>{item.Question}</td>

                        <td className={classes.table_td}>{item.CorrectAnswer}</td>
                        <td className={classes.table_td}>{item.Explanation}</td>

                        <td className={classes.table_td}>
                          <button
                            className={classes.delbtn}
                            onClick={() => deleteApi(item.QAUUID)}
                          >
                            {loading ? (
                              <Loader
                                type="TailSpin"
                                color="white"
                                height={20}
                                width={50}
                                className="text-center"
                              />
                            ) : (
                              "Delete"
                            )}
                          </button>
                        </td>

                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <div className={classes.No_data}>No data found!</div>
              )}
            </div>
          </div>

        )}



      </div>

    </>
  );
};

export default Questiondetails;
