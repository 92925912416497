import React, { useEffect, useState } from "react";
import LoadingSpinner from "../Components/LoadingSpinner";
import classes from "./PostAJobs.module.css";
import Loader from "react-loader-spinner";
import { AiOutlineArrowUp } from "react-icons/ai";
import { AiOutlineArrowDown } from "react-icons/ai";

const PostAJobs = () => {
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [bloading, setBLoading] = useState(false);
  const [sloading, setSLoading] = useState(false);
  const [passLoader, setPassLoader] = useState();
  const [orderApproveLoading, setOrderApproveLoading] = useState([]);
  const [orderRejectLoading, setOrderRejectLoading] = useState([]);
  const [issno, setIssno] = useState("ASC");
  const [issub, setIsSub] = useState("ASC");
  const [mobile, setMobile] = useState("ASC");
  const [isemail, setIsemail] = useState("ASC");
  useEffect(() => {
    GetPostAJobDetails();
  }, []);

  const GetPostAJobDetails = () => {
    setBLoading(true);
    setSLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    var formdata = new FormData();
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };
    fetch(global.apiLink + "/v1/api/apiTimes/SecondgetAllJobs", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setData(result.data);
        console.log(result.data);
        setLoading(false);
      })

      .catch((error) => console.log("error", error))
      .finally(() => {
        setSLoading(false);
        setBLoading(false);
      });
  };

  const DeleteOrderLoading = (id, status) => {
    switch (status) {
      case "approved":
        let index = orderApproveLoading.indexOf(id);
        setOrderApproveLoading(orderApproveLoading.splice(index, 1));
        break;
      case "cancel":
        let idx = orderRejectLoading.indexOf(id);
        setOrderRejectLoading(orderRejectLoading.splice(idx, 1));
        break;
      default:
        console.log("status not found in Post A Job");
        break;
    }
  };

  const AprooveApiNew = (paperad_id, status) => {
    if (status === "approved")
      setOrderApproveLoading([...orderApproveLoading, paperad_id]);

    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      jobId: paperad_id,
      useremail: sessionStorage.getItem("googleBiEmail"),
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_API_URL + "/adminapi/approvePostajob",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code === 200) {
          GetPostAJobDetails();
        }
      })
      .catch((error) => console.log("error", error))
      .finally(() => {
        DeleteOrderLoading(paperad_id, status);
      });
  };

  const RejectApi = (paperad_id, status) => {
    if (status === "cancel")
      setOrderRejectLoading([...orderRejectLoading, paperad_id]);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      jobId: paperad_id,
      useremail: sessionStorage.getItem("googleBiEmail"),
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_API_URL + "/adminapi/rejectPostajob",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code === 200) {
          GetPostAJobDetails();
        }
      })
      .catch((error) => console.log("error", error))
      .finally(() => {
        DeleteOrderLoading(paperad_id, status);
      });
  };

  const sortingsrno = (col) => {
    if (issno === "ASC") {
      const sortsno = [...data].sort((a, b) => (a[col] > b[col] ? 1 : -1));
      setData(sortsno);
      setIssno("DSC");
    }
    if (issno === "DSC") {
      const sortsno = [...data].sort((a, b) => (a[col] < b[col] ? 1 : -1));
      setData(sortsno);
      setIssno("ASC");
    }
  };

  return (
    <div className={classes.dashboard_container}>
      {isLoading ? (
        <div className={classes.spinner}>
          <LoadingSpinner />
        </div>
      ) : (
        <div className={classes.table_container}>
          <div className={classes.container}>
            <table className={classes.orders_table}>
              <tr className={classes.table_row}>
                <th className={classes.table_heading}>Id</th>
                <th className={classes.table_heading}>
                  Job Id
                  {issno === "ASC" ? (
                    <span
                      className={classes.arrow}
                      onClick={() => sortingsrno("Job Id")}
                    >
                      <AiOutlineArrowDown />
                    </span>
                  ) : (
                    <span
                      className={classes.arrow}
                      onClick={() => sortingsrno("Job Id")}
                    >
                      <AiOutlineArrowUp />
                    </span>
                  )}
                </th>
                <th className={classes.table_heading}>Paperad Id</th>
                <th className={classes.table_heading}>Recruiter Id</th>
                <th className={classes.table_heading}>Company name</th>
                <th className={classes.table_heading}>Contact Number</th>
                <th className={classes.table_heading}>Contact Email</th>
                <th className={classes.table_heading}>Location</th>
                <th className={classes.table_heading}>Position</th>
                <th className={classes.table_heading}>Job Type</th>
                <th className={classes.table_heading}>Status</th>
                <th className={classes.table_heading}>Number of Vacancy</th>
                <th className={classes.table_heading}>Walk In Interview</th>
                <th className={classes.table_heading}>Category</th>
                <th className={classes.table_heading}>Approve</th>
                <th className={classes.table_heading}>Reject/Cancel</th>
              </tr>
              <tbody>
                {data.map((item, index) => {
                  return isLoading ? (
                    <div className={classes.spinner}>
                      <LoadingSpinner />
                    </div>
                  ) : (
                    <tr className={classes.table_row} key={index}>
                      <td className={classes.table_td}>{item.id}</td>
                      <td className={classes.table_td}>{item.JobId}</td>
                      <td className={classes.table_td}>{item.paperad_id}</td>
                      <td className={classes.table_td}>{item.recruiterId}</td>
                      <td className={classes.table_td}>{item.CompanyName}</td>
                      <td className={classes.table_td}>{item.ContactPerson}</td>
                      <td className={classes.table_td}>{item.Contactmail}</td>
                      <th className={classes.table_td}>{item.Location}</th>
                      <td className={classes.table_td}>{item.Position}</td>
                      <td className={classes.table_td}>{item.JobType}</td>
                      <td className={classes.table_td_approve}>
                        {item.status === "" ? "Approved" : item.status}
                      </td>
                      <td className={classes.table_td}>
                        {item.numberOfVacancy}
                      </td>
                      <td className={classes.table_td}>
                        {item.WalkIninterview}
                      </td>
                      <td className={classes.table_td}>{item.Category}</td>
                      <td className={classes.table_td}>
                        {" "}
                        {item.status === "" ? (
                          "Approved"
                        ) : (
                          <button
                            onClick={() =>
                              AprooveApiNew(item.paperad_id, "approved")
                            }
                            className={classes.approve_button}
                          >
                            {orderApproveLoading.indexOf(item.paperad_id) !=
                              -1 ? (
                              <Loader
                                type="TailSpin"
                                color="white"
                                height={15}
                                width={50}
                              />
                            ) : (
                              <>Approve</>
                            )}
                          </button>
                        )}
                      </td>

                      <td className={classes.table_td}>
                        {item.status === "" ? (
                          <button
                            onClick={() => RejectApi(item.paperad_id, "cancel")}
                            className={classes.reject_button}
                          >
                            {orderRejectLoading.indexOf(item.paperad_id) !=
                              -1 ? (
                              <Loader
                                type="TailSpin"
                                color="white"
                                height={15}
                                width={50}
                              />
                            ) : (
                              <>Reject</>
                            )}
                          </button>
                        ) : (
                          <div>Rejected</div>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default PostAJobs;
