import React, { useState, useEffect } from "react";
import LoadingSpinner from "../../Components/LoadingSpinner";
import classes from "../WeCare/wecarelist.module.css";

import { Link } from "react-router-dom";
import { CSVLink } from "react-csv";
import ExcelDownload from "../Articles/ExcelDownload";
import Loader from "react-loader-spinner";
import TimesBreadCrumbs from "../TimesBreadCrumbs";
import Modal from "../../Components/Modal";
import { useHistory } from "react-router-dom";
const Wecarelist = () => {
  let today = new Date();
  today.setDate(today.getDate() - 60);
  let date = new Date(today)
    .toLocaleDateString("in", {
      day: "2-digit",
      year: "numeric",
      month: "2-digit",
    })
    .split("/")
    .reverse()
    .join("-");

  const [loading, setLoading] = useState(false);
  const [wecarelist, setWecarelist] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [startDate, setStartDate] = useState(date);
  const [endDate, setEndDate] = useState(
    new Date()
      .toLocaleDateString("in", {
        day: "2-digit",
        year: "numeric",
        month: "2-digit",
      })
      .split("/")
      .reverse()
      .join("-")
  );
  const [errors, setErrors] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [datacount, setdataCount] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [livecount, setlivecount] = useState([]);
  const [unlivecount, setunlivecount] = useState([]);
  const history = useHistory();
  useEffect(() => {
    WecarelistApi();
  }, [currentPage]);

  const WecarelistApi = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      StartDate: startDate || date,
      EndDate:
        endDate ||
        new Date()
          .toLocaleDateString("in", {
            day: "2-digit",
            year: "numeric",
            month: "2-digit",
          })
          .split("/")
          .reverse()
          .join("-"),
      Page: currentPage,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_API_URL + "/adminapi/WeCareList",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code === 200) {
          setWecarelist(result.data);
          setFilteredData(result.data);
          setdataCount(result.datacount);
          setlivecount(result.live_count);
          setunlivecount(result.unlive_count);
        }
      })
      .catch((error) => console.log("error", error))
      .finally(() => setLoading(false));
  };

  const DeleteApi = (WeCareID) => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      WeCareID: WeCareID,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_API_URL + "/adminapi/DeleteWeCare",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code === 200) {
          WecarelistApi();
        }
      })
      .catch((error) => console.log("error", error));
  };
  const formValidation = () => {
    let isValid = true;
    let error = {};

    if (!startDate || !endDate) {
      isValid = false;
      error.startDate = "Please Select Both Dates";
    }

    setErrors(error);
    return isValid;
  };

  const pageInfo = {
    currentPageName: "Corporates Care List",
  };
  const filterdata = (IsLive) => {
    if (IsLive === "1") {
      const filtereddata = wecarelist.filter((item) => {
        if (item.IsLive === "1") {
          return item;
        }
      });
      setFilteredData(filtereddata);
    }
  };

  const filterunlive = (IsLive) => {
    if (IsLive === "0") {
      const unlivedata = wecarelist.filter((item) => {
        if (item.IsLive === "0") {
          return item;
        }
      });
      setFilteredData(unlivedata);
    }
  };
  return (
    <>
      {modalShow && (
        <Modal
          heading={"Data Updated successfully"}
          onClick={() => history.push("/BiDashboard/WeCareList")}
        />
      )}
      <div className={classes.dashboard_container}>
        <div className={classes.TimsbiBreadcrumb}>
          <TimesBreadCrumbs pageInfo={pageInfo} />{" "}
        </div>
        <div className={classes.DateFilter_container}>
          <div className={classes.button_container}>
            <CSVLink
              data={wecarelist}
              filename={"WecareList.csv"}
              target="_blank"
            >
              <button className={classes.button_articleList}>CSV</button>
            </CSVLink>
            <ExcelDownload csvData={wecarelist} fileName="WecareList" />
          </div>
          <div className={classes.dateDiv}>
            <div>
              <label className={classes.dateLable}>Start Date</label>
              <input
                type="date"
                value={startDate || date}
                onChange={(e) => setStartDate(e.target.value)}
              />
              <label className={classes.dateLable}>End Date</label>
              <input
                type="date"
                value={
                  endDate ||
                  new Date()
                    .toLocaleDateString("in", {
                      day: "2-digit",
                      year: "numeric",
                      month: "2-digit",
                    })
                    .split("/")
                    .reverse()
                    .join("-")
                }
                onChange={(e) => setEndDate(e.target.value)}
              />
            </div>
            <div className={classes.form_error}>{errors.startDate}</div>
            <div className={classes.buttons}>
              <button
                type="button"
                className="button"
                onClick={() =>
                  formValidation()
                    ? WecarelistApi()
                    : console.log("Something Wrong")
                }
              >
                {" "}
                Search
              </button>
            </div>
          </div>
        </div>
        {loading ? (
          <div className={classes.spinner}>
            <LoadingSpinner />
          </div>
        ) : (
          <div className={classes.table_container}>
            <div className={classes.buttons1}>
              <div>
                {currentPage !== 1 ? (
                  <button
                    onClick={() => {
                      setCurrentPage(currentPage - 1);
                    }}
                    className={classes.buttonone}
                  >
                    Prev
                  </button>
                ) : (
                  <></>
                )}
                <span>{wecarelist.length < 20 ? <></> : currentPage}</span>
                {wecarelist.length < 20 ? (
                  <></>
                ) : (
                  <button
                    onClick={() => {
                      setCurrentPage(currentPage + 1);
                    }}
                    className={classes.buttonone}
                  >
                    Next
                  </button>
                )}
              </div>
              <div>
                <button
                  onClick={() => {
                    filterdata("1");
                  }}
                >
                  Live
                </button>

                <button
                  onClick={() => {
                    filterunlive("0");
                  }}
                >
                  Unlive
                </button>

                <button
                  onClick={() => {
                    WecarelistApi();
                  }}
                >
                  All
                </button>
              </div>

              <div className={classes.datacount}>Count : {datacount}</div>
              <div className={classes.livecount}>Live Count : {livecount}</div>
              <div className={classes.unlivecount}>
                Unlive Count : {unlivecount}
              </div>
              <div>
                <button className={classes.Addbtn1}>
                  <Link
                    to="/BiDashboard/Addcorporates-care"
                    className={classes.Edit_link}
                  >
                    Add Corporates Care
                  </Link>
                </button>
                <button className={classes.Addbtn}>
                  <Link
                    to="/BiDashboard/NewAddCorporate"
                    className={classes.Edit_link}
                  >
                    Add New Corporates Care
                  </Link>
                </button>
              </div>
            </div>

            <div className={classes.container}>
              {wecarelist.length > 0 ? (
                <table className={classes.orders_table}>
                  <tr className={classes.table_row}>
                    <th className={classes.table_heading}>Id</th>
                    <th className={classes.table_heading}>Company Logo</th>

                    <th className={classes.table_heading}>Author Name</th>
                    <th className={classes.table_heading}>Organisation Name</th>
                    <th className={classes.table_heading}>Activity Name</th>
                    <th className={classes.table_heading}>
                      Activity Description
                    </th>

                    <th className={classes.table_heading}>Company Sector</th>
                    <th className={classes.table_heading}>Company Website</th>

                    <th className={classes.table_heading}>Event Date</th>
                    <th className={classes.table_heading}>Event Location</th>
                    <th className={classes.table_heading}>Created At</th>
                    <th className={classes.table_heading}>Updated At</th>
                    <th className={classes.table_heading}>Status</th>

                    <th className={classes.table_heading}>Update</th>

                    <th className={classes.table_heading}>View</th>
                    <th className={classes.table_heading}>Delete</th>
                  </tr>

                  <tbody>
                    {filteredData.map((item, value) => {
                      return (
                        <tr className={classes.table_row}>
                          <td className={classes.table_td}>{item.ID}</td>
                          <td className={classes.table_td}>
                            <img
                              src={item.CompanyLogo}
                              alt="icon"
                              className={classes.IconImage}
                            />
                          </td>

                          <td className={classes.table_td}>
                            {item.AuthorName === "" ? "NA" : item.AuthorName}
                          </td>
                          <td className={classes.table_td}>
                            {item.OrganisationName}
                          </td>
                          <td className={classes.table_td}>
                            {item.ActivityName}
                          </td>
                          <td className={classes.table_td}>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: item?.ActivityDescription?.slice(
                                  0,
                                  110
                                ),
                              }}
                            />
                          </td>
                          <td className={classes.table_td}>
                            {item.CompanySector === ""
                              ? "NA"
                              : item.CompanySector}
                          </td>
                          <td className={classes.table_td}>
                            <a
                              href={item.CompanyWebsite}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {" "}
                              WebsiteUrl
                            </a>
                          </td>
                          <td className={classes.table_td}>{item.EventDate}</td>
                          <td className={classes.table_td}>
                            {item.EventLocation === ""
                              ? "NA"
                              : item.EventLocation}
                          </td>

                          <td className={classes.table_td}>{item.CreatedAt}</td>
                          <td className={classes.table_td}>{item.UpdatedAt}</td>
                          <td className={classes.table_td}>
                            {item.IsLive === "0" ? (
                              <span className={classes.isnotLive}>
                                Not Live
                              </span>
                            ) : (
                              <span className={classes.isLive}>Live</span>
                            )}
                          </td>
                          <td className={classes.table_td}>
                            <Link
                              to={
                                "/BiDashboard/Editcorporates-care/" +
                                item.WeCareID
                              }
                              className={classes.Edit_link_two}
                            >
                              Edit
                            </Link>
                          </td>
                          <td className={classes.table_td}>
                            <Link
                              to={"/BiDashboard/ViewDetail/" + item.WeCareID}
                              className={classes.Edit_link_two}
                            >
                              View
                            </Link>
                          </td>
                          <td className={classes.table_td}>
                            <button
                              className={classes.delbtn}
                              onClick={() => DeleteApi(item.WeCareID)}
                            >
                              {loading ? (
                                <Loader
                                  type="TailSpin"
                                  color="white"
                                  height={20}
                                  width={50}
                                  className="text-center"
                                />
                              ) : (
                                "Delete"
                              )}
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              ) : (
                <div className={classes.No_data}>No data found!</div>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Wecarelist;
