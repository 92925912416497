import React, { useState, useEffect } from "react";
import LoadingSpinner from "../Components/LoadingSpinner";
import classes from "./easyapplyjobdetail.module.css";
import { CSVLink } from "react-csv";
import ExcelDownload from "../Components/Articles/ExcelDownload";
import { Link, useParams } from "react-router-dom";
import TimesBreadCrumbs from "../Components/TimesBreadCrumbs";
import { AiOutlineArrowUp } from "react-icons/ai";
import { AiOutlineArrowDown } from "react-icons/ai";

const EasyApplyThroghId = () => {
  const [isLoading, setLoading] = useState(false);
  const [jobdetailtdata, setJobDetaildata] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [firstdate, setFirstDate] = useState("");
  const [lastdate, setLastDate] = useState("");
  const [count, setCount] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const currentURL = window.location.href.split("/").pop();
  const [errors, setErrors] = useState(false);
  console.log("currentURL", currentURL);
  const [order, setOrder] = useState("ASC");
  const [company, setCompany] = useState("ASC");
  const [comapnyid, setCompanyId] = useState("ASC");
  const [buttonclick, setButtonclick] = useState("ASC");
  const [positionTitle, setPositionTitle] = useState("ASC");

  const date = localStorage.getItem("date");

  const compId = localStorage.setItem("idjob", currentURL);

  console.log("compi", compId);
  useEffect(() => {
    JobdetailApi();
  }, [currentPage]);

  const JobdetailApi = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      CompanyId: currentURL,
      startDate: startDate,
      endDate: endDate,
      Page: currentPage,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_API_URL + "/adminapi/getJobThroughIdEasyApply",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setJobDetaildata(result.data);
        setCount(result.totalCount);
        setFirstDate(result.startdate);
        setLastDate(result.endDate);
      })
      .catch((error) => console.log("error", error))
      .finally(() => setLoading(false));
  };

  const formValidation = () => {
    let isValid = true;
    let error = {};

    if (!startDate || !endDate) {
      isValid = false;
      error.startDate = "Please Select Both Dates";
    }

    setErrors(error);
    return isValid;
  };
  const pageInfo = {
    currentPageName: "Easy Apply Jobs",
    previousPageName: "Easy Apply Companies List",
    previousPage: "/BiDashboard/EasyApplyJobs/" + date,
  };
  const sorting = (col) => {
    if (order === "ASC") {
      const sorted = [...jobdetailtdata].sort((a, b) =>
        a[col] > b[col] ? 1 : -1
      );
      setJobDetaildata(sorted);
      setOrder("DSC");
    }
    if (order === "DSC") {
      const sorted = [...jobdetailtdata].sort((a, b) =>
        a[col] < b[col] ? 1 : -1
      );
      setJobDetaildata(sorted);
      setOrder("ASC");
    }
  };
  const sortingposition = (col) => {
    if (positionTitle === "ASC") {
      const sortedposition = [...jobdetailtdata].sort((a, b) =>
        a[col] > b[col] ? 1 : -1
      );
      setJobDetaildata(sortedposition);
      setPositionTitle("DSC");
    }
    if (positionTitle === "DSC") {
      const sortedposition = [...jobdetailtdata].sort((a, b) =>
        a[col] < b[col] ? 1 : -1
      );
      setJobDetaildata(sortedposition);
      setPositionTitle("ASC");
    }
  };
  const sortingComapny = (col) => {
    if (company === "ASC") {
      const sortedcompany = [...jobdetailtdata].sort((a, b) =>
        a[col] > b[col] ? 1 : -1
      );
      setJobDetaildata(sortedcompany);
      setCompany("DSC");
    }
    if (company === "DSC") {
      const sortedcompany = [...jobdetailtdata].sort((a, b) =>
        a[col] < b[col] ? 1 : -1
      );
      setJobDetaildata(sortedcompany);
      setCompany("ASC");
    }
  };
  const sortingButtonclick = (col) => {
    if (buttonclick === "ASC") {
      const sortedbutton = [...jobdetailtdata].sort((a, b) =>
        a[col] > b[col] ? 1 : -1
      );
      setJobDetaildata(sortedbutton);
      setButtonclick("DSC");
    }
    if (buttonclick === "DSC") {
      const sortedbutton = [...jobdetailtdata].sort((a, b) =>
        a[col] < b[col] ? 1 : -1
      );
      setJobDetaildata(sortedbutton);
      setButtonclick("ASC");
    }
  };
  return (
    <div className={classes.dashboard_container}>
      <div className={classes.TimsbiBreadcrumb}>
        {" "}
        <TimesBreadCrumbs pageInfo={pageInfo} />{" "}
      </div>
      <div className={classes.events_title}>Easy Apply Companies</div>
      <div className={classes.DateFilter_container}>
        <div className={classes.button_container}>
          <CSVLink data={jobdetailtdata} filename={"jobs.csv"} target="_blank">
            <button className={classes.button_articleList}>CSV</button>
          </CSVLink>
          <ExcelDownload csvData={jobdetailtdata} fileName="jobs" />
        </div>
        <div className={classes.dateDiv}>
          <div>
            <label className={classes.dateLable}>Start Date</label>
            <input
              type="date"
              value={startDate || firstdate}
              onChange={(e) => setStartDate(e.target.value)}
            />
            <label className={classes.dateLable}>End Date</label>
            <input
              type="date"
              value={endDate || lastdate}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </div>
          <div className={classes.form_error}>{errors.startDate}</div>
          <div className={classes.buttons}>
            <button
              type="button"
              className="button"
              onClick={() =>
                formValidation()
                  ? JobdetailApi()
                  : console.log("Something Wrong")
              }
            >
              {" "}
              Search
            </button>
          </div>
        </div>
      </div>
      <div className={classes.card}>
        <div className={classes.count}>Count :</div>
        <div className={classes.count_main}>{count}</div>
      </div>
      {count > 10 ? (
        <div className={classes.buttons1}>
          {currentPage !== 1 ? (
            <button
              onClick={() => {
                setCurrentPage(currentPage - 1);
              }}
              className={classes.buttonone}
            >
              Prev
            </button>
          ) : (
            <></>
          )}
          <span>{currentPage}</span>
          {jobdetailtdata.length > 9 ? (
            <>
              <button
                onClick={() => {
                  setCurrentPage(currentPage + 1);
                }}
                className={classes.buttonone}
              >
                Next
              </button>
            </>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <></>
      )}
      {isLoading ? (
        <div className={classes.spinner}>
          <LoadingSpinner />
        </div>
      ) : (
        <div className={classes.table_container}>
          <div className={classes.container}>
            {jobdetailtdata.length > 0 ? (
              <table className={classes.orders_table}>
                <tr className={classes.table_row}>
                  <th className={classes.table_heading}>Sno</th>
                  <th className={classes.table_heading}>
                    Company Name
                    {company === "ASC" ? (
                      <span
                        className={classes.arrow}
                        onClick={() => sortingComapny("name")}
                      >
                        {" "}
                        <AiOutlineArrowDown />{" "}
                      </span>
                    ) : (
                      <span
                        className={classes.arrow}
                        onClick={() => sortingComapny("name")}
                      >
                        <AiOutlineArrowUp />
                      </span>
                    )}
                  </th>
                  <th className={classes.table_heading}>
                    Position Title
                    {positionTitle === "ASC" ? (
                      <span
                        className={classes.arrow}
                        onClick={() => sortingposition("positionTitle")}
                      >
                        {" "}
                        <AiOutlineArrowDown />{" "}
                      </span>
                    ) : (
                      <span
                        className={classes.arrow}
                        onClick={() => sortingposition("positionTitle")}
                      >
                        <AiOutlineArrowUp />
                      </span>
                    )}
                  </th>
                  <th className={classes.table_heading}>Date</th>
                  <th className={classes.table_heading}>
                    Page View
                    {order === "ASC" ? (
                      <span
                        className={classes.arrow}
                        onClick={() => sorting("pageview")}
                      >
                        {" "}
                        <AiOutlineArrowDown />
                      </span>
                    ) : (
                      <span
                        className={classes.arrow}
                        onClick={() => sorting("pageview")}
                      >
                        <AiOutlineArrowUp />
                      </span>
                    )}
                  </th>
                  <th className={classes.table_heading}>
                    button Click
                    {buttonclick === "ASC" ? (
                      <span
                        className={classes.arrow}
                        onClick={() => sortingButtonclick("buttonClick")}
                      >
                        {" "}
                        <AiOutlineArrowDown />
                      </span>
                    ) : (
                      <span
                        className={classes.arrow}
                        onClick={() => sortingButtonclick("buttonClick")}
                      >
                        <AiOutlineArrowUp />
                      </span>
                    )}
                  </th>
                  <th className={classes.table_heading}>View Job Detail</th>
                </tr>

                <tbody>
                  {jobdetailtdata.map((item, value) => {
                    return (
                      <tr className={classes.table_row}>
                        <td className={classes.table_td}>{item.sno}</td>
                        <td className={classes.table_td}>{item.name}</td>
                        <td className={classes.table_td}>
                          {item.positionTitle}
                        </td>
                        <td className={classes.table_td}>{item.date}</td>
                        <td className={classes.table_td}>{item.pageview}</td>
                        <td className={classes.table_td}>{item.buttonClick}</td>
                        <td className={classes.table_td}>
                          <Link
                            to={
                              "/BiDashboard/EasyApplyJobsDetail/" + item.jobid
                            }
                          >
                            View Job Detail
                          </Link>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            ) : (
              <div className={classes.No_data}>No data found!</div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default EasyApplyThroghId;
