import React, { useState, useEffect } from "react";
import classes from "../WeCare/viewdetail.module.css";
import { useParams } from "react-router-dom";

const ViewDetail = () => {
  const { id } = useParams();

  const [updateDate, setUpdateDate] = useState("");

  const [orgname, setOrgname] = useState("");
  const [complogo, setComplogo] = useState("");
  const [compweb, setCompweb] = useState("");
  const [companySector, setCompanySector] = useState("");
  const [activityName, setActivityName] = useState("");
  const [activityDescription, setActivityDescription] = useState("");
  const [eventLocation, setEventLocation] = useState("");
  const [eventdate, setEventdate] = useState("");
  const [images, setImages] = useState([]);
  const [live, setLive] = useState("");
  const [createDate, setCreateDate] = useState("");
  const [author, setAuthor] = useState("");

  useEffect(() => {
    getDetailApi();
  }, []);

  const getDetailApi = () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      WeCareID: id,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_API_URL + "/adminapi/WeCareDetail",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setOrgname(result.data.OrganisationName);
        setComplogo(result.data.CompanyLogo);

        setCompweb(result.data.CompanyWebsite);

        setCompanySector(result.data.CompanySector);
        setActivityName(result.data.ActivityName);
        setActivityDescription(result.data.ActivityDescription);

        setEventLocation(result.data.EventLocation);
        setEventdate(result.data.EventDate);
        setImages(result.data.Images);
        setLive(result.data.IsLive);

        setCreateDate(result.data.CreatedAt);
        setUpdateDate(result.data.UpdatedAt);
        setAuthor(result.data.AuthorName);
      })
      .catch((error) => console.log("error", error));
  };

  return (
    <>
      <div className={classes.NewArticle}>
        <h4 className={classes.ArticleHeading}>We Care ID : {id}</h4>
        <form>
          <div className={classes.FormContainer}>
            <div className={classes.leftSide}>
              <div className={classes.formGroup}>
                {/* <label htmlFor="title">Title : </label>
                            <input type="text" id="title" name="title" placeholder="Title" value={title} /> */}
                <p className={classes.artical_date}>
                  Organisation Name : {orgname}
                </p>
              </div>
              <div className={classes.formGroup}>
                <p className={classes.artical_date}>Company Logo :</p>
                <div>
                  {" "}
                  <img
                    src={complogo}
                    className={classes.articleImage}
                    alt="Thumbnailimage"
                  />
                </div>
              </div>

              <div>
                <p className={classes.artical_date}>
                  Company Website :{" "}
                  <a
                    href={compweb}
                    target="_blank"
                    alt="WECAREDETAIL"
                    rel="noreferrer"
                  >
                    {" "}
                    WebsiteUrl
                  </a>
                </p>
                <p className={classes.artical_date}>
                  Company Sector : {companySector === "" ? "NA" : companySector}
                </p>
              </div>

              <div>
                <p className={classes.artical_date}>
                  Activity Name : {activityName}
                </p>
              </div>
              <div>
                <p className={classes.artical_date}>
                  Event Location : {eventLocation === "" ? "NA" : eventLocation}
                </p>
              </div>
              <div>
                <p className={classes.artical_date}>
                  Author Name : {author === "" ? "NA" : author}
                </p>
              </div>
              <div>
                <p className={classes.artical_date}>Event Date : {eventdate}</p>
              </div>

              <div className={classes.infoheading}>
                {" "}
                Activity Description :{" "}
              </div>
              <div className={classes.artical_dat}>
                <div
                  dangerouslySetInnerHTML={{ __html: activityDescription }}
                />
              </div>
            </div>
            <div className={classes.rightSide}>
              <h5 className={classes.otherDetailHeading}>Other Details</h5>
              <div className={classes.formGroup}>
                <p className={classes.artical_date}>
                  Status : {live === "1" ? "Live" : "NotLive"}
                </p>
                <p className={classes.artical_date}>
                  Create Date : {createDate.slice(0, 10)}{" "}
                </p>
                <p className={classes.artical_date}>
                  Update Date : {updateDate.slice(0, 10)}{" "}
                </p>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default ViewDetail;
