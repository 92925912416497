import React, { useState, useEffect } from "react";
import LoadingSpinner from "../LoadingSpinner";
import classes from "./forherPostList.module.css";
import { AiOutlineArrowUp } from "react-icons/ai";
import { AiOutlineArrowDown } from "react-icons/ai";
import { Link } from "react-router-dom";
import { CSVLink } from "react-csv";
import ExcelDownload from "../Articles/ExcelDownload";
import Loader from "react-loader-spinner";
import TimesBreadCrumbs from "../TimesBreadCrumbs";

const ForherPostList = () => {
  const [loading, setLoading] = useState(false);
  const [guestlist, setGuestlist] = useState([]);
  const [guestalllist, setGuestalllist] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [errors, setErrors] = useState(false);
  const [count, setCount] = useState("");
  const [issno, setIssno] = useState("ASC");
  const [selectedPost, setSelectedPost] = useState("forherevents");
  useEffect(() => {
    GuestListAllApi();
  }, [currentPage, selectedPost]);

  const GuestListAllApi = () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({ PageName: selectedPost });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_API_URL + "/v1/admin1_1/ForherpageList",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code === 200) {
          console.log(result, "jjjjj");
          setGuestalllist(result.data);
        }
      })
      .catch((error) => console.log("error", error));
  };

  // const DeleteApi = (id) => {
  //   var myHeaders = new Headers();
  //   myHeaders.append(
  //     "Authorization",
  //     "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
  //   );
  //   myHeaders.append("Content-Type", "application/json");

  //   var raw = JSON.stringify({
  //     Id: id,
  //     IsDeleted: "1",
  //   });

  //   var requestOptions = {
  //     method: "POST",
  //     headers: myHeaders,
  //     body: raw,
  //     redirect: "follow",
  //   };

  //   fetch(
  //     process.env.REACT_APP_API_URL + "/adminapi/DeleteForHerArticles",
  //     requestOptions
  //   )
  //     .then((response) => response.json())
  //     .then((result) => {
  //       if (result.status_code === 200) {
  //         // GuestListApi();
  //       }
  //     })
  //     .catch((error) => console.log("error", error));
  // };
  const formValidation = () => {
    let isValid = true;
    let error = {};

    if (!startDate || !endDate) {
      isValid = false;
      error.startDate = "Please Select Both Dates";
    }

    setErrors(error);
    return isValid;
  };

  let today = new Date();
  today.setDate(today.getDate() - 180);
  let date = new Date(today)
    .toLocaleDateString("in", {
      day: "2-digit",
      year: "numeric",
      month: "2-digit",
    })
    .split("/")
    .reverse()
    .join("-");

  const pageInfo = {
    currentPageName: "Guest Aricle List",
  };
  const sortingsrno = (col) => {
    if (issno === "ASC") {
      const sortsno = [...guestlist].sort((a, b) => (a[col] > b[col] ? 1 : -1));
      setGuestlist(sortsno);
      setIssno("DSC");
    }
    if (issno === "DSC") {
      const sortsno = [...guestlist].sort((a, b) => (a[col] < b[col] ? 1 : -1));
      setGuestlist(sortsno);
      setIssno("ASC");
    }
  };

  const dropdownList = [
    {
      id: 1,
      type: "forherevents",
    },
    {
      id: 2,
      type: "forhercourses",
    },
    {
      id: 3,
      type: "forherjobs",
    },
  ];
  return (
    <>
      <div className={classes.dashboard_container}>
        <div className={classes.TimsbiBreadcrumb}>
          <TimesBreadCrumbs pageInfo={pageInfo} />{" "}
        </div>
        <div className={classes.DateFilter_container}>
          <div className={classes.button_container}>
            <CSVLink
              data={guestalllist}
              filename={"guestalllist.csv"}
              target="_blank"
            >
              <button className={classes.button_articleList}>CSV</button>
            </CSVLink>
            <ExcelDownload csvData={guestalllist} fileName="guestalllist" />
          </div>
          <div className={classes.dateDiv}>
            <select
              onClick={(e) => setSelectedPost(e.target.value)}
              name=""
              id=""
            >
              <option value="forherevents">forherevents</option>
              <option value="forhercourses">forhercourses</option>
              <option value="forherjobs">forherjobs</option>
            </select>
          </div>
        </div>
        {loading ? (
          <div className={classes.spinner}>
            <LoadingSpinner />
          </div>
        ) : (
          <div className={classes.table_container}>
            <div className={classes.buttons1}>
              <div>
                {currentPage !== 1 ? (
                  <button
                    onClick={() => {
                      setCurrentPage(currentPage - 1);
                    }}
                    className={classes.buttonone}
                  >
                    Prev
                  </button>
                ) : (
                  <></>
                )}
                <span>{count >= 20 ? currentPage : <></>}</span>
                {guestlist.length >= 20 ? (
                  <button
                    onClick={() => {
                      setCurrentPage(currentPage + 1);
                    }}
                    className={classes.buttonone}
                  >
                    Next
                  </button>
                ) : (
                  <></>
                )}
              </div>

              <div>
                <button className={classes.Addbtn}>
                  <Link
                    to="/BiDashboard/AddGuestArticle"
                    className={classes.Edit_link}
                  >
                    Add Guest Article
                  </Link>
                </button>
              </div>
            </div>
            {selectedPost === "forherevents" && (
              <div className={classes.container}>
                {guestalllist.length > 0 ? (
                  <table className={classes.orders_table}>
                    <tr className={classes.table_row}>
                      <th className={classes.table_heading}>
                        Id{" "}
                        {issno === "ASC" ? (
                          <span
                            className={classes.arrow}
                            onClick={() => sortingsrno(" Id")}
                          >
                            <AiOutlineArrowDown />
                          </span>
                        ) : (
                          <span
                            className={classes.arrow}
                            onClick={() => sortingsrno(" Id")}
                          >
                            <AiOutlineArrowUp />
                          </span>
                        )}
                      </th>
                      <th className={classes.table_heading}>Event Name</th>
                      <th className={classes.table_heading}>Event location</th>
                      <th className={classes.table_heading}>event_organizer</th>

                      <th className={classes.table_heading}>
                        Event Start Date
                      </th>

                      <th className={classes.table_heading}>Event End Date</th>
                      <th className={classes.table_heading}>Update</th>
                      {/* <th className={classes.table_heading}>Delete</th> */}
                    </tr>

                    <tbody>
                      {guestalllist.map((item, value) => {
                        return (
                          <tr className={classes.table_row}>
                            <td className={classes.table_td}>{item.id}</td>

                            <td className={classes.table_td}>
                              {item.eventName}
                            </td>

                            <td className={classes.table_td}>
                              {item.eventLocation}
                            </td>

                            <td className={classes.table_td}>
                              {item.event_organizer}
                            </td>
                            <td className={classes.table_td}>
                              {item.eventStartDate}
                            </td>
                            <td className={classes.table_td}>
                              {item.eventEndDate}
                            </td>
                            <td className={classes.table_td}>
                              <Link
                                to={`/BiDashboard/forherPostEdit/${item.id}/forherevents`}
                                className={classes.Edit_link_two}
                              >
                                Edit
                              </Link>
                            </td>
                            {/* <td className={classes.table_td}>
                            <button
                              className={classes.delbtn}
                              onClick={() => DeleteApi(item.id)}
                            >
                              {loading ? (
                                <Loader
                                  type="TailSpin"
                                  color="white"
                                  height={20}
                                  width={50}
                                  className="text-center"
                                />
                              ) : (
                                "Delete"
                              )}
                            </button>
                          </td> */}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                ) : (
                  <div className={classes.No_data}>No data found!</div>
                )}
              </div>
            )}
            {selectedPost === "forhercourses" && (
              <div className={classes.container}>
                {guestalllist.length > 0 ? (
                  <table className={classes.orders_table}>
                    <tr className={classes.table_row}>
                      <th className={classes.table_heading}>
                        CourseId
                        {issno === "ASC" ? (
                          <span
                            className={classes.arrow}
                            onClick={() => sortingsrno(" Id")}
                          >
                            <AiOutlineArrowDown />
                          </span>
                        ) : (
                          <span
                            className={classes.arrow}
                            onClick={() => sortingsrno(" Id")}
                          >
                            <AiOutlineArrowUp />
                          </span>
                        )}
                      </th>
                      <th className={classes.table_heading}>course_title</th>

                      <th className={classes.table_heading}>course_type</th>

                      <th className={classes.table_heading}>course_category</th>

                      <th className={classes.table_heading}>courseDuration</th>
                      <th className={classes.table_heading}>Edit</th>
                    </tr>

                    <tbody>
                      {guestalllist.map((item, value) => {
                        return (
                          <tr className={classes.table_row}>
                            <td className={classes.table_td}>{item.id}</td>

                            <td className={classes.table_td}>
                              {item.course_title}
                            </td>

                            <td className={classes.table_td}>
                              {item.course_type}
                            </td>
                            <td className={classes.table_td}>
                              {item.course_category}
                            </td>
                            <td className={classes.table_td}>
                              {item.courseDuration}
                            </td>
                            <td className={classes.table_td}>
                              <Link
                                to={`/BiDashboard/forherPostEdit/${item.id}/forhercourses`}
                                className={classes.Edit_link_two}
                              >
                                Edit
                              </Link>
                            </td>
                            {/* <td className={classes.table_td}>
                            <button
                              className={classes.delbtn}
                              onClick={() => DeleteApi(item.id)}
                            >
                              {loading ? (
                                <Loader
                                  type="TailSpin"
                                  color="white"
                                  height={20}
                                  width={50}
                                  className="text-center"
                                />
                              ) : (
                                "Delete"
                              )}
                            </button>
                          </td> */}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                ) : (
                  <div className={classes.No_data}>No data found!</div>
                )}
              </div>
            )}
            {selectedPost === "forherjobs" && (
              <div className={classes.container}>
                {guestalllist.length > 0 ? (
                  <table className={classes.orders_table}>
                    <tr className={classes.table_row}>
                      <th className={classes.table_heading}>
                        CourseId
                        {issno === "ASC" ? (
                          <span
                            className={classes.arrow}
                            onClick={() => sortingsrno(" Id")}
                          >
                            <AiOutlineArrowDown />
                          </span>
                        ) : (
                          <span
                            className={classes.arrow}
                            onClick={() => sortingsrno(" Id")}
                          >
                            <AiOutlineArrowUp />
                          </span>
                        )}
                      </th>
                      <th className={classes.table_heading}>positionTitle</th>

                      <th className={classes.table_heading}>imageUrl</th>

                      <th className={classes.table_heading}>companyname</th>

                      <th className={classes.table_heading}>city</th>
                      <th className={classes.table_heading}>Edit</th>
                    </tr>

                    <tbody>
                      {guestalllist.map((item, value) => {
                        return (
                          <tr className={classes.table_row}>
                            <td className={classes.table_td}>{item.id}</td>

                            <td className={classes.table_td}>
                              {item.positionTitle}
                            </td>

                            <td className={classes.table_td}>
                              {item.imageUrl}
                            </td>
                            <td className={classes.table_td}>
                              {item.companyname}
                            </td>
                            <td className={classes.table_td}>{item.city}</td>
                            <td className={classes.table_td}>
                              <Link
                                to={`/BiDashboard/forherPostEdit/${item.id}/forherjobs`}
                                className={classes.Edit_link_two}
                              >
                                Edit
                              </Link>
                            </td>
                            {/* <td className={classes.table_td}>
                            <button
                              className={classes.delbtn}
                              onClick={() => DeleteApi(item.id)}
                            >
                              {loading ? (
                                <Loader
                                  type="TailSpin"
                                  color="white"
                                  height={20}
                                  width={50}
                                  className="text-center"
                                />
                              ) : (
                                "Delete"
                              )}
                            </button>
                          </td> */}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                ) : (
                  <div className={classes.No_data}>No data found!</div>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default ForherPostList;
