import React, { useState, useEffect, useRef } from "react";
import classes from "./QuestionList.module.css";
import { FiEdit } from "react-icons/fi";
import { MdDeleteForever } from "react-icons/md";
import { useHistory, Link } from "react-router-dom";
import { CSVLink } from "react-csv";
import jsPDF from "jspdf";
import LoadingSpinner from "../../Components/LoadingSpinner";
import ExcelDownload from "../../Components/Articles/ExcelDownload";
import Paginate from "../../Components/Pagination";
import ConfirmModal from "../../Components/ConfirmModal";
import Modal from "../../Components/Modal";
import { BsEye } from "react-icons/bs";
import QuestionModal from "./QuestionModal";

const QuizQuestionList = (props) => {
    const [data, setData] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [errors, setErrors] = useState(false);
    const doc = new jsPDF();
    const [currentPage, setCurrentPage] = useState(1);
    const [modalShow, setModalShow] = useState(false);
    const [confirmModalShow, setConfirmModalShow] = useState(false);
    const [count, setCount] = useState(0);
    const [QAUUID, setQAUUID] = useState("");
    const [errorModal, setErrorModal] = useState("");
    const [modelData, setModelData] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const perPage = 20;

    const tableRef = useRef();
    const formValidation = () => {
        let isValid = true;
        let error = {};

        if (!startDate || !endDate) {
            isValid = false;
            error.startDate = "Please Select Both Dates";
        }

        setErrors(error);
        return isValid;
    };
    let today = new Date();
    today.setDate(today.getDate() - 60);
    let date = new Date(today)
        .toLocaleDateString("in", {
            day: "2-digit",
            year: "numeric",
            month: "2-digit",
        })
        .split("/")
        .reverse()
        .join("-");

    useEffect(() => {
        fetchQuestionList();
    }, [currentPage]);

    const history = useHistory();

    const fetchQuestionList = () => {
        setLoading(true);
        var myHeaders = new Headers();
        myHeaders.append(
            "Authorization",
            "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
        );
        myHeaders.append("Content-Type", "application/json");
        var raw = JSON.stringify({
            page: currentPage,
            content: perPage,
            StartDate: startDate,
            EndDate: endDate
        });

        var requestOptions = {
            method: "POST",
            body: raw,
            headers: myHeaders,
            redirect: "follow",
        };

        fetch(global.apiLink + "/adminapi/QuizListDataBank", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.status_code == 200 && result.status === "SUCCESS") {
                    setData(result.data);
                    setCount(result.count);
                }
                setLoading(false);
            })
            .catch((error) => console.log("error", error));
    };

    const deleteQuiz = () => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Token 77927b69bb144b065ca11bf2a9d452819cd852db");

        var formdata = new FormData();
        formdata.append("QuizDataBankUUID", QAUUID);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(global.apiLink + "/adminapi/DeleteQuizDataBank", requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status === "SUCCESS" && result.status_code == 200) {
                    setModalShow(true);
                    fetchQuestionList();
                }
                else {
                    setErrorModal(true)
                }
            })
            .catch(error => console.log('error', error));
    }

    // Function to search for quiz questions
    const searchCategory = (term) => {
        const myHeaders = new Headers();
        myHeaders.append(
            "Authorization",
            "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
        );

        const formdata = new FormData();
        formdata.append("SearchTerm", term);
        formdata.append("Type", "All");

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: formdata,
            redirect: "follow",
        };

        setLoading(true);

        fetch(global.apiLink + "/adminapi/QuizSearchAPI", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                setData(result.data || []);
                setLoading(false);
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
                setData([]);
                setLoading(false);
            });
    };


    // Handle search term change
    const handleSearchChange = (e) => {
        const term = e.target.value;
        setSearchTerm(term);

        if (term.trim() === "") {
            // If the search term is empty, fetch all the quiz questions
            fetchQuestionList();
        } else {
            // Otherwise, search based on the search term
            searchCategory(term);
        }
    };


    const handleQuiz = () => {
        history.push("/BiDashboard/AddQuizQuestion");
    };

    const onModalCloseHandler = () => {
        setIsModalOpen(false);
    };


    return (
        <div className={classes.main_div}>
            <div className={classes.container}>
                {confirmModalShow && (
                    <ConfirmModal
                        heading={"Are you sure that you want to delete this data?"}
                        onClick={() => deleteQuiz()}
                        onClose={() => setConfirmModalShow(false)}
                    />
                )}
                {modalShow && (
                    <Modal
                        heading={"Data deleted successfully"}
                        onClick={() => {
                            setModalShow(false);
                            setConfirmModalShow(false);
                        }}
                    />
                )}
                {errorModal && (
                    <Modal
                        heading={"Unable to delete the data."}
                        onClick={() => {
                            setErrorModal(false);
                            setConfirmModalShow(false);
                        }}
                    />
                )}
                {isModalOpen && modelData && (
                    <QuestionModal
                        isModalOpen={isModalOpen}
                        data={modelData}
                        onClose={() => setIsModalOpen(false)}
                    />
                )}
                <div className={classes.header}>Quiz Question List :</div>
                <div className={classes.DateFilter_container}>
                    <div className={classes.button_container}>
                        <CSVLink
                            data={data ? data : ""}
                            filename={"/QuizQuestionList.csv"}
                            target="_blank"
                        >
                            <button className={classes.button_articleList}>CSV</button>
                        </CSVLink>
                        <ExcelDownload
                            csvData={data}
                            fileName="/QuizQuestionList"
                        />
                    </div>
                    <div className={classes.dateDiv}>
                        <div className={classes.filter}>
                            <label className={classes.dateLable}>Start Date</label>
                            <input
                                type="date"
                                value={startDate || date}
                                onChange={(e) => setStartDate(e.target.value)}
                            />
                            <label className={classes.dateLable}>End Date</label>
                            <input
                                type="date"
                                value={
                                    endDate ||
                                    new Date()
                                        .toLocaleDateString("in", {
                                            day: "2-digit",
                                            year: "numeric",
                                            month: "2-digit",
                                        })
                                        .split("/")
                                        .reverse()
                                        .join("-")
                                }
                                onChange={(e) => setEndDate(e.target.value)}
                            />
                        </div>
                        <div className={classes.form_error}>{errors.startDate}</div>
                        <div className={classes.buttons}>
                            <button
                                type="button"
                                className="button"
                                onClick={() =>
                                    formValidation()
                                        ? fetchQuestionList()
                                        : console.log("Something Wrong")
                                }
                            >
                                {" "}
                                Search
                            </button>
                        </div>
                    </div>
                </div>
                <div className={classes.buttons}>
                    <button className={classes.button} onClick={handleQuiz}>
                        Add New
                    </button>
                </div>
                <div className={classes.searchInputContainer}>
                    <label className={classes.searchLabel}>Search Quiz Question</label>
                    <input
                        type="text"
                        className={classes.searchInput}
                        placeholder="Type to search..."
                        value={searchTerm}
                        onChange={handleSearchChange} // <-- Call handleSearchChange directly on input change
                    />
                </div>
                <div>
                    {isLoading ? (
                        <div className={classes.spinner}>
                            <LoadingSpinner />{" "}
                        </div>
                    ) : data.length ? (
                        <>
                            <table
                                id="article-table"
                                ref={tableRef}
                                className={classes.orders_table}
                            >
                                <tr className={classes.table_row}>
                                    <th className={classes.table_heading}>Sr. No.</th>
                                    <th className={classes.table_heading}>Question Title</th>
                                    <th className={classes.table_heading}>Correct Answer</th>
                                    <th className={classes.table_heading}>Category</th>
                                    <th className={classes.table_heading}>
                                        Status
                                    </th>
                                    <th className={classes.table_heading}>
                                        Created At
                                    </th>
                                    <th className={classes.table_heading}>
                                        Updated At
                                    </th>
                                    <th className={classes.table_heading}>View</th>
                                    <th className={classes.table_heading}>Edit</th>
                                    <th className={classes.table_heading}>Delete</th>
                                </tr>
                                <tbody>
                                    {data.map((item, idx) => (
                                        <tr key={item.Id} className={classes.table_row}>
                                            <td className={classes.table_td}>{idx + 1}</td>
                                            <td className={classes.table_td}>
                                                {item.Question}
                                            </td>
                                            <td className={classes.table_td}>{item.CorrectAnswer}</td>
                                            <td className={classes.table_td}>{item.QACategoryName}</td>
                                            <td className={classes.table_td}>{item.IsLive == "1" ? "Live" : "Not Live"}</td>
                                            <td className={classes.table_td}>{item.CreatedAt.split("T")[0]}</td>
                                            <td className={classes.table_td}>{item.UpdateAt.split("T")[0]}</td>
                                            <td
                                                className={classes.table_td}
                                                style={{ cursor: "pointer" }}
                                                onClick={() => {
                                                    setModelData(item);
                                                    setIsModalOpen(true);
                                                }}
                                            >
                                                <span className={classes.table_td_Link}>
                                                    <BsEye />
                                                </span>
                                            </td>

                                            <td className={classes.table_td}>
                                                <Link
                                                    to={"/BiDashboard/editQuizQuestion/" + item.QAUUID}
                                                    className={classes.articleLink}
                                                >
                                                    <FiEdit size={20} />
                                                </Link>
                                            </td>
                                            <td className={classes.table_td}>
                                                <div
                                                    onClick={() => {
                                                        setQAUUID(item.QAUUID);
                                                        setConfirmModalShow(true);
                                                    }}
                                                    className={classes.articleLinkDelete}
                                                >
                                                    <MdDeleteForever size={20} />
                                                </div>
                                            </td>

                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <div>
                                {
                                    count > 20 &&
                                    <Paginate maxPage={count} currentPage={currentPage} setCurrentPage={setCurrentPage} perPage={perPage} />
                                }
                            </div>
                        </>
                    ) : (
                        <h2 className={classes.serachErrorText}>"No Quiz Question Found !"</h2>
                    )}
                </div>
            </div>
        </div>
    );
};

export default QuizQuestionList;
