import React, { useState, useEffect } from "react";
import LoadingSpinner from "../../Components/LandingPagedetails";
import classes from "../PostanPoll/Pollslist.module.css";
import { AiOutlineArrowUp } from "react-icons/ai";
import { AiOutlineArrowDown } from "react-icons/ai";
import { Link } from "react-router-dom";

const PollsList = () => {
  const [isLoading, setLoading] = useState(false);
  const [pollslist, setPollslist] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [issno, setIssno] = useState("ASC");
  const [iscount, setIscount] = useState("ASC");
  const [iscount2, setIscount2] = useState("ASC");
  const [iscount3, setIscount3] = useState("ASC");
  const PollsListApi = () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      page: currentPage,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_API_URL + "/adminapi/getAllListOfpollQuestion",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code === 200) {
          setPollslist(result.data);
        }
      })
      .catch((error) => console.log("error", error));
  };

  useEffect(() => {
    PollsListApi();
  }, [currentPage]);

  const sortingsrno = (col) => {
    if (issno === "ASC") {
      const sortsno = [...pollslist].sort((a, b) => (a[col] > b[col] ? 1 : -1));
      setPollslist(sortsno);
      setIssno("DSC");
    }
    if (issno === "DSC") {
      const sortsno = [...pollslist].sort((a, b) => (a[col] < b[col] ? 1 : -1));
      setPollslist(sortsno);
      setIssno("ASC");
    }
  };
  const sortingCount = (col) => {
    if (iscount === "ASC") {
      const sortcount = [...pollslist].sort((a, b) =>
        a[col] > b[col] ? 1 : -1
      );
      setPollslist(sortcount);
      setIscount("DSC");
    }
    if (iscount === "DSC") {
      const sortcount = [...pollslist].sort((a, b) =>
        a[col] < b[col] ? 1 : -1
      );
      setPollslist(sortcount);
      setIscount("ASC");
    }
  };
  const sortingCount2 = (col) => {
    if (iscount2 === "ASC") {
      const sortcount2 = [...pollslist].sort((a, b) =>
        a[col] > b[col] ? 1 : -1
      );
      setPollslist(sortcount2);
      setIscount2("DSC");
    }
    if (iscount2 === "DSC") {
      const sortcount2 = [...pollslist].sort((a, b) =>
        a[col] < b[col] ? 1 : -1
      );
      setPollslist(sortcount2);
      setIscount2("ASC");
    }
  };
  const sortingCount3 = (col) => {
    if (iscount3 === "ASC") {
      const sortcount3 = [...pollslist].sort((a, b) =>
        a[col] > b[col] ? 1 : -1
      );
      setPollslist(sortcount3);
      setIscount3("DSC");
    }
    if (iscount3 === "DSC") {
      const sortcount3 = [...pollslist].sort((a, b) =>
        a[col] < b[col] ? 1 : -1
      );
      setPollslist(sortcount3);
      setIscount3("ASC");
    }
  };
  return (
    <>
      <div className={classes.dashboard_container}>
        {isLoading ? (
          <div className={classes.spinner}>
            <LoadingSpinner />
          </div>
        ) : (
          <div className={classes.table_container}>
            <div className={classes.buttons1}>
              <div>
                {currentPage !== 1 ? (
                  <button
                    onClick={() => {
                      setCurrentPage(currentPage - 1);
                    }}
                    className={classes.buttonone}
                  >
                    Prev
                  </button>
                ) : (
                  <></>
                )}
                <span>{currentPage}</span>
                {pollslist.length < 24 ? (
                  <></>
                ) : (
                  <>
                    {" "}
                    <button
                      onClick={() => {
                        setCurrentPage(currentPage + 1);
                      }}
                      className={classes.buttonone}
                    >
                      Next
                    </button>
                  </>
                )}{" "}
              </div>

              <div>
                <button className={classes.Addbtn}>
                  <Link
                    to="/BiDashboard/CreatePoll"
                    className={classes.Edit_link}
                  >
                    Create A Poll
                  </Link>
                </button>
              </div>
            </div>
            <div className={classes.container}>
              {pollslist.length > 0 ? (
                <table className={classes.orders_table}>
                  <tr className={classes.table_row}>
                    <th className={classes.table_heading}>
                      Id{" "}
                      {issno === "ASC" ? (
                        <span
                          className={classes.arrow}
                          onClick={() => sortingsrno("sno")}
                        >
                          <AiOutlineArrowDown />
                        </span>
                      ) : (
                        <span
                          className={classes.arrow}
                          onClick={() => sortingsrno("sno")}
                        >
                          <AiOutlineArrowUp />
                        </span>
                      )}
                    </th>
                    <th className={classes.table_heading}>Questions</th>
                    <th className={classes.table_heading}>Option1</th>
                    <th className={classes.table_heading}>Option2</th>
                    <th className={classes.table_heading}>Option3</th>
                    <th className={classes.table_heading}>
                      Option One Count{" "}
                      {iscount === "ASC" ? (
                        <span
                          className={classes.arrow}
                          onClick={() => sortingCount("option1Count")}
                        >
                          <AiOutlineArrowDown />
                        </span>
                      ) : (
                        <span
                          className={classes.arrow}
                          onClick={() => sortingCount("option1Count")}
                        >
                          <AiOutlineArrowUp />
                        </span>
                      )}
                    </th>
                    <th className={classes.table_heading}>
                      Option Two Count
                      {iscount2 === "ASC" ? (
                        <span
                          className={classes.arrow}
                          onClick={() => sortingCount2("option2Count")}
                        >
                          <AiOutlineArrowDown />
                        </span>
                      ) : (
                        <span
                          className={classes.arrow}
                          onClick={() => sortingCount2("option2Count")}
                        >
                          <AiOutlineArrowUp />
                        </span>
                      )}
                    </th>
                    <th className={classes.table_heading}>
                      Option Three Count{" "}
                      {iscount3 === "ASC" ? (
                        <span
                          className={classes.arrow}
                          onClick={() => sortingCount3("option3Count")}
                        >
                          <AiOutlineArrowDown />
                        </span>
                      ) : (
                        <span
                          className={classes.arrow}
                          onClick={() => sortingCount3("option3Count")}
                        >
                          <AiOutlineArrowUp />
                        </span>
                      )}
                    </th>
                    <th className={classes.table_heading}>Status</th>
                    <th className={classes.table_heading}>Update</th>
                  </tr>

                  <tbody>
                    {pollslist.map((item, value) => {
                      return (
                        <tr className={classes.table_row}>
                          <td className={classes.table_td}>{item.id}</td>
                          <td className={classes.table_td}>{item.question}</td>
                          <td className={classes.table_td}>{item.option1}</td>
                          <td className={classes.table_td}>{item.option2}</td>
                          <td className={classes.table_td}>{item.option3}</td>

                          <td className={classes.table_td}>
                            {item.option1Count}
                          </td>
                          <td className={classes.table_td}>
                            {item.option2Count}
                          </td>
                          <td className={classes.table_td}>
                            {item.option3Count}
                          </td>
                          <td className={classes.table_td}>
                            {item.isCurrent === 0 ? (
                              <span className={classes.isnotLive}>
                                Not Live
                              </span>
                            ) : (
                              <span className={classes.isLive}>Live</span>
                            )}
                          </td>
                          <td className={classes.table_td}>
                            <Link
                              to={"/BiDashboard/UpdatePolls/" + item.id}
                              className={classes.Edit_link_two}
                            >
                              Edit
                            </Link>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              ) : (
                <div className={classes.No_data}>No data found!</div>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default PollsList;
