import React, { useEffect, useRef, useState } from "react";
import Loader from "react-loader-spinner";
import { useLocation, useParams } from "react-router-dom";
import EditCompanyModal from "../Components/EditCompanyModal";
import EditJobModal from "../Components/EditJobModal";
import classes from "./GridDashboard.module.css";
import JobWebstoryModal from "../Components/JobWebstoryModal";

const GridDashboard = () => {
  const { id } = useParams();
  const { QC } = useParams();
  const { pathname } = useLocation();
  const [showCompanyModal, setShowCompanyModal] = useState(false);
  const [showJobModal, setShowJobModal] = useState(false);
  const [locationList, setLocationList] = useState([]);
  const [jobFunctionList, setJobFunctionList] = useState([]);
  const [levelList, setLevelList] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [industryList, setIndustryList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [adDetailImage, setAdDetailImage] = useState();
  const [adDetail, setAdDetail] = useState({});
  const [allAds, setAllAds] = useState([]);
  const [preFilledData, setPrefilledData] = useState({});

  const [mobile, setMobile] = useState(null);
  const [companyName, setCompanyName] = useState([]);
  const [compDesc, setCompDesc] = useState();
  const [selectedLoc, setSelectedLoc] = useState();
  const [address, setAddress] = useState();

  const [existingLoc, setExistingLoc] = useState();
  const [website, setWebsite] = useState();
  const [companySuggestions, setCompanySuggestions] = useState([]);
  const [companySuggestionsShow, setCompanySuggestionsShow] = useState(true);
  const [selectedCompId, setSelectedCompId] = useState();
  const [sendForQCLoading, setSendForQCLoading] = useState(false);
  const [webstoryModalOpen, setWebstoryModalOpen] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [webstoriesAll, setWebstoriesAll] = useState(false);
  const [webstorySummaryData, setWebstorySummaryData] = useState("");
  const [erorrshow, setErrorShow] = useState(false);
  const [internalErrorShow, setInternalErrorShow] = useState(false);
  const [modalShow, setModalShow] = useState(false);

  useEffect(() => {
    getWebstorySummaryData();
  }, []);

  const selectionDetailsApi = (data) => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(global.apiLink + "/adminapi/selectionDetails", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setLevelList(result.leveldata);
        setJobFunctionList(result.jobFunctiondata);
        setLocationList(result.locationdata);
        setCompanyList(result.companydata);
        setIndustryList(result.industrydata);
      })
      .catch((error) => console.log("error", error))
      .finally(() => setLoading(false));
  };

  const adDetailsApi = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      orderNumber: pathname.split("/").pop(),
    });

    var requestOptions = {
      body: raw,
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(global.apiLink + "/adminapi/getOrderDetails", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setAdDetail(result.data);
        setAdDetailImage(result.data.imageUrl);
      })
      .catch((error) => console.log("error", error))
      .finally(() => {
        setLoading(false);
      });
  };
  const getAdsListByOrderNumber = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      orderNumber: pathname.split("/").pop(),
    });

    var requestOptions = {
      body: raw,
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${global.apiLink}/adminapi/${QC
        ? "getAdsListByOrderNumberForQC"
        : pathname.includes("/NonAscentJobs") ||
          pathname.includes("/postAJob")
          ? "getAdsListByOrderNumberNonascent"
          : "getAdsListByOrderNumber"
      }`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setAllAds(result.data);
      })
      .catch((error) => console.log("error", error))
      .finally(() => {
        setLoading(false);
      });
  };
  const userEmail = sessionStorage.getItem("googleBiEmail");
  const [idArr, setIdArr] = useState([]);
  const [webJobID, setWebJobID] = useState([]);

  const checkId = (id) => {
    if (!idArr.includes(id)) {
      setIdArr((prev) => [...prev, id]);
    } else {
      let arr = idArr;
      let arr1 = arr.filter((val) => val !== id);
      setIdArr(arr1);
    }
  };

  const checkJobId = (id) => {
    if (!webJobID.includes(id)) {
      setWebJobID((prev) => [...prev, id]);
    } else {
      let arr = webJobID;
      let arr1 = arr.filter((val) => val !== id);
      setWebJobID(arr1);
    }
  };

  useEffect(() => {
    if (selectAll) {
      allAds.map((item) => {
        if (!idArr.includes(item.id)) {
          setIdArr((prev) => [...prev, item.id]);
        }
      });
    } else {
      setIdArr([]);
    }
  }, [selectAll]);

  useEffect(() => {
    if (webstoriesAll) {
      allAds.map((item) => {
        if (!webJobID.includes(item.id)) {
          setWebJobID((prev) => [...prev, item.id]);
        }
      });
    } else {
      setWebJobID([]);
    }
  }, [webstoriesAll]);
  useEffect(() => {
    console.log(idArr);
  }, [idArr]);
  useEffect(() => {
    console.log(webJobID, "webJobID");
  }, [webJobID]);
  const addNewJob = () => {
    bottomRef.current.scrollIntoView();
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      orderNumber: id,
      userEmail: userEmail,
    });

    var requestOptions = {
      body: raw,
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${global.apiLink}/adminapi/${pathname.includes("/NonAscentJobs")
        ? "createNewAdsForSameOrderNumberForNonASCENTjobs"
        : "createNewAdsByOrderNumber"
      }`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status !== "SUCCESS") {
          alert("Job creation failed");
          return;
        }
        getAdsListByOrderNumber();
      })
      .catch((error) => console.log("error", error))
      .finally(() => {
        setLoading(false);
      });
  };
  const getCompanyDetail = (companyId, companyName) => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      CompanyId: adDetail.companyId,
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(global.apiLink + "/v1/api/apiTimes/GetCompanyDetail", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setCompanyName(result.data.CompanyName);
        setCompDesc(result.data.companyDescription);
        setAddress(result.data.address);
        setWebsite(result.data.website);
        setMobile(result.data.phone);
        setExistingLoc(result.data.city);
      })
      .catch((error) => console.log("error", error));
  };
  const sendForQC = () => {
    setSendForQCLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      userEmail: userEmail,
      orderNumber: id,
      printAdId: idArr.join(","),
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(global.apiLink + "/adminapi/dataEntryToQC", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code === 200) {
          alert("Jobs sent for Q.C.");
        } else {
          alert("something's wrong");
        }
      })
      .catch((error) => console.log("error", error))
      .finally(() => {
        setSendForQCLoading(false);
      });
  };
  const printAdsfromQCtoDataEntry = () => {
    setSendForQCLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      userEmail: userEmail,
      orderNumber: id,
      printAdId: idArr.join(","),
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(
      global.apiLink + "/adminapi/printAdsfromQCtoDataEntry",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code === 200) {
          alert("Jobs sent for Data entry.");
        } else {
          alert("something's wrong");
        }
      })
      .catch((error) => console.log("error", error))
      .finally(() => {
        setSendForQCLoading(false);
      });
  };

  const getWebstorySummaryData = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 4YzpGk8Vf8IcxzxQ68teZ2KM5iX8SktW5RN4iz92"
    );

    var formdata = new FormData();
    formdata.append("OrderNumber", pathname.split("/").pop());

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_API_URL + "/v1/api/apiTimes/GetJobWebStoriesSummaryByOrderId",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "Success" && result.status_code == 200) {
          setWebstorySummaryData(result.data);
        }
      })
      .catch((error) => console.log("error", error))
      .finally(() => setLoading(false));
  };

  const AddWebstory = (
    orderNumber,
    title,
    description,
    image,
    check,
    seoUrl,
    metaDescription,
    metaTitle
  ) => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );

    var formdata = new FormData();
    formdata.append("OrderNumber", orderNumber);
    formdata.append("Title", title);
    formdata.append("Description", description);
    formdata.append("Image", image);
    formdata.append("IsLive", check);
    formdata.append("SeoURL", seoUrl);
    formdata.append("MetaDescription", metaDescription);
    formdata.append("MetaTitle", metaTitle);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_API_URL + "/v1/api/apiTimes/AddJobsWebstoriesSummary",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "Success" && result.status_code === 200) {
          setModalShow(true);
        } else if (result.status_code === 400 || result.status_code === 500) {
          setInternalErrorShow(true);
        } else if (result.status_code === 300) {
          setErrorShow(true);
        }
      })
      .catch((error) => console.log("error", error))
      .finally(() => setLoading(false));
  };

  const UpdateWebstoryApi = (
    webstoryUUID,
    orderNumber,
    title,
    description,
    check,
    seoUrl,
    metaDescription,
    metaTitle,
    image,
    newImage
  ) => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );

    var formdata = new FormData();

    formdata.append("WebStoriesSummaryID", webstoryUUID);
    formdata.append("OrderNumber", orderNumber);
    formdata.append("Title", title);
    formdata.append("Description", description);
    formdata.append("IsLive", check);
    formdata.append("SeoURL", seoUrl);
    formdata.append("MetaDescription", metaDescription);
    formdata.append("MetaTitle", metaTitle);
    if (newImage) {
      formdata.append("ImageUrl", image);
    }

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_API_URL + "/v1/api/apiTimes/UpdateJobsWebstoriesSummary",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "Success" && result.status_code === 200) {
          setModalShow(true);
        } else if (result.status_code === 400 || result.status_code === 500) {
          setInternalErrorShow(true);
        } else if (result.status_code === 300) {
          setErrorShow(true);
        }
      })
      .catch((error) => console.log("error", error))
      .finally(() => setLoading(false));
  };

  const deleteSelected = () => {
    setDeleteLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      userEmail: userEmail,
      orderNumber: id,
      printAdId: idArr.join(","),
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(global.apiLink + "/adminapi/deleteAd", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code === 200) {
          alert("Jobs deleted.");
        } else {
          alert("something's wrong");
        }
      })
      .catch((error) => console.log("error", error))
      .finally(() => {
        setDeleteLoading(false);
      });
  };
  const makeLive = () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      userEmail: userEmail,
      orderNumber: id,
      printAdId: idArr.join(","),
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(
      `${global.apiLink}/adminapi/${pathname.includes("/NonAscentJobs") || pathname.includes("/postAJob")
        ? "getLiveNonAscentJobs"
        : "QCtoLiveprintAds"
      }`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code === 200) {
          alert("Jobs are live & webstories inprogress");
          makeWebLive();
        } else {
          alert("something's wrong");
        }
      })
      .catch((error) => console.log("error", error));
  };

  const makeWebLive = () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      JobId: webJobID.join(","),
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(
      process.env.REACT_APP_API_URL + "/v1/api/apiTimes/JobWSAutomation",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code === 200) {
          alert("Jobs & Webstories are live");
        } else {
          alert("something's wrong");
        }
      })
      .catch((error) => console.log("error", error));
  };

  useEffect(() => {
    if (adDetail.companyId) {
      getCompanyDetail();
    }
  }, [adDetail]);
  useEffect(() => {
    adDetailsApi();
    getAdsListByOrderNumber();
    selectionDetailsApi();
  }, []);
  const bottomRef = useRef(null);

  return (
    <>
      {showCompanyModal && (
        <EditCompanyModal
          show={showCompanyModal}
          setShow={setShowCompanyModal}
          adDetailsApi={adDetailsApi}
          orderNumber={id}
          companyId={adDetail.companyId}
          adDetailImage={adDetailImage}
          industryList={industryList}
          locationList={locationList}
          jobFunctionList={jobFunctionList}
          levelList={levelList}
          companyList={companyList}
        />
      )}
      {webstoryModalOpen && (
        <JobWebstoryModal
          show={webstoryModalOpen}
          setShow={setWebstoryModalOpen}
          orderNumber={pathname.split("/").pop()}
          data={webstorySummaryData}
          getWebstorySummaryData={getWebstorySummaryData}
          setErrorShow={setErrorShow}
          loading={loading}
          setInternalErrorShow={setInternalErrorShow}
          setModalShow={setModalShow}
          AddWebstory={AddWebstory}
          UpdateWebstoryApi={UpdateWebstoryApi}
          erorrshow={erorrshow}
          internalErrorShow={internalErrorShow}
          modalShow={modalShow}
        />
      )}
      {showJobModal && (
        <EditJobModal
          show={showJobModal}
          setShow={setShowJobModal}
          webstorySummaryData={webstorySummaryData}
          companyName={companyName}
          data={preFilledData}
          getAdsListByOrderNumber={getAdsListByOrderNumber}
          companyId={adDetail.companyId}
          adDetailImage={adDetailImage}
          industryList={industryList}
          locationList={locationList}
          jobFunctionList={jobFunctionList}
          levelList={levelList}
          companyList={companyList}
        />
      )}
      <div className={classes.gridDashboard}>
        <div className={classes.gridDashboardTop}>
          <img src={adDetailImage} width={"300px"} height={"300px"} />
          <div className={classes.companyDetailBox}>
            <div>
              <h6>Company Name : </h6>
              <span>{companyName || "NA"}</span>
            </div>

            <div>
              <h6>Company Description :</h6>
              <span>{compDesc || "NA"}</span>
            </div>

            <div>
              <h6>Tel : </h6>
              <span>{mobile || "NA"}</span>
            </div>

            <div>
              <h6>Company website : </h6>
              <span>{website || "NA"}</span>
            </div>

            <div>
              <h6>Company Address : </h6>
              <span>{address || "NA"}</span>
            </div>
            <div>
              <h6>Job Application Details : </h6>
              <span>{adDetail.applicationDetails || "NA"}</span>
            </div>
            <div>
              {QC ? (
                <></>
              ) : (
                <button
                  onClick={() => {
                    addNewJob();
                  }}
                >
                  Add New Job
                </button>
              )}
              <button
                onClick={() => {
                  setShowCompanyModal(true);
                }}
              >
                Edit
              </button>
            </div>
          </div>
        </div>
        <div>
          <>
            <div className={classes.addWebstory}>
              <button
                onClick={() => {
                  setWebstoryModalOpen(true);
                }}
              >
                <>
                  {loading && (
                    <Loader
                      type="TailSpin"
                      color="white"
                      height={20}
                      width={50}
                      className="text-center"
                    />
                  )}
                  {!loading && (
                    <>
                      {webstorySummaryData ? "Edit Webstory" : "Add Webstory"}
                    </>
                  )}
                </>
              </button>
            </div>
          </>
        </div>
        <div className={classes.table_div}>
          <table>
            <thead>
              <tr>
                <td>Sr No.</td>
                <td
                  onClick={() => {
                    setSelectAll(!selectAll);
                  }}
                  className={classes.cursor_pointer}
                >
                  Select All
                </td>
                <td
                  onClick={() => {
                    setWebstoriesAll(!webstoriesAll);
                  }}
                  className={classes.cursor_pointer}
                >
                  Webstories All
                </td>
                <td>Position Title</td>
                <td>Job Function</td>
                <td>Level</td>
                <td>Job Description</td>
                <td>MinSalary</td>
                <td>MaxSalary</td>
                <td>Email</td>
                <td>Employement Type</td>
                <td>Postal Code</td>
                <td>Currency</td>
                <td>Street Address</td>
                <td>Region</td>
                <td>External Apply URLs</td>
                <td>Locations</td>
                <td>Start Date</td>
                <td>End Date</td>
                <td>Walk in time</td>
                <td>Address</td>
                {/* <td><button>Edit</button></td> */}
              </tr>
            </thead>
            <tbody>
              {allAds.map((item, index) => {
                return (
                  <TrComponent
                    index={index}
                    item={item}
                    selectAll={selectAll}
                    setSelectAll={setSelectAll}
                    locationList={locationList}
                    levelList={levelList}
                    jobFunctionList={jobFunctionList}
                    webstoriesAll={webstoriesAll}
                    setWebstoriesAll={setWebstoriesAll}
                    checkId={checkId}
                    checkJobId={checkJobId}
                    setShowJobModal={setShowJobModal}
                    setPrefilledData={setPrefilledData}
                  />
                );
              })}
              <div ref={bottomRef}></div>
            </tbody>
          </table>
          <div className={classes.center}>
            {loading ? (
              <Loader type="TailSpin" color="#00BFFF" height={80} width={80} />
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className={classes.bottomBtns}>
          {/* {sessionStorage.getItem("level") === "ADSDB" ? (
            <div className={classes.saveButtons}>
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  makeWebLive();
                }}
                className={classes.href}
              >
                Make Webstories Live
              </a>
            </div>
          ) : (
            <div></div>
          )} */}
          {sessionStorage.getItem("level") === "ADSDB" ? (
            <div className={classes.saveButtons}>
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  makeLive();
                }}
                className={classes.href}
              >
                Make live
              </a>
            </div>
          ) : (
            <div></div>
          )}
          <>
            {pathname.includes("/printAd") ? (
              QC ? (
                <div className={classes.saveButtons}>
                  {sendForQCLoading ? (
                    <Loader
                      type="TailSpin"
                      color="#f90292"
                      height={30}
                      width={30}
                    />
                  ) : (
                    <>
                      <button
                        onClick={() => {
                          printAdsfromQCtoDataEntry();
                        }}
                      >
                        Send to data entry
                      </button>
                    </>
                  )}
                  {deleteLoading ? (
                    <Loader
                      type="TailSpin"
                      color="#f90292"
                      height={30}
                      width={30}
                    />
                  ) : (
                    <>
                      <button
                        onClick={() => {
                          deleteSelected();
                        }}
                      >
                        Delete selected
                      </button>
                    </>
                  )}
                </div>
              ) : (
                <div className={classes.saveButtons}>
                  <>
                    {sendForQCLoading ? (
                      <Loader
                        type="TailSpin"
                        color="#f90292"
                        height={30}
                        width={30}
                      />
                    ) : (
                      <>
                        <button
                          onClick={() => {
                            sendForQC();
                          }}
                        >
                          Send for QC
                        </button>
                      </>
                    )}
                  </>
                </div>
              )
            ) : (
              <div className={classes.saveButtons}>
                {deleteLoading ? (
                  <Loader
                    type="TailSpin"
                    color="#f90292"
                    height={30}
                    width={30}
                  />
                ) : (
                  <>
                    <button
                      onClick={() => {
                        deleteSelected();
                      }}
                    >
                      Delete selected
                    </button>
                  </>
                )}
              </div>
            )}
          </>
        </div>
      </div>
    </>
  );
};
const TrComponent = ({
  item,
  selectAll,
  locationList,
  jobFunctionList,
  levelList,
  index,
  checkId,
  checkJobId,
  setShowJobModal,
  setPrefilledData,
  webstoriesAll,
  setWebstoriesAll,
}) => {
  const [level, setLevel] = useState();
  const [jobFunction, setJobFunction] = useState();
  const [locations, setLocations] = useState();
  useEffect(() => {
    if (
      item &&
      locationList &&
      jobFunctionList &&
      levelList &&
      locationList.length > 0 &&
      jobFunctionList.length > 0 &&
      levelList.length > 0
    ) {
      setLevel(
        levelList.filter((cat) => {
          return cat.keyindex === item.level;
        })[0]
      );
      setJobFunction(
        jobFunctionList.filter((cat) => {
          return cat.keyindex === item.jobCategory;
        })[0]
      );
      let str = item.location;
      let arr = str ? str.split(",") : [];
      let locationsNameArr = arr.map((value) => {
        let obj = locationList ? locationList.find((o) => o.id === value) : [];
        {
          /* console.log(obj); */
        }
        if (obj && obj.locName) return obj.locName;
      });
      // let locations = ""
      console.log(locationsNameArr);
      setLocations(locationsNameArr.join(","));
    }
  }, [item, locationList, jobFunctionList, levelList]);
  const [isSelected, setIsSelected] = useState(false);
  const [isWebstorySelected, setIsWebstorySelected] = useState(false);
  useEffect(() => {
    setIsSelected(selectAll);
  }, [selectAll]);
  useEffect(() => {
    setIsWebstorySelected(webstoriesAll);
  }, [webstoriesAll]);

  return (
    <tr>
      <TdComponent>{index + 1 || " -"}</TdComponent>
      <TdComponent>
        <input
          type="checkbox"
          className={classes.checkbox_input}
          onChange={(e) => {
            setIsSelected(!isSelected);
            checkId(item.id);
          }}
          checked={isSelected}
        />
      </TdComponent>
      <TdComponent>
        <input
          type="checkbox"
          className={classes.checkbox_input}
          onChange={(e) => {
            setIsWebstorySelected(!isWebstorySelected);
            checkJobId(item.id);
          }}
          checked={isWebstorySelected}
        />
      </TdComponent>
      <TdComponent>{item.positionTitle || "-"}</TdComponent>
      <TdComponent>{(jobFunction && jobFunction.jobName) || "-"}</TdComponent>
      <TdComponent>{(level && level.levelName) || "-"}</TdComponent>
      <TdComponent>{item.jobDescription || "-"}</TdComponent>
      <TdComponent>{item.minCtc || "-"}</TdComponent>
      <TdComponent>{item.maxCtc || "-"}</TdComponent>
      <TdComponent>{item.email || "-"}</TdComponent>
      <TdComponent>{item.EmploymentType || "-"}</TdComponent>
      <TdComponent>{item.PostalCode || "-"}</TdComponent>
      <TdComponent>{item.Currency || "-"}</TdComponent>
      <TdComponent>{item.StreetAddress || "-"}</TdComponent>
      <TdComponent>{item.AddressRegion || "-"}</TdComponent>
      <TdComponent>{item.externalApplyUrl || "-"}</TdComponent>
      <TdComponent>{locations || "-"}</TdComponent>
      <TdComponent>{item.WalkinStartDate || "-"}</TdComponent>
      <TdComponent>{item.WalkinEndDate || "-"}</TdComponent>
      <TdComponent>{item.WalkinTime || "-"}</TdComponent>
      <TdComponent>{item.WalkinAddress || "-"}</TdComponent>
      <td>
        <button
          onClick={() => {
            setShowJobModal(true);
            setPrefilledData(item);
          }}
        >
          Edit
        </button>
      </td>
    </tr>
  );
};
const TdComponent = (props) => {
  // const [alertValue, setAlertValue] = useState(false)
  // const alertInput = () => {
  //     return (
  //         <div className={classes.alertInput}> <div>{props.children}</div></div>
  //     )
  // }
  return (
    <td
    // onMouseOver={(event) => {
    //     ; setAlertValue(true)
    // }} onMouseOut={(event) => {
    //     ; setAlertValue(false)
    // }}
    >
      <p>{props.children}</p>
      {/* {alertValue ? alertInput() : ""} */}
    </td>
  );
};
export default GridDashboard;
