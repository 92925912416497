import React, { useState, useEffect } from "react";

import classes from "./hrshare.module.css";

// import { Link } from "react-router-dom";
import { CSVLink } from "react-csv";
// import Loader from "react-loader-spinner";

// import { useHistory } from "react-router-dom";
import ExcelDownload from "./Articles/ExcelDownload";
import TimesBreadCrumbs from "./TimesBreadCrumbs";
import LoadingSpinner from "./LoadingSpinner";
import Modal from "./Modal";
import EventInputs from "./Events/EventInputs";

const HrshareFormlist = () => {
  const [loading, setLoading] = useState(false);
  const [webstorylist, setWebstorylist] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [errors, setErrors] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [alertMessage, setAlertMessage] = useState(false);
  // const history = useHistory();



  const WebstoryListApi = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      StartDate: startDate || date,
      EndDate:
        endDate ||
        new Date()
          .toLocaleDateString("in", {
            day: "2-digit",
            year: "numeric",
            month: "2-digit",
          })
          .split("/")
          .reverse()
          .join("-"),
      page: currentPage,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_API_URL + "/v1/api/apiTimes/RecognizingHrExcList",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code === 200) {
          setWebstorylist(result.data);
        }
      })
      .catch((error) => console.log("error", error))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    WebstoryListApi();
  }, [currentPage]);

  const hrShareApproveFunc = (uuid, approveStatus) => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      RecognizingHrExcID: uuid,
      Approve: approveStatus == "1" ? "0" : "1",
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_API_URL + "/v1/api/apiTimes/ApproveRecognizingHrExcList",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "Success" && result.status_code == 200) {
          setAlertMessage(
            approveStatus == "1"
              ? "Data disapproved successfully"
              : "Data approved successfully"
          );
          setModalShow(true);
          WebstoryListApi();
        }
      })
      .catch((error) => console.log("error", error))
      .finally(() => setLoading(false));
  };

  const formValidation = () => {
    let isValid = true;
    let error = {};

    if (!startDate || !endDate) {
      isValid = false;
      error.startDate = "Please Select Both Dates";
    }

    setErrors(error);
    return isValid;
  };

  let today = new Date();
  today.setDate(today.getDate() - 60);
  let date = new Date(today)
    .toLocaleDateString("in", {
      day: "2-digit",
      year: "numeric",
      month: "2-digit",
    })
    .split("/")
    .reverse()
    .join("-");

  const pageInfo = {
    currentPageName: "Share Your Profile List",
  };
  return (
    <>
      {modalShow && (
        <Modal
          heading={alertMessage}
          onClick={() => {
            setModalShow(false);
          }}
        />
      )}
      <div className={classes.dashboard_container}>
        <div className={classes.TimsbiBreadcrumb}>
          <TimesBreadCrumbs pageInfo={pageInfo} />{" "}
        </div>
        <div className={classes.DateFilter_container}>
          <div className={classes.button_container}>
            <CSVLink
              data={webstorylist ? webstorylist : ""}
              filename={"/HrProfile.csv"}
              target="_blank"
            >
              <button className={classes.button_articleList}>CSV</button>
            </CSVLink>
            <ExcelDownload csvData={webstorylist} fileName="/HrProfile" />
          </div>
          <div className={classes.dateDiv}>
            <div>
              <label className={classes.dateLable}>Start Date</label>
              <input
                type="date"
                value={startDate || date}
                onChange={(e) => setStartDate(e.target.value)}
              />
              <label className={classes.dateLable}>End Date</label>
              <input
                type="date"
                value={
                  endDate ||
                  new Date()
                    .toLocaleDateString("in", {
                      day: "2-digit",
                      year: "numeric",
                      month: "2-digit",
                    })
                    .split("/")
                    .reverse()
                    .join("-")
                }
                onChange={(e) => setEndDate(e.target.value)}
              />
            </div>
            <div className={classes.form_error}>{errors.startDate}</div>
            <div className={classes.buttons}>
              <button
                type="button"
                className="button"
                onClick={() =>
                  formValidation()
                    ? WebstoryListApi()
                    : console.log("Something Wrong")
                }
              >
                {" "}
                Search
              </button>
            </div>
          </div>
        </div>
        {loading ? (
          <div className={classes.spinner}>
            <LoadingSpinner />
          </div>
        ) : (
          <div className={classes.table_container}>
            <div className={classes.buttons1}>
              <div>
                {currentPage !== 1 ? (
                  <button
                    onClick={() => {
                      setCurrentPage(currentPage - 1);
                    }}
                    className={classes.buttonone}
                  >
                    Prev
                  </button>
                ) : (
                  <></>
                )}
                <span>{webstorylist?.length < 20 ? <></> : currentPage}</span>
                {webstorylist?.length < 20 ? (
                  <></>
                ) : (
                  <button
                    onClick={() => {
                      setCurrentPage(currentPage + 1);
                    }}
                    className={classes.buttonone}
                  >
                    Next
                  </button>
                )}
              </div>
            </div>
            <div className={classes.container}>
              {webstorylist?.length > 0 ? (
                <table className={classes.orders_table}>
                  <tr className={classes.table_row}>
                    <th className={classes.table_heading}>Id</th>

                    <th className={classes.table_heading}>FullName</th>
                    <th className={classes.table_heading}>Profile Picture </th>

                    <th className={classes.table_heading}>Company Logo</th>

                    <th className={classes.table_heading}>
                      Awards Achievements
                    </th>
                    <th className={classes.table_heading}>LinkedIn Link</th>
                    <th className={classes.table_heading}>CV</th>
                    <th className={classes.table_heading}>Approve</th>
                    <th className={classes.table_heading}>Created At</th>
                    {/* <th className={classes.table_heading}>Updated At</th> */}
                    {/* <th className={classes.table_heading}>Status</th> */}
                  </tr>

                  <tbody>
                    {webstorylist?.map((item, value) => {
                      return (
                        <tr className={classes.table_row}>
                          <td className={classes.table_td}>{item.ID}</td>
                          <td className={classes.table_td}>{item.FullName}</td>
                          <td className={classes.table_td}>
                            <img
                              src={item.ProfilePicture}
                              alt="icon"
                              className={classes.IconImage}
                            />
                          </td>
                          <td className={classes.table_td}>
                            <img
                              src={item.CompanyLogo}
                              alt="icon"
                              className={classes.IconImage}
                            />
                          </td>{" "}
                          <td className={classes.table_td}>
                            {item.AwardsAchievements}
                          </td>
                          <td className={classes.table_td}>
                            <div>
                              <a
                                href={item.Connect}
                                target="_blank"
                                rel="noreferrer"
                              >
                                LinkedIn
                              </a>
                            </div>
                          </td>
                          <td className={classes.table_td}>
                            <a
                              href={item.UploadCV}
                              download={item.UploadCV}
                              target="_blank"
                              rel="noreferrer"
                            >
                              Download Resume
                            </a>
                          </td>
                          <td
                            className={classes.table_td}
                            style={{ width: "150px" }}
                          >
                            <EventInputs
                              input={"CheckBox"}
                              disabled={item.IsApproved == 1 ? true : false}
                              onChange={() =>
                                hrShareApproveFunc(
                                  item.RecognizingHrExcID,
                                  item.IsApproved
                                )
                              }
                              label={
                                item.IsApproved == 1
                                  ? "Approved"
                                  : "Not Approved"
                              }
                              value={item.IsApproved == 1 ? true : false}
                            />
                          </td>
                          <td className={classes.table_td}>
                            {item.CreatedAt.slice(0, 10)}
                          </td>
                          {/* <td className={classes.table_td}>{item.UpdatedAt}</td> */}
                          {/* <td className={classes.table_td}>
                            {item.IsLive === "0" ? (
                              <span className={classes.isnotLive}>
                                Not Live
                              </span>
                            ) : (
                              <span className={classes.isLive}>Live</span>
                            )}
                          </td> */}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              ) : (
                <div className={classes.No_data}>No data found!</div>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default HrshareFormlist;
