import React, { useState, useEffect } from "react";
import LoadingSpinner from "../Components/LoadingSpinner";
import classes from "./recruiterlogin.module.css";
import { CSVLink } from "react-csv";
import ExcelDownload from "../Components/Articles/ExcelDownload";
import Card from "../Components/Dashboard/Card";
import { Link } from "react-router-dom";
import TimesBreadCrumbs from "../Components/TimesBreadCrumbs";
import { AiOutlineArrowUp } from "react-icons/ai";
import { AiOutlineArrowDown } from "react-icons/ai";
const RecruiterLoginDataPoints = (props) => {
  const [isLoading, setLoading] = useState(false);
  const [recruiterlogindata, setRecruiterlogindata] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [firstdate, setFirstDate] = useState("");
  const [lastdate, setLastDate] = useState("");
  const [count, setCount] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [errors, setErrors] = useState(false);
  const [order, setOrder] = useState("ASC");
  const [name, setName] = useState("ASC");
  const [mobile, setMobile] = useState("ASC");
  const [entrance, setEntrance] = useState("ASC");
  const [redirectedFrom, setRedirectedFrom] = useState("ASC");
  const [isdate, setIsdate] = useState("ASC");
  const [issno, setIssno] = useState("DSC");

  useEffect(() => {
    RecruiterLoginApi();
  }, [currentPage]);

  const RecruiterLoginApi = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      startDate: startDate,
      endDate: endDate,
      Page: currentPage,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_API_URL + "/adminapi/getrecruiterLogin",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setRecruiterlogindata(result.data);
        setCount(result.totalCount);
        setFirstDate(result.startdate);
        setLastDate(result.endDate);
      })
      .catch((error) => console.log("error", error))
      .finally(() => setLoading(false));
  };

  const formValidation = () => {
    let isValid = true;
    let error = {};

    if (!startDate || !endDate) {
      isValid = false;
      error.startDate = "Please Select Both Dates";
    }

    setErrors(error);
    return isValid;
  };

  const sorting = (col) => {
    if (order === "ASC") {
      const sorted = [...recruiterlogindata].sort((a, b) =>
        a[col] > b[col] ? 1 : -1
      );
      setRecruiterlogindata(sorted);
      setOrder("DSC");
    }
    if (order === "DSC") {
      const sorted = [...recruiterlogindata].sort((a, b) =>
        a[col] < b[col] ? 1 : -1
      );
      setRecruiterlogindata(sorted);
      setOrder("ASC");
    }
  };

  const sortingname = (col) => {
    if (name === "ASC") {
      const sortedname = [...recruiterlogindata].sort((a, b) =>
        a[col] > b[col] ? 1 : -1
      );
      setRecruiterlogindata(sortedname);
      setName("DSC");
    }
    if (name === "DSC") {
      const sortedname = [...recruiterlogindata].sort((a, b) =>
        a[col] < b[col] ? 1 : -1
      );
      setRecruiterlogindata(sortedname);
      setName("ASC");
    }
  };

  const sortingmobile = (col) => {
    if (mobile === "ASC") {
      const sortedmobile = [...recruiterlogindata].sort((a, b) =>
        a[col] > b[col] ? 1 : -1
      );
      setRecruiterlogindata(sortedmobile);
      setMobile("DSC");
    }
    if (mobile === "DSC") {
      const sortedmobile = [...recruiterlogindata].sort((a, b) =>
        a[col] < b[col] ? 1 : -1
      );
      setRecruiterlogindata(sortedmobile);
      setMobile("ASC");
    }
  };

  const sortingentrance = (col) => {
    if (entrance === "ASC") {
      const sortedentrnce = [...recruiterlogindata].sort((a, b) =>
        a[col] > b[col] ? 1 : -1
      );
      setRecruiterlogindata(sortedentrnce);
      setEntrance("DSC");
    }
    if (entrance === "DSC") {
      const sortedentrnce = [...recruiterlogindata].sort((a, b) =>
        a[col] < b[col] ? 1 : -1
      );
      setRecruiterlogindata(sortedentrnce);
      setEntrance("ASC");
    }
  };
  const sortingredirected = (col) => {
    if (redirectedFrom === "ASC") {
      const sortedredirected = [...recruiterlogindata].sort((a, b) =>
        a[col] > b[col] ? 1 : -1
      );
      setRecruiterlogindata(sortedredirected);
      setRedirectedFrom("DSC");
    }
    if (redirectedFrom === "DSC") {
      const sortedredirected = [...recruiterlogindata].sort((a, b) =>
        a[col] < b[col] ? 1 : -1
      );
      setRecruiterlogindata(sortedredirected);
      setRedirectedFrom("ASC");
    }
  };
  const sortingdate = (col) => {
    if (isdate === "ASC") {
      const sorteddate = [...recruiterlogindata].sort((a, b) =>
        a[col] > b[col] ? 1 : -1
      );
      setRecruiterlogindata(sorteddate);
      setIsdate("DSC");
    }
    if (isdate === "DSC") {
      const sorteddate = [...recruiterlogindata].sort((a, b) =>
        a[col] < b[col] ? 1 : -1
      );
      setRecruiterlogindata(sorteddate);
      setIsdate("ASC");
    }
  };

  const sortingsrno = (col) => {
    if (issno === "ASC") {
      const sortsno = [...recruiterlogindata].sort((a, b) =>
        a[col] > b[col] ? 1 : -1
      );
      setRecruiterlogindata(sortsno);
      setIssno("DSC");
    }
    if (issno === "DSC") {
      const sortsno = [...recruiterlogindata].sort((a, b) =>
        a[col] < b[col] ? 1 : -1
      );
      setRecruiterlogindata(sortsno);
      setIssno("ASC");
    }
  };

  const pageInfo = {
    currentPageName: "Recruiter Login",
    previousPageName: "Data Points",
    previousPage: "DataPoints",
  };
  return (
    <div className={classes.dashboard_container}>
      <div className={classes.TimsbiBreadcrumb}>
        {" "}
        <TimesBreadCrumbs pageInfo={pageInfo} />{" "}
      </div>
      <div className={classes.events_title}>Recruiter Login</div>

      <div className={classes.DateFilter_container}>
        <div className={classes.button_container}>
          <CSVLink
            data={recruiterlogindata}
            filename={"Recruiter_Login.csv"}
            target="_blank"
          >
            <button className={classes.button_articleList}>CSV</button>
          </CSVLink>
          <ExcelDownload
            csvData={recruiterlogindata}
            fileName="Recruiter_Login"
          />
        </div>
        <div className={classes.dateDiv}>
          <div>
            <label className={classes.dateLable}>Start Date</label>
            <input
              type="date"
              value={startDate || firstdate}
              onChange={(e) => setStartDate(e.target.value)}
            />
            <label className={classes.dateLable}>End Date</label>
            <input
              type="date"
              value={endDate || lastdate}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </div>
          <div className={classes.form_error}>{errors.startDate}</div>
          <div className={classes.buttons}>
            <button
              type="button"
              className="button"
              onClick={() =>
                formValidation()
                  ? RecruiterLoginApi()
                  : console.log("Something Wrong")
              }
            >
              {" "}
              Search
            </button>
          </div>
        </div>
      </div>
      <div className={classes.card}>
        <div className={classes.count}>Count :</div>
        <div className={classes.count_main}>{count}</div>
      </div>
      <div className={classes.buttons1}>
        {currentPage !== 1 ? (
          <button
            onClick={() => {
              setCurrentPage(currentPage - 1);
            }}
            className={classes.buttonone}
          >
            Prev
          </button>
        ) : (
          <></>
        )}
        <span>{currentPage}</span>
        {recruiterlogindata.length > 9 ? (
          <button
            onClick={() => {
              setCurrentPage(currentPage + 1);
            }}
            className={classes.buttonone}
          >
            Next
          </button>
        ) : (
          <></>
        )}
      </div>
      {isLoading ? (
        <div className={classes.spinner}>
          <LoadingSpinner />
        </div>
      ) : (
        <div className={classes.table_container}>
          <div className={classes.container}>
            {recruiterlogindata.length > 0 ? (
              <table className={classes.orders_table}>
                <tr className={classes.table_row}>
                  <th className={classes.table_heading}>
                    Sno
                    {issno === "ASC" ? (
                      <span
                        className={classes.arrow}
                        onClick={() => sortingsrno("sno")}
                      >
                        <AiOutlineArrowDown />
                      </span>
                    ) : (
                      <span
                        className={classes.arrow}
                        onClick={() => sortingsrno("sno")}
                      >
                        <AiOutlineArrowUp />
                      </span>
                    )}
                  </th>
                  <th className={classes.table_heading}>
                    Name
                    {name === "ASC" ? (
                      <span
                        className={classes.arrow}
                        onClick={() => sortingname("name")}
                      >
                        <AiOutlineArrowDown />
                      </span>
                    ) : (
                      <span
                        className={classes.arrow}
                        onClick={() => sortingname("name")}
                      >
                        <AiOutlineArrowUp />
                      </span>
                    )}
                  </th>
                  <th className={classes.table_heading}>
                    Mobile
                    {mobile === "ASC" ? (
                      <span
                        className={classes.arrow}
                        onClick={() => sortingmobile("mobile")}
                      >
                        <AiOutlineArrowDown />
                      </span>
                    ) : (
                      <span
                        className={classes.arrow}
                        onClick={() => sortingmobile("mobile")}
                      >
                        <AiOutlineArrowUp />
                      </span>
                    )}
                  </th>
                  <th className={classes.table_heading}>
                    Page View
                    {order === "ASC" ? (
                      <span
                        className={classes.arrow}
                        onClick={() => sorting("pageview")}
                      >
                        <AiOutlineArrowDown />
                      </span>
                    ) : (
                      <span
                        className={classes.arrow}
                        onClick={() => sorting("pageview")}
                      >
                        <AiOutlineArrowUp />
                      </span>
                    )}{" "}
                  </th>
                  <th className={classes.table_heading}>
                    Entrance{" "}
                    {entrance === "ASC" ? (
                      <span
                        className={classes.arrow}
                        onClick={() => sortingentrance("entrance")}
                      >
                        <AiOutlineArrowDown />
                      </span>
                    ) : (
                      <span
                        className={classes.arrow}
                        onClick={() => sortingentrance("entrance")}
                      >
                        <AiOutlineArrowUp />
                      </span>
                    )}{" "}
                  </th>

                  <th className={classes.table_heading}>
                    redirectedFrom
                    {redirectedFrom === "ASC" ? (
                      <span
                        className={classes.arrow}
                        onClick={() => sortingredirected("redirectedFrom")}
                      >
                        <AiOutlineArrowDown />
                      </span>
                    ) : (
                      <span
                        className={classes.arrow}
                        onClick={() => sortingredirected("redirectedFrom")}
                      >
                        <AiOutlineArrowUp />
                      </span>
                    )}
                  </th>
                  <th className={classes.table_heading}>
                    date{" "}
                    {isdate === "ASC" ? (
                      <span
                        className={classes.arrow}
                        onClick={() => sortingdate("date")}
                      >
                        <AiOutlineArrowDown />
                      </span>
                    ) : (
                      <span
                        className={classes.arrow}
                        onClick={() => sortingdate("date")}
                      >
                        <AiOutlineArrowUp />
                      </span>
                    )}
                  </th>
                  <th className={classes.table_heading}>View</th>
                </tr>

                <tbody>
                  {recruiterlogindata.map((item, value) => {
                    return (
                      <tr className={classes.table_row}>
                        <td className={classes.table_td}>{item.sno}</td>
                        <td className={classes.table_td}>{item.name}</td>

                        <td className={classes.table_td}>{item.mobile}</td>
                        <td className={classes.table_td}>{item.pageview}</td>
                        <td className={classes.table_td}>{item.entrence}</td>
                        <td className={classes.table_td}>
                          {item.redirectedFrom}
                        </td>
                        <td className={classes.table_td}>{item.date}</td>
                        <td className={classes.table_td}>
                          <Link
                            to={
                              "/BiDashboard/RecruiterLoginDetail/" + item.date
                            }
                          >
                            View
                          </Link>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            ) : (
              <div className={classes.No_data}>No data found!</div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default RecruiterLoginDataPoints;
