import React, { useState, useEffect } from "react";
import LoadingSpinner from "../Components/LoadingSpinner";
import classes from "./easyapplyjobdetail.module.css";
import { CSVLink } from "react-csv";
import ExcelDownload from "../Components/Articles/ExcelDownload";
import { Link, useParams } from "react-router-dom";
import TimesBreadCrumbs from "../Components/TimesBreadCrumbs";

import { AiOutlineArrowUp } from "react-icons/ai";
import { AiOutlineArrowDown } from "react-icons/ai";
const EasyApplyJobDetail = () => {
  const [isLoading, setLoading] = useState(false);
  const [jobdetailtdata, setJobDetaildata] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [count, setCount] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const currentURL = window.location.href.split("/").pop();
  console.log("currentURL", currentURL);
  const [order, setOrder] = useState("ASC");
  const [comapny, setCompany] = useState("ASC");
  const [comapnyid, setCompanyId] = useState("ASC");
  const [buttonclick, setButtonclick] = useState("ASC");
  const { id } = useParams();

  const compId = localStorage.setItem("date", currentURL);

  console.log("storage", compId);

  console.log("param", id);

  useEffect(() => {
    JobdetailApi();
  }, [currentPage]);

  const JobdetailApi = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      startDate: startDate || currentURL,
      endDate: endDate || currentURL,
      Page: currentPage,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_API_URL + "/adminapi/getEasyApply",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setJobDetaildata(result.data);
        setCount(result.totalCount);
      })
      .catch((error) => console.log("error", error))
      .finally(() => setLoading(false));
  };
  const pageInfo = {
    currentPageName: "Easy Apply Companies List ",
    previousPageName: "EasyApply Data Points",
    previousPage: "/BiDashboard/EasyApplyDataPointsLastMonth",
  };

  const sorting = (col) => {
    if (order === "ASC") {
      const sorted = [...jobdetailtdata].sort((a, b) =>
        a[col] > b[col] ? 1 : -1
      );
      setJobDetaildata(sorted);
      setOrder("DSC");
    }
    if (order === "DSC") {
      const sorted = [...jobdetailtdata].sort((a, b) =>
        a[col] < b[col] ? 1 : -1
      );
      setJobDetaildata(sorted);
      setOrder("ASC");
    }
  };
  const sortingComapny = (col) => {
    if (comapny === "ASC") {
      const sortedcompany = [...jobdetailtdata].sort((a, b) =>
        a[col] > b[col] ? 1 : -1
      );
      setJobDetaildata(sortedcompany);
      setCompany("DSC");
    }
    if (comapny === "DSC") {
      const sortedcompany = [...jobdetailtdata].sort((a, b) =>
        a[col] < b[col] ? 1 : -1
      );
      setJobDetaildata(sortedcompany);
      setCompany("ASC");
    }
  };
  const sortingComapnyId = (col) => {
    if (comapnyid === "ASC") {
      const sortedcompanyid = [...jobdetailtdata].sort((a, b) =>
        a[col] > b[col] ? 1 : -1
      );
      setJobDetaildata(sortedcompanyid);
      setCompanyId("DSC");
    }
    if (comapnyid === "DSC") {
      const sortedcompanyid = [...jobdetailtdata].sort((a, b) =>
        a[col] < b[col] ? 1 : -1
      );
      setJobDetaildata(sortedcompanyid);
      setCompanyId("ASC");
    }
  };
  const sortingButtonclick = (col) => {
    if (buttonclick === "ASC") {
      const sortedbutton = [...jobdetailtdata].sort((a, b) =>
        a[col] > b[col] ? 1 : -1
      );
      setJobDetaildata(sortedbutton);
      setButtonclick("DSC");
    }
    if (buttonclick === "DSC") {
      const sortedbutton = [...jobdetailtdata].sort((a, b) =>
        a[col] < b[col] ? 1 : -1
      );
      setJobDetaildata(sortedbutton);
      setButtonclick("ASC");
    }
  };
  return (
    <div className={classes.dashboard_container}>
      <div className={classes.TimsbiBreadcrumb}>
        {" "}
        <TimesBreadCrumbs pageInfo={pageInfo} />{" "}
      </div>
      <div className={classes.events_title}>Easy Apply Companies List</div>
      <div className={classes.DateFilter_container}>
        <div className={classes.button_container}>
          <CSVLink
            data={jobdetailtdata}
            filename={"jobdetail.csv"}
            target="_blank"
          >
            <button className={classes.button_articleList}>CSV</button>
          </CSVLink>
          <ExcelDownload csvData={jobdetailtdata} fileName="jobdetail" />
        </div>
        <div className={classes.dateDiv}>
          <div>
            <label className={classes.dateLable}>Start Date</label>
            <input
              type="date"
              value={startDate || currentURL}
              onChange={(e) => setStartDate(e.target.value)}
            />
            <label className={classes.dateLable}>End Date</label>
            <input
              type="date"
              value={endDate || currentURL}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </div>
          <div className={classes.buttons}>
            <button
              type="button"
              className="button"
              onClick={() => JobdetailApi()}
            >
              {" "}
              Search
            </button>
          </div>
        </div>
      </div>
      <div className={classes.card}>
        <div className={classes.count}>Count :</div>
        <div className={classes.count_main}>{count}</div>
      </div>
      {count > 10 ? (
        <div className={classes.buttons1}>
          {currentPage !== 1 ? (
            <button
              onClick={() => {
                setCurrentPage(currentPage - 1);
              }}
              className={classes.buttonone}
            >
              Prev
            </button>
          ) : (
            <></>
          )}
          <span>{currentPage}</span>
          {jobdetailtdata.length > 9 ? (
            <>
              <button
                onClick={() => {
                  setCurrentPage(currentPage + 1);
                }}
                className={classes.buttonone}
              >
                Next
              </button>
            </>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <></>
      )}
      {isLoading ? (
        <div className={classes.spinner}>
          <LoadingSpinner />
        </div>
      ) : (
        <div className={classes.table_container}>
          <div className={classes.container}>
            {jobdetailtdata.length > 0 ? (
              <table className={classes.orders_table}>
                <tr className={classes.table_row}>
                  <th className={classes.table_heading}>Sno</th>
                  <th className={classes.table_heading}>Company Id</th>
                  <th className={classes.table_heading}>Company Name</th>
                  <th className={classes.table_heading}>
                    PageView
                    {order === "ASC" ? (
                      <span
                        className={classes.arrow}
                        onClick={() => sorting("pageview")}
                      >
                        {" "}
                        <AiOutlineArrowDown />
                      </span>
                    ) : (
                      <span
                        className={classes.arrow}
                        onClick={() => sorting("pageview")}
                      >
                        <AiOutlineArrowUp />
                      </span>
                    )}
                  </th>
                  <th className={classes.table_heading}>
                    button Click
                    {buttonclick === "ASC" ? (
                      <span
                        className={classes.arrow}
                        onClick={() => sortingButtonclick("buttonClick")}
                      >
                        {" "}
                        <AiOutlineArrowDown />
                      </span>
                    ) : (
                      <span
                        className={classes.arrow}
                        onClick={() => sortingButtonclick("buttonClick")}
                      >
                        <AiOutlineArrowUp />
                      </span>
                    )}
                  </th>
                  <th className={classes.table_heading}>View Jobs </th>
                </tr>

                <tbody>
                  {jobdetailtdata.map((item, value) => {
                    return (
                      <tr className={classes.table_row}>
                        <td className={classes.table_td}>{item.sno}</td>
                        <td className={classes.table_td}>{item.companyId}</td>
                        <td className={classes.table_td}>{item.company}</td>
                        <td className={classes.table_td}>{item.pageview}</td>
                        <td className={classes.table_td}>{item.buttonClick}</td>
                        <td className={classes.table_td}>
                          <Link
                            to={
                              "/BiDashboard/EasyApplyThroghId/" + item.companyId
                            }
                          >
                            View Jobs
                          </Link>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            ) : (
              <div className={classes.No_data}>No data found!</div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default EasyApplyJobDetail;
