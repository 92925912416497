import React, { useEffect, useState } from "react";

import classes from "../Webinarvideos/InsertVideo.module.css";

import { useHistory, useParams, Link } from "react-router-dom";

import Loader from "react-loader-spinner";
import TimesBreadCrumbs from "../Components/TimesBreadCrumbs";
import Modal from "../Components/Modal";
import EventInputs from "../Components/Events/EventInputs";
import BackButton from "../Components/BackButton";

const UpdateFaq = () => {
  const { id } = useParams();

  const [Question, setQuestion] = useState("");
  const [loading, setLoading] = useState(false);
  const [Answer, setAnswer] = useState("");
  const [erorrshow, setErrorShow] = useState(false);
  const [internalErrorShow, setInternalErrorShow] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [SeoURL, setSeoUrl] = useState("")
  let history = useHistory();



  const UpdateApi = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      Id: id,
      Answer: Answer,
      Question: Question,
      PageSeoURL: SeoURL
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_API_URL + "/adminapi/UpdateWealthFAQ",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "Success" && result.status_code === 200) {
          setModalShow(true);
        } else if (result.status_code === 400 || result.status_code === 500) {
          setInternalErrorShow(true);
        } else if (result.status_code === 300) {
          setErrorShow(true);
        }
      })
      .catch((error) => console.log("error", error))
      .finally(() => setLoading(false));
  };

  const ViewApi = () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      Id: id,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_API_URL + "/adminapi/GetWealthFAQById",
      // "http://192.168.1.8:8000/adminapi/GetWealthFAQById",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {

        setQuestion(result.data.Question);
        setAnswer(result.data.Answer);
        setSeoUrl(result.data.PageSeoURL)
      })
      .catch((error) => console.log("error", error));
  };

  useEffect(() => {
    ViewApi();
  }, []);

  const pageInfo = {
    currentPageName: "Update Guest Article",
    previousPageName: "Guest Aricle List",
    previousPage: "/BiDashboard/GuestArticleList",
  };

  return (
    <div className={classes.top_div}>
      {modalShow && (
        <Modal heading={"Data updated successfully"} onClick={() => history.push("/BiDashboard/FAQ")} />
      )}
      {erorrshow && (
        <Modal
          heading={"Required Fields Are Missing"}
          onClick={() => setErrorShow(false)}
        />
      )}
      {internalErrorShow && (
        <Modal
          heading={"Internal server error"}
          onClick={() => setInternalErrorShow(false)}
        />
      )}

      <div className={classes.container}>
        <div className={classes.form}>
          <div className={classes.TimsbiBreadcrumb}>
            {" "}
            <TimesBreadCrumbs pageInfo={pageInfo} />{" "}
          </div>
          <div className={classes.form_header_container}>
            <div className={classes.form_header}>Update FAQ</div>
            <Link to="/BiDashboard/FAQ" className={classes.BackButton}>
              <BackButton />
            </Link>
          </div>
          <div className={classes.form_input}>
            <div className={classes.form_Group}>
              <label htmlFor="shortDescription">
                Question
              </label>
              <textarea
                rows="4"
                cols="50"
                id="shortDescription"
                name="shortDescription"
                placeholder="Write Your Question"
                onChange={(e) => setQuestion(e.target.value)}
                value={Question}
              />
            </div>

            <div className={classes.form_Group}>
              <label htmlFor="shortDescription">
                Answer
              </label>
              <textarea
                rows="4"
                cols="50"
                id="shortDescription"
                name="shortDescription"
                placeholder="Write Your Answer"
                onChange={(e) => setAnswer(e.target.value)}
                value={Answer}
              />
            </div>

            <div className={classes.form_Group}>
              <EventInputs
                input={"SingleLineInput"}
                label={"Seo Url"}
                placeholder={"Enter Seo Url..."}
                onChange={(e) => setSeoUrl(e.target.value)}
                value={SeoURL}
                disabled={true}
              />
            </div>

          </div>

        </div>
        <button className={classes.Upload_button} onClick={() => UpdateApi()}>
          {loading ? (
            <Loader
              type="TailSpin"
              color="white"
              height={20}
              width={50}
              className="text-center"
            />
          ) : (
            "Update"
          )}
        </button>
      </div>
    </div>
  );
};

export default UpdateFaq;
