import React, { useEffect, useState } from "react";

import classes from "../GuestArticle/editguest.module.css";
import EventInputs from "../Events/EventInputs";
import Modal from "../Modal";
import { useHistory, useParams } from "react-router-dom";
import TimesBreadCrumbs from "../TimesBreadCrumbs";
import Loader from "react-loader-spinner";

const ForherPostEdit = () => {
  const [articleguestId, setArticleguestId] = useState("");
  const [loading, setLoading] = useState(false);
  const [articleguesttype, setArticleguesttype] = useState("");
  const [erorrshow, setErrorShow] = useState(false);
  const [internalErrorShow, setInternalErrorShow] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [check, setCheck] = useState("");
  const [postType, setPostType] = useState("");

  let history = useHistory();

  const dropdownList = [
    {
      id: 1,
      type: "forherevents",
    },
    {
      id: 2,
      type: "forhercourses",
    },
    {
      id: 3,
      type: "forherjobs",
    },
  ];

  const { id, pagename } = useParams();
  useEffect(() => {
    ViewApi();
  }, []);

  const UpdateApi = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      PageName: postType,
      Id: articleguestId,
      Status: check,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(
      process.env.REACT_APP_API_URL + "/v1/admin1_1/ForherPageUpdate",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "SUCCESS" && result.status_code === 200) {
          setModalShow(true);
        } else if (result.status_code === 400 || result.status_code === 500) {
          setInternalErrorShow(true);
        } else if (result.status_code === 300) {
          setErrorShow(true);
        }
      })
      .catch((error) => console.log("error", error))
      .finally(() => setLoading(false));
  };

  const ViewApi = () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({ PageName: pagename, Id: id });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_API_URL + "/v1/admin1_1/ForherPageDetail",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code === 200) {
          setCheck(result.data.status === "1" ? 1 : 0);
          setArticleguestId(result.data.id);
          setPostType(pagename);
        }
      })
      .catch((error) => console.log("error", error));
  };
  const pageInfo = {
    currentPageName: "Forher page update",
    previousPage: "/BiDashboard/forher-post-list",
  };

  return (
    <div className={classes.top_div}>
      {modalShow && (
        <Modal
          heading={"Data updated successfully"}
          onClick={() => setModalShow(false)}
        />
      )}
      {erorrshow && (
        <Modal
          heading={"Required Fields Are Missing"}
          onClick={() => setErrorShow(false)}
        />
      )}
      {internalErrorShow && (
        <Modal
          heading={"Internal server error"}
          onClick={() => setInternalErrorShow(false)}
        />
      )}

      <div className={classes.container}>
        <div className={classes.form}>
          <div className={classes.TimsbiBreadcrumb}>
            <TimesBreadCrumbs pageInfo={pageInfo} />{" "}
          </div>

          <div className={classes.form_header_container}>
            <div className={classes.form_header}>Update forher post</div>
            <div className={classes.checkbox}>
              <span className={classes.live}>Live</span>
              <EventInputs
                input={"CheckBox"}
                onChange={() => setCheck(!check === true ? 1 : 0)}
                value={check}
              />
            </div>
          </div>
          <div className={classes.form_input}>
            <div className={classes.input}>
              <EventInputs
                input={"Number"}
                label={"Post Id"}
                placeholder={"Post Id"}
                onChange={(e) => setArticleguestId(e.target.value)}
                value={articleguestId}
              />
            </div>
            {/* <EventInputs
              input={"SingleLineInput"}
              label={"Article Type"}
              placeholder={"Article Type"}
              onChange={(e) => setArticleguesttype(e.target.value)}
              value={articleguesttype}
            /> */}
            <EventInputs
              input={"smalldropdown"}
              label={"Post type"}
              placeholder={"Post type"}
              displayData={dropdownList}
              onChange={(e) => setPostType(e.target.value)}
              value={postType}
            />
          </div>
        </div>
        <button className={classes.Upload_button} onClick={() => UpdateApi()}>
          {loading ? (
            <Loader
              type="TailSpin"
              color="white"
              height={20}
              width={50}
              className="text-center"
            />
          ) : (
            "Update"
          )}
        </button>
      </div>
    </div>
  );
};

export default ForherPostEdit;
