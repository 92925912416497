import React, { useState, useEffect, useRef } from "react";
import classes from "./LeadersOfChange.module.css";
import { FiEdit } from "react-icons/fi";
import { MdDeleteForever } from "react-icons/md";
import { useHistory, Link } from "react-router-dom";
import { CSVLink } from "react-csv";
// import jsPDF from "jspdf";
import LoadingSpinner from "../../Components/LoadingSpinner";
import ExcelDownload from "../../Components/Articles/ExcelDownload";
import Paginate from "../../Components/Pagination";
import ConfirmModal from "../../Components/ConfirmModal";
import Modal from "../../Components/Modal";

const LeadersOfChange = () => {
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(true);
  // const [date, setDate] = useState({ startDate: "", endDate: "" });
  // const doc = new jsPDF();
  const date = "";
  const [currentPage, setCurrentPage] = useState(1);
  const [modalShow, setModalShow] = useState(false);
  const [confirmModalShow, setConfirmModalShow] = useState(false);
  const [count, setCount] = useState(0);
  const [managerUUID, setManagerUUID] = useState("");
  const perPage = 20;

  const tableRef = useRef();



  const history = useHistory();

  const handleNewArticle = () => {
    history.push("/BiDashboard/addLeadersOfChange");
  };

  const gmiList = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      page: currentPage,
      content: perPage,
      StartDate: date.startDate ? date.startDate : "",
      EndDate: date.endDate ? date.endDate : "",
    });

    var requestOptions = {
      method: "POST",
      body: raw,
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_API_URL + "/adminapi/GetLeadersofChange",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code === 200 && result.status === "Success") {
          setData(result.data);
          setCount(result.count);
        }
        setLoading(false);
      })
      .catch((error) => console.log("error", error));
  };

  useEffect(() => {
    gmiList();
  }, [currentPage]);

  const deleteGMI = () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );

    var formdata = new FormData();
    formdata.append("locUUID", managerUUID);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_API_URL + "/adminapi/DeleteLeadersofChange",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        if (result.status === "Success" && result.status_code === 200) {
          setModalShow(true);
          gmiList();
        }
      })
      .catch((error) => console.log("error", error));
  };

  return (
    <div className={classes.main_div}>
      <div className={classes.container}>
        {/* <div className={classes.header}>Podcasts</div> */}
        {confirmModalShow && (
          <ConfirmModal
            heading={"Are you sure that you want to delete this data?"}
            onClick={() => deleteGMI()}
            onClose={() => setConfirmModalShow(false)}
          />
        )}
        {modalShow && (
          <Modal
            heading={"Data deleted successfully"}
            onClick={() => {
              setModalShow(false);
              setConfirmModalShow(false);
            }}
          />
        )}

        <div className={classes.header}>Leaders of Change List:</div>
        <div className={classes.DateFilter_container}>
          <div className={classes.button_container}>
            <CSVLink data={data} filename={"gmi-table.csv"} target="_blank">
              <button className={classes.button_articleList}>CSV</button>
            </CSVLink>
            <ExcelDownload csvData={data} fileName="gmi-table" />
          </div>
          <div className={classes.buttons}>
            <button className={classes.button} onClick={handleNewArticle}>
              Add New
            </button>
          </div>
          {/* <div className={classes.dateDiv}>
            <div>
              <label className={classes.dateLable}>Start Date</label>
              <input
                type="date"
                value={date.startDate}
                onChange={(e) =>
                  setDate({ ...date, startDate: e.target.value })
                }
              />
              <label className={classes.dateLable}>End Date</label>
              <input
                type="date"
                value={date.endDate}
                onChange={(e) => setDate({ ...date, endDate: e.target.value })}
              />
            </div>
            <div className={classes.buttons}>
              <button
                type="button"
                className="button"
                onClick={() => gmiList()}
              >
                {" "}
                Search
              </button>
            </div>
          </div> */}
        </div>
        {/* <div className={classes.search_container}>
          <button className={classes.button} onClick={handleNewArticle}>
            New GMI
          </button>
        </div> */}
        <div>
          {isLoading ? (
            <div className={classes.spinner}>
              <LoadingSpinner />{" "}
            </div>
          ) : data.length ? (
            <>
              <table
                id="article-table"
                ref={tableRef}
                className={classes.orders_table}
              >
                <tr className={classes.table_row}>
                  <th className={classes.table_heading}>Sr. No.</th>
                  <th className={classes.table_heading}>Image</th>
                  <th className={classes.table_heading}>Name</th>
                  <th className={classes.table_heading}>Short Description</th>
                  <th className={classes.table_heading}>Website</th>
                  <th className={classes.table_heading}>Status</th>
                  <th className={classes.table_heading}>Edit</th>
                  <th className={classes.table_heading}>Delete</th>
                </tr>
                <tbody>
                  {data.map((item, idx) => (
                    <tr className={classes.table_row}>
                      <td className={classes.table_td}>{idx + 1}</td>
                      <td className={classes.table_td}>
                        <div>
                          <img
                            src={item.Logo}
                            alt={item.LogoAltImage}
                            className={classes.imageShow}
                          />
                        </div>
                      </td>
                      <td className={classes.table_td}>{item.Name}</td>
                      <td className={classes.table_td}>
                        {item.ShortDescription}
                      </td>
                      <td className={classes.table_td}>
                        {item.CompanyWebsite}
                      </td>
                      {/* <td className={classes.table_td}>
                        <div dangerouslySetInnerHTML={{ __html: item.ManagerDescription }} />
                      </td> */}
                      <td className={classes.table_td}>
                        {item.IsLive == "1" ? "Live" : "Not Live"}
                      </td>
                      <td className={classes.table_td}>
                        <Link
                          to={
                            "/BiDashboard/editLeadersOfChange/" +
                            item.LeadersOfChangeUUID
                          }
                          className={classes.articleLink}
                        >
                          <FiEdit size={20} />
                        </Link>
                      </td>
                      <td className={classes.table_td}>
                        <div
                          onClick={() => {
                            setManagerUUID(item.LeadersOfChangeUUID);
                            setConfirmModalShow(true);
                          }}
                          className={classes.articleLinkDelete}
                        >
                          <MdDeleteForever size={20} />
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div>
                {count > 20 && (
                  <Paginate
                    maxPage={count}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    perPage={perPage}
                  />
                )}
              </div>
            </>
          ) : (
            <h2 className={classes.serachErrorText}>"No Articles found !"</h2>
          )}
        </div>
      </div>
    </div>
  );
};

export default LeadersOfChange;
