import React, { useState } from "react";

import classes from "../Webinarvideos/InsertVideo.module.css";

import EventInputs from "../Components/Events/EventInputs";
import Modal from "../Components/Modal";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import TimesBreadCrumbs from "../Components/TimesBreadCrumbs";
import Loader from "react-loader-spinner";

const AddSheroVideos = () => {
  const [loading, setLoading] = useState(false);

  const [erorrshow, setErrorShow] = useState(false);
  const [internalErrorShow, setInternalErrorShow] = useState(false);
  const [modalShow, setModalShow] = useState(false);

  const [Image, setImage] = useState("");
  const [url, seturl] = useState("");
  const [Title, setTitle] = useState("");
  const [isPublished, setIsPublished] = useState(false);

  let history = useHistory();

  const AddVideoApi = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 4YzpGk8Vf8IcxzxQ68teZ2KM5iX8SktW5RN4iz92"
    );

    var formdata = new FormData();

    formdata.append("IsWebinarVideo", isPublished ? "1" : "0");
    formdata.append(" url", url);
    formdata.append("Title", Title);
    formdata.append("Image ", Image);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_API_URL + "/adminapi/InsertSHEroVideoURL",

      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "Success" && result.status_code === 200) {
          setModalShow(true);
        } else if (result.status_code === 400 || result.status_code === 500) {
          setInternalErrorShow(true);
        } else if (result.status_code === 300) {
          setErrorShow(true);
        }
      })
      .catch((error) => console.log("error", error))
      .finally(() => setLoading(false));
  };

  const pageInfo = {
    currentPageName: "Add SHERO",
    previousPageName: "SHERO Video List",
    previousPage: "/BiDashboard/SheroVideo",
  };
  const currentURL = window.location.href;

  console.log(currentURL);
  return (
    <div className={classes.top_div}>
      {modalShow && (
        <Modal
          heading={"Video added successfully"}
          onClick={() => history.push("/BiDashboard/SheroVideo")}
        />
      )}
      {erorrshow && (
        <Modal
          heading={"Required Fields Are Missing"}
          onClick={() => setErrorShow(false)}
        />
      )}
      {internalErrorShow && (
        <Modal
          heading={"Internal server error"}
          onClick={() => setInternalErrorShow(false)}
        />
      )}

      <div className={classes.container}>
        <div className={classes.form}>
          <div className={classes.TimsbiBreadcrumb}>
            {" "}
            <TimesBreadCrumbs pageInfo={pageInfo} />{" "}
          </div>
          <div className={classes.form_header_container}>
            <div className={classes.form_header}>Add SHERO Videos</div>
          </div>
          <div className={classes.form_input}>
            <div className={classes.input}>
              <EventInputs
                input={"SingleLineInput"}
                label={"Embed Code"}
                placeholder={"Embed Code"}
                onChange={(e) => seturl(e.target.value)}
                value={url}
              />

              <EventInputs
                input={"SingleLineInput"}
                label={"Title"}
                placeholder={"Title"}
                onChange={(e) => setTitle(e.target.value)}
                value={Title}
              />

              <EventInputs
                input={"ImageInput"}
                label={"Image"}
                placeholder={"Image"}
                onChange={(e) => setImage(e.target.files[0])}
              />

              <div className={classes.formGroup}>
                <div className={classes.checkBoxGroup}>
                  <label htmlFor="featuredArticle">Webinar LIve : </label>
                  <input
                    type="checkbox"
                    name="PickedBy"
                    checked={isPublished}
                    onClick={() => setIsPublished(!isPublished)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <button className={classes.Upload_button} onClick={() => AddVideoApi()}>
          {loading ? (
            <Loader
              type="TailSpin"
              color="white"
              height={20}
              width={50}
              className="text-center"
            />
          ) : (
            "Add"
          )}
        </button>
      </div>
    </div>
  );
};

export default AddSheroVideos;
